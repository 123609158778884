import React, { Component } from "react";

export class AbstractChart extends Component<any, any> {
  static defaultProps = {
    height: 270
  };
  containerRef: any;

  constructor(props: any) {
    super(props);

    this.state = {
      width: null,
      height: this.props.height
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    if (this.containerRef.current) {
      window.addEventListener("resize", this.updateDimensions.bind(this));
      this.updateDimensions();
    }
  }

  updateDimensions(callback?: any) {
    const width = parseInt(this.containerRef.current ? this.containerRef.current.clientWidth : 800);
    const height = parseInt(
      this.containerRef.current ? this.containerRef.current.clientHeight : this.props.height
    );
    this.setState({ width, height }, () => {
      if (typeof callback === "function") callback(this.state.width, this.state.height);
    });
  }
}
