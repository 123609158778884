import { Data } from "../Data";
import { SettingCourseFaculty } from "./SettingCourseFaculty";

export class SettingCourseFacultyList extends Data {
  static instance: any = null;
  faculties: any;

  constructor(options: any) {
    const { apiUrl, ...otherOptions } = options;
    const apiOptions = {
      api: apiUrl,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    super(apiOptions);

    this.faculties = [];
  }

  onDataReceived(data: any) {
    data.faculties.forEach((f: any, index: any) => {
      const faculty = new SettingCourseFaculty(f);
      this.faculties.push(faculty);
    });
  }

  static onGetData(context: any, options: any) {
    const { apiUrl } = options;
    return new Promise((resolve, reject) => {
      SettingCourseFacultyList.instance = new SettingCourseFacultyList({
        apiUrl,
        resolve,
        reject,
        context
      });
    });
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve: any, reject) => {
      if ((SettingCourseFaculty as any).instance && updateCache !== true)
        resolve((SettingCourseFaculty as any).instance, true);
      else
        (SettingCourseFaculty as any).instance = new SettingCourseFaculty({
          resolve,
          reject,
          context
        });
    });
  }
}
