export class SettingsCourse {
  id: any;
  rowId: any;
  courseId: any;
  name: any;
  description: any;
  recurrence: any;
  numberOfAssociatedAttributes: any;
  associatedAttribute: any;
  organizationIntegrationName: any;
  organizationIntegrationAbbreviation: any;
  faculties: any;

  constructor(data: any) {
    if (data) {
      this.id = data.id;
      this.rowId = data.id;
      this.courseId = data.courseId;
      this.name = data.courseName;
      this.description = data.courseDescription;
      this.recurrence = data.recurrence;
      this.numberOfAssociatedAttributes = data.numberOfAssociatedAttributes;
      this.associatedAttribute = data.associatedAttributeDefinitions;
      this.organizationIntegrationName = data.organizationIntegrationName;
      this.organizationIntegrationAbbreviation = data.organizationIntegrationAbbreviation;
      this.faculties = data.faculties;
    }
  }
}
