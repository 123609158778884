import { Grid } from "@mui/material";
import { AppContext } from "../../common/AppContext";
import { Placeholder } from "../../components/Placeholder";
import { FeedbackTable } from "../../components/table/FeedbackTable";
import { FeedbackTableSearchBar } from "../../components/table/FeedbackTableSearchBar";
import { ViewHeading } from "../../components/typography/ViewHeading";
import { View } from "../../components/View";
import { Feedback_filters } from "../../constants";
import { FeedbackAttribute } from "../../data/attributes/FeedbackAttribute";
import { FeedbackAttributeList } from "../../data/attributes/FeedbackAttributeList";
import { ReactComponent as FeedbackIcon } from "../../resources/images/icons-feedback.svg";
import { AppPages } from "../../router";
import { AbstractView } from "../AbstractPage";
import { ReviewFeedbackDialog } from "./ReviewFeedbackDialog";

export class Feedback extends AbstractView {
  static contextType = AppContext;

  static filters = Feedback_filters;

  constructor(props: any) {
    super(props, AppPages.feedback);

    const { groups, location } = this.props;
    const groupId = Feedback.getGroupIdFromPath(location);

    this.state = {
      filter: Feedback.filters.all,
      search: null,
      feedbackList: null,
      attribute: null,
      attributeId: this.props.match.params.id,
      reviewReadOnly: false,
      groups: props.groups,
      groupId,
      group: groups && groupId ? groups.get(groupId) : null,
      basePath: this.getBasePath(location)
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    super.componentDidUpdate(prevProps, prevState);
    if (prevProps.groups !== this.props.groups) this.setState({ groups: this.props.groups });
  }

  onFilterChange(filter: any) {
    this.setState({ filter });
  }

  onGroupChange(group: any) {
    this.props.history.push(group.id ? "/feedback/" + group.id : "/feedback");
  }

  onViewChange(location: any) {
    const { groupId, groups } = this.state;

    const newGroupId = Feedback.getGroupIdFromPath(location);

    if (newGroupId !== groupId) {
      const group = groups ? groups.get(newGroupId) : null;
      this.setState({ group, groupId: newGroupId, basePath: this.getBasePath(location) }, () =>
        this.loadFeedback(true)
      );
    }
  }

  static getGroupIdFromPath(location: any) {
    const pathSplit = location.pathname.split("/");
    if (pathSplit.length >= 3) {
      const pathSec = pathSplit[2];
      if (pathSec === "review" || pathSec === "view") return null;
      return pathSplit[2];
    }
    return null;
  }

  onSearch(search: any) {
    this.setState({ search: search });
  }

  onHasUser(user: any) {
    if (!this.verifyAccess()) return;
    this.loadFeedback();
  }

  getBasePath(location: any) {
    const groupId = Feedback.getGroupIdFromPath(location);
    return groupId ? "/feedback/" + groupId : "/feedback";
  }

  loadFeedback(updateCache?: any) {
    const { user } = this.props;
    const { groupId } = this.state;

    if (!user) return;
    this.context.setLoading("feedback", true);

    FeedbackAttributeList.get(this.context, updateCache, groupId ? groupId : undefined)
      .then((feedbackList: any) => {
        this.setState({
          feedbackList: feedbackList,
          attribute: feedbackList.getAttributeByEmployeeAttributeId(this.state.attributeId)
        });
      })
      .catch((error: any) => {
        //Todo: handle error
        //alert('error: ' + error);
      })
      .finally(() => this.context.setLoading("feedback", false));
  }

  onCloseReviewDialog(refresh: any) {
    const { basePath } = this.state;
    this.props.history.push(basePath);

    if (refresh) this.loadFeedback(true);
  }

  onFeedbackSelect(e: any, feedback: any) {
    const { basePath } = this.state;
    if (feedback.state === FeedbackAttribute.state.review) this.onReviewLinkClick(e, feedback);
    else
      this.setState(
        { reviewReadOnly: true },
        this.props.history.push(basePath + "/view/" + feedback.employeeAttributeId)
      );
  }

  onReviewLinkClick(e: any, feedback: any) {
    const { basePath } = this.state;
    e.preventDefault();
    e.stopPropagation();
    this.setState(
      { reviewReadOnly: false },
      this.props.history.push(basePath + "/review/" + feedback.employeeAttributeId)
    );
  }

  render() {
    if (!this.verifyAccess()) return this.renderAccessDenied();

    const { organization, location, user, theme } = this.props;
    const { filter, search, feedbackList, reviewReadOnly, groups, groupId, basePath } = this.state;

    const newRowData = feedbackList?.attributes.filter((row: any) => {
      if (!row) return false;

      let matchSearch1 = true;
      let matchSearch2 = true;
      let matchesFilter = true;

      if (search && search !== "") {
        const searchText = search.toLowerCase();
        matchSearch1 = row.attribute?.toLowerCase().includes(searchText);
        matchSearch2 = row.employeeFullName?.toLowerCase().includes(searchText);
      }

      if (filter && filter.label !== Feedback.filters.all.label) {
        matchesFilter =
          row.stateLabel === FeedbackAttribute.state.approved.label
            ? row.approvedStatus?.label.includes(filter.label)
            : row.stateLabel?.includes(filter.label);
      }

      return (matchSearch1 || matchSearch2) && matchesFilter;
    });

    const path = location.pathname;

    let requestId = null;
    const isReview = path.startsWith(basePath + "/review/");
    if (isReview) requestId = path.replace(basePath + "/review/", "");

    const isView = path.startsWith(basePath + "/view/");
    if (isView) requestId = path.replace(basePath + "/view/", "");

    const hasItems = feedbackList && feedbackList.attributes.length !== 0;

    return (
      <View theme={theme} scrollToTopOnMount={true}>
        <ViewHeading text='Feedback' />

        {user && ((user.isTeamLead && hasItems) || user.hasGroup) && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <FeedbackTableSearchBar
                  user={user}
                  searchPlaceholder='Search by name'
                  disabledSearch={!hasItems}
                  filters={Feedback.filters}
                  disabledFilter={!hasItems}
                  theme={theme}
                  defaultValue={Feedback.filters.all}
                  showViewModeToggle={false}
                  showSearchFromBreakpoint='xs'
                  onFilterChange={this.onFilterChange.bind(this)}
                  groupId={groupId}
                  groups={groups ? groups.groups.filter((g: any) => g.hasManagePermission) : null}
                  onGroupChange={this.onGroupChange.bind(this)}
                  onSearch={this.onSearch.bind(this)}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FeedbackTable
                  theme={theme}
                  rows={feedbackList ? newRowData : null}
                  noRowsMessage={{
                    primary: "Nothing to show yet",
                    secondary: "This is where all your employees’ requests will be shown"
                  }}
                  showHeaderFromBreakpoint='md'
                  onFeedbackSelect={this.onFeedbackSelect.bind(this)}
                  onReviewLinkClick={this.onReviewLinkClick.bind(this)}
                />
              </Grid>
            </Grid>
            {feedbackList && requestId && (
              <ReviewFeedbackDialog
                organization={organization}
                user={user}
                theme={theme}
                onClose={this.onCloseReviewDialog.bind(this)}
                attributeProvider={this}
                requestId={requestId}
                attributes={feedbackList}
                readOnly={reviewReadOnly}
              />
            )}
          </>
        )}

        {user && (!user.isTeamLead || (user.isTeamLead && !hasItems)) && !user.hasGroup && (
          <Placeholder
            text={{
              primary: "Nothing to show yet",
              secondary: "This is where all your employees’ requests will be shown."
            }}
            icon={<FeedbackIcon />}
            sx={{ height: "100vh", maxHeight: "60vh" }}
          />
        )}
      </View>
    );
  }
}
