import { Dashboard_mode } from "../constants";
import { Data } from "./Data";
import { EmployeeAttributeList } from "./attributes/EmployeeAttributeList";
import { GapAttributeList } from "./attributes/GapAttributeList";
import { TeamAttributeList } from "./attributes/TeamAttributeList";
import { TeamGapList } from "./attributes/TeamGapList";
import { NotificationsData } from "./notifications/NotificationsData";

export class DashboardData extends Data {
  static mode = Dashboard_mode;

  static instance: any = {};

  mode: any;
  data: any;
  attributes: any;
  gaps: any;
  notifications: any;

  constructor(options: any) {
    super({
      api: options.mode.api,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });

    this.mode = options.mode;
  }

  onDataReceived(data: any) {
    this.data = data;

    if (this.mode === DashboardData.mode.team) {
      this.attributes = new TeamAttributeList({ data: data.topAttributes });
      this.gaps = new TeamGapList({ data: data.topGapAttributes });
      this.notifications = new NotificationsData({
        data: data.latestTeamRequests,
        mode: this.mode
      });
    } else {
      this.gaps = new GapAttributeList({ data: data.gapAttributes });
      this.attributes = new EmployeeAttributeList({ data: data.employeeAttributes });
      this.notifications = new NotificationsData({ data: data.notifications, mode: this.mode });
    }
  }

  static get(context: any, mode: any) {
    return new Promise((resolve, reject) => {
      if (DashboardData.instance[mode.name])
        (resolve as any)(DashboardData.instance[mode.name], true);
      else
        DashboardData.instance[mode.name] = new DashboardData({ resolve, reject, context, mode });
    });
  }

  static getModeFromIndex(index: any) {
    return Object.values(DashboardData.mode)[index];
  }
}
