import React, { Component } from "react";
import { Dialog } from "../../components/dialogs/Dialog";

export class InvalidProfilePictureDialog extends Component<any, any> {
  render() {
    const { theme, onClose, reason } = this.props;

    return (
      <Dialog
        theme={theme}
        open={true}
        onClose={onClose}
        maxWidth='xs'
        title='Invalid profile picture'
        actions={[
          {
            label: "OK",
            primary: true,
            onClick: onClose
          }
        ]}
      >
        {reason}
      </Dialog>
    );
  }
}
