import { Gap_Attribute } from "../../constants";
import { AbstractAttributeBase } from "./AbstractAttributeBase";
import { AttributeRequestActivity } from "./AttributeRequestActivity";
export class AbstractAttribute extends AbstractAttributeBase {
  constructor(id: any, data: any) {
    super(id, data);
  }

  loadRequestActivity(context: any, updateCache: any) {
    return new Promise((resolve, reject) => {
      if (!this.requestActivity || updateCache === true) {
        this.requestActivity = new AttributeRequestActivity({
          attributeId: this.employeeAttributeId,
          resolve: (requestActivity: any) => {
            resolve(requestActivity);
          },
          reject,
          context
        });
      } else resolve(this.requestActivity);
    });
  }

  isUnderReview() {
    return this?.status?.index === AbstractAttribute.status.review.index;
  }

  isApproved() {
    return this?.status?.index === AbstractAttribute.status.approved.index;
  }

  isDeclined() {
    return this?.status?.index === AbstractAttribute.status.declined.index;
  }

  isNeedsFeedback() {
    return this?.status?.index === AbstractAttribute.status.feedback.index;
  }

  hasValidationRequest() {
    return this.validationRequestState;
  }

  isLowProficiency() {
    return this.reason === Gap_Attribute.reason.lowProficiency;
  }

  isMissing() {
    return this.reason === Gap_Attribute.reason.missing;
  }

  static hasRequiredDetails(attr: any) {
    if (attr.status === AbstractAttribute.status.feedback) return true;
    if (attr.hasExpiryDate === true && !attr.expiryDate) return false;
    if (attr.hasProficiency === true && !Number.isInteger(attr.proficiency)) return false;
    return true;
  }

  hasValidPendingExpiryDate() {
    return this.pendingExpiryDate !== undefined && this.pendingExpiryDate !== null;
  }

  hasValidPendingProficiency() {
    return (
      this.pendingProficiency !== undefined &&
      this.pendingProficiency !== null &&
      !isNaN(this.pendingProficiency) &&
      this.pendingProficiency !== 0
    );
  }
}
