import { Data } from "../Data";
import { SettingsGroup } from "./SettingsGroup";

export class SettingsGroupList extends Data {
  static instance: any = null;
  groups: any;

  constructor(options: any) {
    super({
      api: "/Group/List",
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.groups = [];
    data.groups.forEach((g: any, index: any) => this.groups.push(new SettingsGroup(g)));
  }

  add(group: any) {
    this.groups = [...this.groups];
    this.groups.push(group);
  }

  update(group: any) {
    const groups = [...this.groups];
    const index = groups.indexOf(this.get(group.groupId));
    if (index !== -1) groups.splice(index, 1, group);
    this.groups = [...groups];
  }

  get(id: any) {
    return this.groups.find((l: any) => l.id === id || l.groupsId === id);
  }

  delete(group: any) {
    const groups = [...this.groups];
    const index = groups.indexOf(group);
    if (index !== -1) groups.splice(index, 1);
    this.groups = [...groups];
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve: any, reject) => {
      if (SettingsGroupList.instance && updateCache !== true)
        resolve(SettingsGroupList.instance, true);
      else SettingsGroupList.instance = new SettingsGroupList({ resolve, reject, context });
    });
  }
}
