import { Card, CardContent, Skeleton, Stack } from "@mui/material";
import { TextOverflow, Theme } from "klayowebshared";
import React, { Component } from "react";
import { AttributeProgressBar } from "./AttributeProgressBar";
import { ProfilePicture } from "./ProfilePicture";

export class EmployeeCard extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  width: any;

  render() {
    const { employee, onClick, className } = this.props;

    return (
      <Card
        component='div'
        className={"klayo_peoplecard " + className}
        id={employee ? "klaro_peoplecard-" + employee.employeeId : undefined}
        onClick={employee ? (e: any) => onClick(e, employee) : undefined}
        sx={{ cursor: "pointer" }}
      >
        <CardContent>
          <ProfilePicture
            size='xl'
            user={employee}
            showCompliance={employee}
            sx={{
              top: 24,
              left: this.width ? this.width / 2 - 40 : "calc(50% - 40px)"
            }}
          />

          <div
            className='klayo_peoplecard_name'
            style={{
              top: 129,
              left: 0,
              width: this.width,
              display: "flex"
            }}
          >
            <TextOverflow
              tooltip={employee ? employee.fullName : null}
              centered={true}
              loading={!employee}
            >
              {employee ? employee.fullName : null}
            </TextOverflow>
          </div>

          <div
            className='klayo_peoplecard_job'
            style={{
              top: 157,
              left: 0,
              width: this.width,
              display: "flex"
            }}
          >
            <TextOverflow
              tooltip={employee ? employee.jobTitle : null}
              centered={true}
              loading={!employee}
            >
              {employee ? employee.jobTitle : null}
            </TextOverflow>
          </div>

          <AttributeProgressBar
            sx={{
              top: 213,
              left: 32,
              width: this.width ? this.width - 64 : "calc(100% - 64px)"
            }}
            color={Theme.getStyleVar("--neon-blue-nb-300")}
            label='Job match overview'
            showLabel={false}
            barHeight={8}
            met={employee ? employee.numberOfMetMandatoryAndRequiredAttributes : null}
            total={employee ? employee.totalNumberOfMandatoryAndRequiredAttributesForJob : null}
            loading={!employee}
          />

          <div
            className='klayo_peoplecard_attrs'
            style={{
              top: 236,
              left: 32
            }}
          >
            <Stack direction='row' spacing={1}>
              <span>Job competencies:</span>
              <b>
                {employee ? (
                  employee.jobAttributesCount
                ) : (
                  <Skeleton width='20px' height='18x' variant='rounded' />
                )}
              </b>
            </Stack>
          </div>
          <div
            className='klayo_peoplecard_gaps'
            style={{
              top: 236,
              right: 32
            }}
          >
            <Stack direction='row' spacing={1}>
              <span>Gaps:</span>
              <b>
                {employee ? (
                  employee.gapAttributesCount
                ) : (
                  <Skeleton width='20px' height='18x' variant='rounded' />
                )}
              </b>
            </Stack>
          </div>
        </CardContent>
      </Card>
    );
  }
}
