import { Box, Divider, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { DateLabel } from "klayowebshared";
import React, { Component } from "react";
import { InlineDocumentList } from "./InlineDocumentList";
import { ProfilePicture } from "./ProfilePicture";

export class RequestActivityItem extends Component<any, any> {
  render() {
    const { activity, user, employee, documents, onDocumentClick } = this.props;

    return (
      <Box className='klayo-feeditem'>
        <ListItem>
          <ListItemIcon sx={{ alignSelf: "start" }}>
            <ProfilePicture
              size='md'
              user={activity.user}
              src={activity.user?.getProfilePictureUrl()}
            />
          </ListItemIcon>
          <ListItemText className='klayo-feeditem_content' disableTypography={true}>
            <DateLabel
              className='klayo-feeditem_date'
              date={activity.createDate}
              sx={{ marginBottom: "10px" }}
            />
            <div className='klayo-feeditem_user'>
              {user.isMe(activity.user) ? "You" : activity.employeeFullName}
            </div>
            <div className='klayo-feeditem_status'>{activity.commentReason}</div>
            {activity.documentIds && activity.documentIds.length && (
              <div className='klayo-feeditem_documents'>
                <InlineDocumentList
                  onDocumentClick={onDocumentClick}
                  documents={documents}
                  filter={activity.documentIds}
                  sx={{ display: "flex", paddingBottom: "10px" }}
                />
              </div>
            )}
            {activity.text && activity.text.length && (
              <div
                className='klayo-feeditem_comment'
                dangerouslySetInnerHTML={{
                  __html: `<span> Comment added: ${activity.text} </span>`
                }}
              ></div>
            )}
          </ListItemText>
        </ListItem>
        <Divider />
      </Box>
    );
  }
}
