import { Component } from "react";
import { SelectMenu } from "../SelectMenu";

export class SortSelector extends Component<any, any> {
  static sort = {
    ascending: {
      label: "Sort A - Z",
      method: "asc"
    },
    descending: {
      label: "Sort Z - A",
      method: "desc"
    }
  };

  constructor(props: any) {
    super(props);

    this.state = {
      value: this.props.value
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.value !== this.props.value) this.setState({ value: this.props.value });
  }

  onChange(e: any) {
    const { onChange } = this.props;

    this.setState({ value: e.target.value }, () => {
      if (onChange) onChange(e, e.target.value);
    });
  }

  render() {
    const { sx, disabled, className } = this.props;
    const { value } = this.state;

    return (
      <SelectMenu
        value={value}
        placeholder='Sort'
        firstValueDefault={true}
        clearable={false}
        items={Object.entries(SortSelector.sort)}
        disabled={disabled}
        onChange={this.onChange.bind(this)}
        sx={sx}
        className={className}
      />
    );
  }
}
