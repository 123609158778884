import { Fade, LinearProgress } from "@mui/material";
import React, { Component } from "react";

export class LoadingBar extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: this.props.loading ? this.props.loading : false,
      loadingReason: null
    };
  }

  render() {
    const { loading } = this.state;
    const { sx } = this.props;

    return (
      <Fade in={loading === true}>
        <LinearProgress className='klayo-loadingbar' />
      </Fade>
    );
  }

  setLoading(loading: any) {
    this.setState({ loading });
  }
}
