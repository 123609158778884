import styled from "@emotion/styled";
import { Divider, ListItemText, Menu, MenuItem, Stack } from "@mui/material";
import { Delayed } from "klayowebshared";
import { AppContext } from "../../common/AppContext";
import axiosClient from "../../common/AxiosClient";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Button } from "../../components/Button";
import { Paper } from "../../components/Paper";
import { SelectMenu } from "../../components/SelectMenu";
import { Snackbar } from "../../components/Snackbar";
import { EmployeeTable } from "../../components/table/EmployeeTable";
import { TextField } from "../../components/TextField";
import { ViewComponent } from "../../components/ViewComponent";
import { EMPLOYEE_FILTER_STATUS, TableSearchDebounceTime } from "../../constants";
import { Data } from "../../data/Data";
import { SettingsEmployee } from "../../data/settings/SettingsEmployee";
import { SettingsEmployeeList } from "../../data/settings/SettingsEmployeeList";
import { ReactComponent as AddIcon } from "../../resources/images/icons-add.svg";
import { ReactComponent as SearchIcon } from "../../resources/images/icons-search.svg";
import { getSortByApiValue } from "../../utilities";
import { AddEditEmployeeView } from "./AddEditEmployeeView";
export class SettingsEmployeesView extends ViewComponent {
  static contextType = AppContext;
  debounceTimer: any;

  static defaultProps = {
    allowAssignAdminBilling: true
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ...(ViewComponent as any).state,
      search: null,
      orderByConvertList: [{ tableFieldName: "profilePicFullName", apiFieldName: "firstName" }],
      sortModel: [
        {
          field: "profilePicFullName",
          sort: "asc"
        }
      ],
      filter: Object.entries(EMPLOYEE_FILTER_STATUS)[0][1],
      actionTarget: null,
      actionEmployee: null,
      saveEmployeeError: null,
      downloadingDoc: null,
      paginationModel: {
        page: 0,
        pageSize: SettingsEmployeeList.defaultPageSize
      }
    };

    this.debounceTimer = null;
  }

  componentDidMount() {
    const { onLoadEmployees, onLoadAttributes, onLoadIntegration, onLoadCategories } = this.props;
    const { attributes, integration, categories } = this.props;

    if (!attributes) onLoadAttributes();
    if (!categories) onLoadCategories();
    onLoadEmployees();
    if (!integration) onLoadIntegration();
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  onViewChange(location: any) {
    if (this.pathEquals("/settings/employees")) this.resetErrors();
  }

  resetErrors() {
    this.setState({ saveEmployeeError: null });
  }

  onSearch(e: any) {
    const { onLoadEmployees } = this.props;
    const {
      filter,
      sortModel,
      orderByConvertList,
      paginationModel: { pageSize }
    } = this.state;
    const [{ field, sort }] = sortModel;
    const orderBy = getSortByApiValue(orderByConvertList, field, sort);
    const status = filter.value !== EMPLOYEE_FILTER_STATUS.ALL.value ? filter.value : null;
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      onLoadEmployees(true, e.target.value, pageSize, status, orderBy);
    }, TableSearchDebounceTime);
    const newModel = { page: 0, pageSize };
    this.setState({ search: e.target.value, paginationModel: newModel });
  }

  onPaginationModelChange(model: any) {
    const { page, pageSize } = model;
    this.setState({ paginationModel: model });
    const { onLoadEmployeesPageSize } = this.props;
    const { search, filter, sortModel, orderByConvertList } = this.state;
    const [{ field, sort }] = sortModel;
    const orderBy = getSortByApiValue(orderByConvertList, field, sort);
    const status = filter.value !== EMPLOYEE_FILTER_STATUS.ALL.value ? filter.value : null;
    onLoadEmployeesPageSize(page + 1, pageSize, search, status, orderBy);
  }

  onFilterChange(e: any) {
    const { onLoadEmployees } = this.props;
    const {
      search,
      sortModel,
      orderByConvertList,
      paginationModel: { pageSize }
    } = this.state;
    const [{ field, sort }] = sortModel;
    const orderBy = getSortByApiValue(orderByConvertList, field, sort);
    const status =
      e.target.value.value !== EMPLOYEE_FILTER_STATUS.ALL.value ? e.target.value.value : null;
    const newModel = { page: 0, pageSize };
    this.setState({ filter: e.target.value, paginationModel: newModel });
    onLoadEmployees(true, search, pageSize, status, orderBy);
  }

  onSortModelChange(sortModel: any) {
    const { onLoadEmployees } = this.props;
    const {
      search,
      filter,
      orderByConvertList,
      paginationModel: { pageSize }
    } = this.state;
    const [{ field, sort }] = sortModel;
    const orderBy = getSortByApiValue(orderByConvertList, field, sort);
    const status = filter.value !== EMPLOYEE_FILTER_STATUS.ALL.value ? filter.value : null;
    const newModel = { page: 0, pageSize };
    onLoadEmployees(true, search, pageSize, status, orderBy);
    this.setState({ sortModel, paginationModel: newModel });
  }

  onEmployeeAction(e: any, employee: any) {
    this.setState({ actionTarget: e.target, actionEmployee: employee });
  }

  onEmployeeSelect(e: any, employee: any) {
    this.props.history.push("/settings/employees/edit/" + employee.employeeId);
  }

  onEditEmployee() {
    const { actionEmployee } = this.state;
    this.onCloseActionMenu();

    this.props.history.push("/settings/employees/edit/" + actionEmployee.employeeId);
  }

  onChangeEmployeeStatus(status: any) {
    const { employees, onLoadEmployees } = this.props;
    const { actionEmployee } = this.state;

    this.onCloseActionMenu();
    this.context.setLoading("deleteEmployee", true);

    axiosClient
      .put("/Employee/Status", {
        employeeId: actionEmployee.employeeId,
        status: status.index
      })
      .then((response: any) => {
        const { search, pageSize, filter, sortModel, orderByConvertList } = this.state;

        const [{ field, sort }] = sortModel;
        const orderBy = getSortByApiValue(orderByConvertList, field, sort);
        const status = filter.value !== EMPLOYEE_FILTER_STATUS.ALL.value ? filter.value : null;

        actionEmployee.setStatus(status);
        employees.update(actionEmployee);
        if (onLoadEmployees) onLoadEmployees(true, search, pageSize, status, orderBy);
      })
      .finally(() => {
        this.context.setLoading("deleteEmployee", false);
      });
  }

  onSendInvite() {
    const { employees, onLoadEmployees } = this.props;
    const { actionEmployee } = this.state;

    this.onCloseActionMenu();
    this.context.setLoading("deleteEmployee", true);

    actionEmployee.setStatus(SettingsEmployee.status.invited);

    axiosClient
      .post("/Employee/ResendInvite", {
        employeeId: actionEmployee.employeeId
      })
      .then((response: any) => {
        const { search, pageSize, filter, sortModel, orderByConvertList } = this.state;

        const [{ field, sort }] = sortModel;
        const orderBy = getSortByApiValue(orderByConvertList, field, sort);
        const status = filter.value !== EMPLOYEE_FILTER_STATUS.ALL.value ? filter.value : null;

        employees.update(actionEmployee);
        this.setState({ successSnackbar: "Invitation sent" });
        if (onLoadEmployees) onLoadEmployees(true, search, pageSize, status, orderBy);
      })
      .finally(() => {
        this.context.setLoading("deleteEmployee", false);
      });
  }

  onCloseActionMenu() {
    this.setState({ actionTarget: null, actionEmployee: null });
  }

  onSaveEmployee(employee: any, callback: any) {
    const {
      user,
      employees,
      onLoadEmployees,
      onLoadJobs,
      onChangeCurrentUserFromSettings,
      onLoadAttributes,
      onDataChanged
    } = this.props;

    const { search, pageSize, filter, sortModel, orderByConvertList } = this.state;

    const [{ field, sort }] = sortModel;
    const orderBy = getSortByApiValue(orderByConvertList, field, sort);
    const status = filter.value !== EMPLOYEE_FILTER_STATUS.ALL.value ? filter.value : null;

    this.resetErrors();
    this.context.setLoading("saveEmployee", true);

    if (!employee.employeeId) {
      axiosClient
        .post("/Employee", employee.toApiFormData())
        .then((response: any) => {
          employee.employeeId = response.data.employee.employeeId;
          employee.rowId = response.data.employee.employeeId;
          employee.id = response.data.employee.employeeId;
          employees.add(employee);
          if (onLoadEmployees) onLoadEmployees(true, search, pageSize, status, orderBy);
          if (onLoadJobs) onLoadJobs(true);
          if (onLoadAttributes) onLoadAttributes(true);
          if (onDataChanged) onDataChanged("employees");
          this.props.history.push("/settings/employees");
        })
        .finally(() => {
          this.context.setLoading("saveEmployee", false);
          callback();
        });
    } else {
      axiosClient
        .put("/Employee", employee.toApiFormData())
        .then((response: any) => {
          employees.update(employee);
          if (onLoadEmployees) onLoadEmployees(true, search, pageSize, status, orderBy);
          if (onLoadJobs) onLoadJobs(true);
          if (onLoadAttributes) onLoadAttributes(true);
          if (onDataChanged) onDataChanged("employees");
          this.props.history.push("/settings/employees");

          if (employee.employeeId === user.employeeId && onChangeCurrentUserFromSettings)
            onChangeCurrentUserFromSettings(employee);
        })
        .finally(() => {
          this.context.setLoading("saveEmployee", false);
          callback();
        });
    }
  }

  onLoadPreviousAttributeActivity(attribute: any, callback: any) {
    if (attribute.isNew || !attribute.employeeAttributeId) {
      callback(attribute);
      return;
    }

    this.context.setLoading("loadPreviousAttributeActivity", true);

    attribute
      .loadRequestActivity(this.context)
      .then((activity: any) => {
        callback(attribute);
      })
      .catch((e: any) => {})
      .finally(() => this.context.setLoading("loadPreviousAttributeActivity", false));
  }

  onDocumentClick(e: any, doc: any) {
    this.context.setLoading("downloadingDoc", true);
    this.setState({ downloadingDoc: doc });

    Data.call("get", "/EmployeeAttribute/download/" + doc.documentId, { responseType: "blob" })
      .then((response) => {
        const blob = new Blob([response.data], { type: response.headers["content-type"] });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", doc.documentName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        this.setState({ error: ErrorModel.parseServerError(e) });
      })
      .finally(() => this.context.setLoading("downloadingDoc", false));
  }

  onDocumentDownloadSnackbarClose() {
    this.setState({ downloadingDoc: null });
  }

  onAssignPermissions(
    e: any,
    actionEmployee: any,
    isOrganizationAdmin: any,
    isBilling: any,
    isInstructor?: any
  ) {
    const { user, employees, onLoadEmployees } = this.props;

    this.onCloseActionMenu();
    this.context.setLoading("assignPermissions", true);

    axiosClient
      .put("/Employee/Permissions", {
        employeeId: actionEmployee.employeeId,
        isOrganizationAdmin: isOrganizationAdmin,
        isBilling: isBilling,
        isInstructor: isInstructor
      })
      .then((response: any) => {
        const { search, pageSize, filter, sortModel, orderByConvertList } = this.state;

        const [{ field, sort }] = sortModel;
        const orderBy = getSortByApiValue(orderByConvertList, field, sort);
        const status = filter.value !== EMPLOYEE_FILTER_STATUS.ALL.value ? filter.value : null;

        if (user.employeeId === actionEmployee.employeeId) {
          if (isOrganizationAdmin !== null) user.isOrganizationAdmin = isOrganizationAdmin;
          if (isBilling !== null) user.isBillingUser = isBilling;

          this.forceUpdate();
          if (user.isBillingUser) this.props.history.push("/settings/billing");
          else if (user.isOrganizationAdmin) this.props.history.push("/settings/employees");
          else this.props.history.push("/");
        }

        employees.update(actionEmployee);
        if (onLoadEmployees) onLoadEmployees(true, search, pageSize, status, orderBy);
      })
      .finally(() => {
        this.context.setLoading("assignPermissions", false);
      });
  }

  getEmployeeId() {
    const { location } = this.props;
    const path = location.pathname;
    return path.includes("edit") ? path.replace("/settings/employees/edit/", "") : null;
  }

  getEmployee() {
    const { employees } = this.props;
    if (!employees) return null;
    const editEmployeeId = this.getEmployeeId();
    return employees.get(editEmployeeId);
  }

  render() {
    const {
      organization,
      integration,
      onLoadIntegration,
      theme,
      user,
      location,
      history,
      managers,
      onShowNewCategoryDialog,
      onShowNewAttributeDialog,
      onLoadAttributes,
      employees,
      onDataChanged,
      onLoadManagers,
      orgSettings,
      attributes,
      allowAssignAdminBilling,
      onNavigation,
      onBlockNavigation,
      onAllowNavigation
    } = this.props;
    const {
      search,
      filter,
      sortModel,
      actionTarget,
      actionEmployee,
      saveEmployeeError,
      downloadingDoc,
      paginationModel
    } = this.state;

    const path = location.pathname;
    const editMode = path.includes("edit");
    const showAddEditEmployeeView =
      path.startsWith("/settings/employees/new") || path.startsWith("/settings/employees/edit");

    const editEmployee = this.getEmployee();
    const editEmployeeId = this.getEmployeeId();

    const filterItems = [];
    if (filter && filter.label !== EMPLOYEE_FILTER_STATUS.ALL.label)
      filterItems.push({
        id: "filter",
        columnField: "status",
        operatorValue: "contains",
        value: filter.label
      });
    if (search && search !== "")
      filterItems.push({
        id: "search",
        columnField: "profilePicFullName",
        operatorValue: "contains",
        value: search
      });

    return (
      <div>
        {!showAddEditEmployeeView ? (
          <Paper theme={theme} padding={{ xs: "46px 24px", md: "60px" }} borderFromBreakpoint='md'>
            <h1>Employees</h1>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              flexWrap='wrap'
              spacing={2}
              sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)", padding: "30px 0" }}
            >
              <TextField
                value={search}
                dense={true}
                placeholder='Search employees'
                disabled={user === null}
                fullWidth={true}
                autoComplete={false}
                leadingIcon={<SearchIcon />}
                onChange={this.onSearch.bind(this)}
                sx={{ maxWidth: { md: "300px" } }}
              />
              <StyledEmployeeFilterSection className='employee-filter-section'>
                <SelectMenu
                  className='employee-filter-section-menu'
                  value={filter}
                  firstValueDefault={true}
                  dense={true}
                  disabled={false}
                  items={Object.entries(EMPLOYEE_FILTER_STATUS)}
                  onChange={this.onFilterChange.bind(this)}
                  sx={
                    {
                      // maxWidth: { md: 'px' },
                      // width: { xs: '100%', sm: '100%' },
                      // [theme.breakpoints.down(showSearchFromBreakpoint)]: {
                      //     margin: '0!important'
                      // }
                    }
                  }
                />
                <Button
                  path={"/settings/employees/new"}
                  size='md'
                  theme={theme}
                  variant='filled'
                  showLabelFromBreakpoint='md'
                  startIcon={<AddIcon />}
                  label='New employee'
                />
              </StyledEmployeeFilterSection>
            </Stack>
            <Delayed>
              <EmployeeTable
                rowHasAction={true}
                minHeight='300px'
                theme={theme}
                showHeaderFromBreakpoint='md'
                filterMode='server'
                filterItems={filterItems}
                hideFirstLastBorder={true}
                dense={true}
                paginationMode='server'
                totalCount={this.props.employees?.totalCount}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                paginationModel={paginationModel}
                onPaginationModelChange={this.onPaginationModelChange.bind(this)}
                columns={[
                  { type: EmployeeTable.columns.fullName, visibleToBreakpoint: "md" },
                  { type: EmployeeTable.columns.profilePicFullName, visibleFromBreakpoint: "md" },
                  { type: EmployeeTable.columns.status, visibleFromBreakpoint: "md" },
                  { type: EmployeeTable.columns.actions }
                ]}
                sortingMode='server'
                sortModel={sortModel}
                onSortModelChange={this.onSortModelChange.bind(this)}
                onEmployeeSelect={this.onEmployeeSelect.bind(this)}
                onEmployeeAction={this.onEmployeeAction.bind(this)}
                paper={false}
                rows={employees ? employees.employees : null}
              />
            </Delayed>

            {actionEmployee && (
              <Menu
                open={actionTarget !== null}
                anchorEl={actionTarget}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                onClose={this.onCloseActionMenu.bind(this)}
              >
                <MenuItem onClick={this.onEditEmployee.bind(this)}>
                  <ListItemText>Edit employee</ListItemText>
                </MenuItem>

                {actionEmployee.status === SettingsEmployee.status.inactive && (
                  <>
                    <Divider />
                    <MenuItem
                      onClick={(e) => this.onChangeEmployeeStatus(SettingsEmployee.status.active)}
                    >
                      <ListItemText>Activate employee</ListItemText>
                    </MenuItem>
                  </>
                )}

                {(actionEmployee.status === SettingsEmployee.status.active ||
                  actionEmployee.status === SettingsEmployee.status.invited) && (
                  <>
                    <Divider />
                    <MenuItem
                      disabled={!employees.canDeactivate(actionEmployee)}
                      onClick={(e) => this.onChangeEmployeeStatus(SettingsEmployee.status.inactive)}
                    >
                      <ListItemText>Deactivate employee</ListItemText>
                    </MenuItem>
                  </>
                )}

                {actionEmployee.status === SettingsEmployee.status.added && (
                  <>
                    <Divider />
                    <MenuItem onClick={(e) => this.onSendInvite()}>
                      <ListItemText>Send invite</ListItemText>
                    </MenuItem>
                  </>
                )}

                {actionEmployee.status === SettingsEmployee.status.invited && (
                  <>
                    <Divider />
                    <MenuItem onClick={(e) => this.onSendInvite()}>
                      <ListItemText>Resend invite</ListItemText>
                    </MenuItem>
                  </>
                )}

                {allowAssignAdminBilling && (
                  <>
                    <Divider />
                    <MenuItem
                      disabled={
                        actionEmployee.isOrganizationAdmin &&
                        !employees.canUnassignAdmin(actionEmployee)
                      }
                      onClick={(e) =>
                        this.onAssignPermissions(
                          e,
                          actionEmployee,
                          !actionEmployee.isOrganizationAdmin,
                          null
                        )
                      }
                    >
                      <ListItemText>
                        {actionEmployee.isOrganizationAdmin
                          ? "Unassign as Admin"
                          : "Assign as Admin"}
                      </ListItemText>
                    </MenuItem>
                    <MenuItem
                      disabled={
                        !user.isBillingUser ||
                        (actionEmployee.isBilling && !employees.canUnassignBilling(actionEmployee))
                      }
                      onClick={(e) =>
                        this.onAssignPermissions(e, actionEmployee, null, !actionEmployee.isBilling)
                      }
                    >
                      <ListItemText>
                        {actionEmployee.isBilling ? "Unassign as Billing" : "Assign as Billing"}
                      </ListItemText>
                    </MenuItem>
                  </>
                )}
                <MenuItem
                  onClick={(e) =>
                    this.onAssignPermissions(
                      e,
                      actionEmployee,
                      null,
                      null,
                      !actionEmployee.isInstructor
                    )
                  }
                >
                  <ListItemText>
                    {actionEmployee.isInstructor
                      ? "Unassign as Instructor"
                      : "Assign as Instructor"}
                  </ListItemText>
                </MenuItem>
              </Menu>
            )}
          </Paper>
        ) : (
          <AddEditEmployeeView
            className='klayo__add-edit-employee-setting'
            user={user}
            organization={organization}
            theme={theme}
            editMode={editMode}
            employee={editMode ? editEmployee : new SettingsEmployee()}
            employeeId={editEmployeeId}
            integration={integration}
            onLoadIntegration={onLoadIntegration}
            error={saveEmployeeError}
            onShowNewAttributeDialog={onShowNewAttributeDialog}
            onShowNewCategoryDialog={onShowNewCategoryDialog}
            onLoadAttributes={onLoadAttributes}
            onLoadManagers={onLoadManagers}
            onDataChanged={onDataChanged}
            onLoadPreviousAttributeActivity={this.onLoadPreviousAttributeActivity.bind(this)}
            onDocumentClick={this.onDocumentClick.bind(this)}
            attributes={attributes}
            managers={managers}
            locations={orgSettings ? orgSettings.locations : null}
            onCancel={(e: any) => history.push("/settings/employees")}
            onSave={this.onSaveEmployee.bind(this)}
            onBlockNavigation={onBlockNavigation}
            onAllowNavigation={onAllowNavigation}
            onNavigation={onNavigation}
            {...this.props}
          />
        )}

        {this.renderSnackbar()}

        {downloadingDoc && (
          <Snackbar
            open={downloadingDoc}
            duration={6000}
            type='info'
            onClose={this.onDocumentDownloadSnackbarClose.bind(this)}
            message={"Downloaded document " + downloadingDoc.documentName}
          />
        )}
      </div>
    );
  }
}

const StyledEmployeeFilterSection = styled(Stack)`
  &.employee-filter-section {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;

    .employee-filter-section-menu {
      width: 100%;
      max-width: 140px;
    }

    @media (max-width: 900px) {
      margin-top: 16px;
      margin-left: 0px;
      gap: 16px;

      .employee-filter-section-menu {
        max-width: 100%;
      }
    }
  }
`;
