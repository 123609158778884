import { Stack } from "@mui/material";
import { BreakpointTemplate, Switch } from "klayowebshared";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import useDebounce from "../hooks/useDebounce";
import { ReactComponent as SearchIcon } from "../resources/images/icons-search.svg";
import { TextField } from "./TextField";
import { SortSelector } from "./selectors/SortSelector";
import React from "react";
 
type Props = {
  search?: string;
  onSearch: (search: string) => void;
  searchPlaceholder: string;
  isGapMode: boolean;
  onSwitchToGapMode: (e: ChangeEvent<HTMLInputElement>) => void;
  sortOrder?: any;
  onSortOrderChange?: (e: any, sortOrder: any) => void;
  theme?: any;
};

const TeamFilterSection = ({
  search,
  onSearch,
  searchPlaceholder,
  isGapMode,
  onSwitchToGapMode,
  sortOrder,
  onSortOrderChange,
  theme
}: Props) => {
  const [searchCurrent, setSearchCurrent] = useState("");
  const isFirstRender = useRef(true);

  const debounceSearch = useDebounce(searchCurrent.trim(), 500);

  useEffect(() => {
    // Skip the first render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setSearchCurrent(search ?? "");
      return;
    }


    // Only call the API on subsequent renders
    onSearch(debounceSearch);
  }, [debounceSearch]);

  return (
    <>
      <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
        <BreakpointTemplate
          theme={theme}
          breakpoint='sm'
          to={
            <SortSelector
              className='w-full'
              dense={true}
              value={sortOrder}
              onChange={onSortOrderChange}
            />
          }
          from={
            <TextField
              value={searchCurrent}
              dense={true}
              placeholder={searchPlaceholder}
              fullWidth={true}
              autoComplete={false}
              leadingIcon={<SearchIcon />}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchCurrent(e.target.value)}
              sx={{ maxWidth: { md: "400px" } }}
            />
          }
          sx={{
            flex: 1
          }}
        />
        <BreakpointTemplate
          theme={theme}
          breakpoint='sm'
          to={<Switch label='Gaps only' checked={isGapMode} onChange={onSwitchToGapMode} />}
          from={<Switch label='Show gaps only' checked={isGapMode} onChange={onSwitchToGapMode} />}
        />
      </Stack>
    </>
  );
};

export { TeamFilterSection };
