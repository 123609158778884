import { GridSortModel } from "@mui/x-data-grid";
import { DataGridProProps } from "@mui/x-data-grid-pro";
import { isEmpty } from "lodash";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { TeamFilterModel } from "../common/models/TeamFilterModel";
import { Utils } from "../common/Utils";
import { AbstractAttribute } from "../data/attributes/AbstractAttribute";
import { ITeamTraining, ITeamTrainingResponse } from "../interfaces/Training";
import { useTeamContext } from "../providers/TeamProvider";
import { appendIfUnique } from "../utilities";
import { useAppQuery } from "./useAppQuery";

type Props = {
  filter: TeamFilterModel;
  groupId?: string;
  basePath: string;
  basePathDepth: number;
  sortOrder?: any;
  onSortOrderChange?: (e: any, sortOrder: any) => void;
};

const useTeamTrainingSection = ({
  filter,
  groupId,
  basePath,
  basePathDepth,
  onSortOrderChange,
  sortOrder
}: Props) => {
  const tableRef = useRef<any>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "attribute", sort: sortOrder?.method || "asc" }
  ]);
  const [search, setSearch] = useState<string>("");
  const history = useHistory();
  const location = useLocation();
  const trainingPeopleListDialogRef = useRef<any>();

  const { gapMode, setGapMode } = useTeamContext();

  const isGapMode = useMemo(() => gapMode.trainings, [gapMode.trainings]);

  const filterParams = useMemo(() => {
    if (isEmpty(filter)) return "";

    const params = new URLSearchParams();

    filter.people.forEach((e) => appendIfUnique(params, "EmployeeIds", e.employeeId));
    filter.peopleIds.forEach((id) => appendIfUnique(params, "EmployeeIds", id));
    filter.jobs.forEach((j) => appendIfUnique(params, "Jobs", j));
    filter.locations.forEach((l) => appendIfUnique(params, "Locations", l));
    filter.attributes.forEach((a) =>
      appendIfUnique(params, "AttributeDefinitionIds", a.attributeDefinitionId)
    );
    filter.attributeIds.forEach((id) => appendIfUnique(params, "AttributeDefinitionIds", id));
    if (filter.compliance)
      appendIfUnique(
        params,
        "IsCompliant",
        (filter.compliance === AbstractAttribute.compliance.compliant) as any
      );
    if (filter.attributeType) appendIfUnique(params, "AttributeType", filter.attributeType.index);
    if (filter.expireAfter)
      appendIfUnique(params, "ExpiryDateFrom", Utils.toApiDate(filter.expireAfter) as any);
    if (filter.expireBefore)
      appendIfUnique(params, "ExpiryDateTo", Utils.toApiDate(filter.expireBefore) as any);

    return params.toString();
  }, [filter]);

  const { data, isFetching } = useAppQuery<ITeamTrainingResponse>(
    `/${groupId ? `MyGroup/${groupId}` : "Teams"}/Trainings?${filterParams}`
  );

  const trainings = useMemo(() => {
    if (!data?.trainings) return [];

    return data?.trainings?.map((training) => ({
      ...training,
      employees: [...training.completedEmployees],
      name: training.integrationAbbreviation
        ? `${training.name} | ${training.integrationAbbreviation}`
        : training.name
    }));
  }, [data]);

  const classificationsSummary = useMemo(() => {
    if (!trainings?.length) return {};

    return trainings.reduce(
      (summary: { [key: string]: Partial<ITeamTraining> }, training: ITeamTraining) => {
        if (
          (isGapMode && !training.gapEmployees.length) ||
          (!isGapMode && !training.completedEmployees.length)
        ) {
          return summary;
        }

        summary[training.name] = {
          name: training.name,
          employees: !isGapMode ? training.employees : training.gapEmployees,
          gapEmployees: training.gapEmployees,
          id: training.id,
          type: "training"
        };
        return summary;
      },
      {}
    );
  }, [trainings, isGapMode]);

  const generateTreeTasks = (trainings: ITeamTraining[]) => {
    if (!trainings) return [];

    return trainings.flatMap((training: ITeamTraining) => {
      const taskEntry = {
        ...training,
        rowId: training.id,
        trainingHierarchy: [training.id],
        type: "training"
      };

      const competencyEntries = training.competencies.map((competency) => ({
        ...competency,
        trainingHierarchy: [training.id, competency.attributeDefinitionId],
        employees: [...competency.gapEmployees.map((c) => ({ ...c, isCompliant: false }))].concat(
          [...competency.metEmployees].map((c) => ({ ...c, isCompliant: true }))
        ),
        rowId: `${training.id}_${competency.attributeDefinitionId}`,
        name: competency.attributeName,
        id: competency.attributeDefinitionId,
        type: "competency",
        isHiddenPeople: true
      }));

      return [taskEntry, ...competencyEntries];
    });
  };

  const filteredTrainings = useMemo(() => {
    const filteredTrainings = trainings.filter((training) =>
      isGapMode ? training.gapEmployees.length : true
    );

    if (!search) return filteredTrainings;

    const lowerCaseKeyword = search.toLowerCase();

    return filteredTrainings.filter((training) => {
      if (training.name.toLowerCase().includes(lowerCaseKeyword)) {
        return true;
      }

      return training.competencies.some((competency) =>
        competency.attributeName.toLowerCase().includes(lowerCaseKeyword)
      );
    });
  }, [trainings, search, isGapMode]);

  const treeTrainings = useMemo(() => generateTreeTasks(filteredTrainings), [filteredTrainings]);

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => row.trainingHierarchy;

  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    tableRef?.current?.setSortModel(model);
  };

  const handleSortOrderChange = (e: any, sortOrder: any) => {
    onSortModelChange([{ field: "attribute", sort: sortOrder.method }]);
    onSortOrderChange && onSortOrderChange(e, sortOrder);
  };

  const onSwitchToGapMode = (e: ChangeEvent<HTMLInputElement>) => {
    setGapMode({
      ...gapMode,
      trainings: e.target.checked
    });
  };

  const setLocation = (path: any, useNative?: any) => {
    history.push(path + "?" + filter.toUrlParams());

    if (useNative === true) window.history.pushState(null, "", path + "?" + filter.toUrlParams());
    else history.push(path + "?" + filter.toUrlParams());
  };

  const onCloseTrainingPeopleListDialog = () => {
    setLocation(basePath + "/" + "trainings", true);
    if (trainingPeopleListDialogRef.current) trainingPeopleListDialogRef.current.close();
  };

  const onPeopleSelect = (e: any, training: any) => {
    setLocation(basePath + "/trainings/" + training.id, true);
    if (trainingPeopleListDialogRef.current)
      trainingPeopleListDialogRef.current.open(training, false, training.type);
  };

  const onGapSelect = (e: any, training: any) => {
    setLocation(basePath + "/trainings/" + training.id + "?isGap=true", true);
    if (trainingPeopleListDialogRef.current)
      trainingPeopleListDialogRef.current.open(training, true, training.type);
  };

  useEffect(() => {
    if (isEmpty(treeTrainings)) return;

    const pathSplit = location.pathname.split("/");

    const training = treeTrainings.find((training) => training.id === pathSplit[basePathDepth + 2]);

    const isGap = location.search.includes("isGap=true");

    if (trainingPeopleListDialogRef.current && training)
      trainingPeopleListDialogRef.current.open(training, isGap, training.type);
  }, [treeTrainings, basePathDepth, location]);

  return {
    tableRef,
    sortModel,
    search,
    isGapMode,
    classificationsSummary,
    treeTrainings,
    setSearch,
    onSortModelChange,
    onSwitchToGapMode,
    isLoading: isFetching,
    trainingPeopleListDialogRef,
    onPeopleSelect,
    onCloseTrainingPeopleListDialog,
    onGapSelect,
    handleSortOrderChange,
    getTreeDataPath
  };
};

export { useTeamTrainingSection };
