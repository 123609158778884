import { Data } from "../Data";
import { UserProfile } from "./UserProfile";

export class User extends Data {
  //Based off EmployeeStatus { Added, Invited, Active, Inactive }
  static status = {
    added: {
      index: 0,
      label: "Added"
    },
    invited: {
      index: 1,
      label: "Invited"
    },
    active: {
      index: 2,
      label: "Active"
    },
    inactive: {
      index: 3,
      label: "Inactive"
    }
  };

  id!: string;
  employeeId!: string;
  rowId!: string;
  firstName!: string;
  isValidFirstName!: boolean;
  lastName!: string;
  fullName!: string;
  middleName!: string;
  preferredName!: string;
  company!: string;
  email!: string;
  location!: { locationId: string; name: string };
  organizationName!: string;
  isTeamLead!: boolean;
  isOrganizationAdmin!: boolean;
  isBilling!: boolean;
  isInstructor!: boolean;
  hasProfilePicture!: boolean;
  jobTitle!: string;
  proficiency!: number;
  department!: string;
  employeeAttributesCount!: number;
  jobAttributesCount!: number;
  gapAttributesCount!: number;
  numberOfMetMandatoryAndRequiredAttributes!: number;
  totalNumberOfMandatoryAndRequiredAttributesForJob!: number;
  isCompliant!: boolean;
  hasJob!: boolean;
  status!: any;
  profile!: any;

  constructor(options: any) {
    super(options);

    if (options.data) this.initData(options.data);
  }

  initData(data: any) {
    if (data.id !== undefined) {
      this.id = data.id;
    }
    this.employeeId = data.employeeId;
    this.rowId = data.employeeId;
    this.firstName = data.firstName;
    this.isValidFirstName = true;
    this.lastName = data.lastName;
    this.fullName = data.fullName ? data.fullName : data.firstName + " " + data.lastName;
    this.middleName = data.middleName;
    this.preferredName = data.preferredName;
    this.company = data.company;
    this.email = data.email;
    if (data.locationId) {
      this.location = { locationId: data.locationId, name: data.locationName };
    }
    this.organizationName = data.organizationName;
    this.isTeamLead = data.isTeamLead;
    this.isOrganizationAdmin =
      data.isOrganizationAdmin || (data.role && data.role.includes("Admin"));
    this.isBilling = data.isBilling || (data.role && data.role.includes("Billing"));
    this.isInstructor = data.isInstructor;
    this.hasProfilePicture = data.hasProfilePicture;
    this.jobTitle = data.jobTitle;
    this.proficiency = data.proficiencyValue;

    //Not sure if should move to another object?
    this.department = data.department;
    this.employeeAttributesCount = data.employeeAttributesCount;
    this.jobAttributesCount = data.jobAttributesCount;
    this.gapAttributesCount = data.gapAttributesCount;
    this.numberOfMetMandatoryAndRequiredAttributes = data.numberOfMetMandatoryAndRequiredAttributes;
    this.totalNumberOfMandatoryAndRequiredAttributesForJob =
      data.totalNumberOfMandatoryAndRequiredAttributesForJob;
    this.isCompliant = data.isCompliant;
    this.hasJob = data.hasJob;
  }

  isActive() {
    return this.status === User.status.active;
  }

  isMe(user: any) {
    return this.employeeId === user.employeeId;
  }

  updateName(first: any, last: any) {
    if (first !== undefined && first !== null) this.firstName = first;
    if (last !== undefined && last !== null) this.lastName = last;
    this.fullName = this.firstName + " " + this.lastName;
  }

  loadProfile(context: any, updateCache: any) {
    return new Promise((resolve, reject) => {
      if (this.profile && updateCache !== true) resolve(this.profile);
      else
        this.profile = new UserProfile({ employeeId: this.employeeId, resolve, reject, context });
    });
  }

  getProfilePictureUrl(date: any) {
    return (
      Data.apiBasePath +
      "/Profile/Avatar?employeeId=" +
      this.employeeId +
      (date ? "&v=" + date.getTime() : "")
    );
  }

  getInitials() {
    if (!this.firstName || !this.lastName) return "";
    return this.firstName.substr(0, 1) + this.lastName.substr(0, 1);
  }
}
