import { Document } from "./Document";

export class DocumentList {
  list: any;

  constructor(data: any) {
    this.list = [];

    data.forEach((d: any) => {
      this.list.push(new Document(d));
    });
  }

  getDocument(id: any) {
    return this.list.filter((e: any) => e.documentId === id)[0];
  }

  delete(doc: any) {
    doc.pendingDelete = true;
  }
}
