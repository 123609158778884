import { IMsalContext } from "@azure/msal-react";
import { LoginLayout } from "klayowebshared";
import React, { Component } from "react";

export class AuthLoadingView extends Component<IMsalContext, any> {
  render() {
    const { inProgress } = this.props;

    return <LoginLayout heading='Redirecting' text='Please wait...' loading={true}></LoginLayout>;
  }
}
