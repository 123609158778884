import { Data } from "../Data";

export class SubmitBilling extends Data {
  constructor(options: any) {
    super({
      api:
        options.method === "post"
          ? "/Organization/Admin/Billing"
          : "/Organization/Admin/Billing/Plan",
      postData: options.details,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  static post(context: any, details: any) {
    return new Promise(
      (resolve, reject) => new SubmitBilling({ resolve, reject, context, details, method: "post" })
    );
  }

  static put(context: any, details: any) {
    return new Promise(
      (resolve, reject) => new SubmitBilling({ resolve, reject, context, details, method: "put" })
    );
  }
}
