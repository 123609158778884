import { Box } from "@mui/material";
import React, { Component } from "react";

export class View extends Component<any, any> {
  static defaultProps = {
    fullWidth: false,
    scrollToTopOnMount: false
  };

  static topPadding = { xl: 42, lg: 42, md: 106, sm: 110, xs: 102 };

  componentDidMount() {
    const { scrollToTopOnMount } = this.props;
    if (scrollToTopOnMount) window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.showBillingBanner !== prevProps.showBillingBanner) this.forceUpdate();
  }

  getFullHeight(breakpoint: any) {
    const { showBillingBanner } = this.props;
    return (
      "calc(100vh - " +
      ((View as any).topPadding[breakpoint] + (showBillingBanner ? 44 : 0)) +
      "px)"
    );
  }

  render() {
    const { className, fullWidth, fullHeight, children, theme, sx, nopadding } = this.props;

    return (
      <Box
        component='main'
        className={
          "klayo-main" +
          (fullWidth ? " klayo-main--fullwidth" : "") +
          (className ? " " + className : "")
        }
        sx={{
          [theme.breakpoints.up("xl")]: {
            maxWidth: "1440px",
            margin: "0 auto",
            height: fullHeight ? this.getFullHeight("xl") : null,
            overflow: fullHeight ? "hidden" : null
          },
          [theme.breakpoints.up("lg")]: {
            padding: nopadding
              ? View.topPadding.lg + "px 0 0 0"
              : View.topPadding.lg + "px 48px 0 48px",
            maxWidth: "900px",
            margin: "0 auto",
            height: fullHeight ? this.getFullHeight("lg") : null,
            overflow: fullHeight ? "hidden" : null
          },
          [theme.breakpoints.up("md")]: {
            padding: nopadding
              ? View.topPadding.md + "px 0 0 0"
              : View.topPadding.md + "px 32px 0 32px",
            maxWidth: "900px",
            margin: "0 auto",
            height: fullHeight ? this.getFullHeight("md") : null,
            overflow: fullHeight ? "hidden" : null
          },
          [theme.breakpoints.up("sm")]: {
            padding: nopadding
              ? View.topPadding.sm + "px 0 0 0"
              : View.topPadding.sm + "px 32px 0 32px",
            margin: "0 auto",
            height: fullHeight ? this.getFullHeight("sm") : null,
            overflow: fullHeight ? "hidden" : null
          },
          [theme.breakpoints.up("xs")]: {
            padding: nopadding
              ? View.topPadding.xs + "px 0 0 0"
              : View.topPadding.xs + "px 16px 0 16px",
            margin: "0 auto",
            height: fullHeight ? this.getFullHeight("xs") : null,
            overflow: fullHeight ? "hidden" : null
          },
          ...sx
        }}
      >
        {children}
      </Box>
    );
  }
}
