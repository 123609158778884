export class SettingCourseFaculty {
  id: any;
  name: any;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
    }
  }
}
