import { Gap_Attribute } from "../../constants";
import { AbstractAttributeBase } from "./AbstractAttributeBase";
import { AttributeRequestActivity } from "./AttributeRequestActivity";

// export class GapAttribute extends AbstractAttribute {
export class GapAttribute extends AbstractAttributeBase {
  static reason = Gap_Attribute.reason;

  category: any;
  currentProficiency: any;
  requiredProficiency: any;
  validationRequestId!: any;
  requestedProficiency!: any;
  reasonLabel: any;

  constructor(index: any, data: any) {
    super(index, data);

    this.employeeAttributeId = data.employeeAttributeId;
    this.attributeName = data.attributeDefinitionName;
    this.category = data.attributeDefinitionCategory;
    this.required = data.jobAttributeRequirementType === 1;
    this.mandatory = data.jobAttributeRequirementType === 2;
    this.currentProficiency = data.employeeAttributeProficiencyValue;
    this.requiredProficiency = data.jobAttributeProficiencyMinimum;
    if (data.employeeAttributeValidationRequestState != null) {
      this.validationRequestId = data.employeeAttributeValidationRequestId;
      this.validationRequestState = Object.values(AbstractAttributeBase.status)[
        data.employeeAttributeValidationRequestState
      ];
      this.requestedProficiency = data.employeeAttributeValidationRequestProficiencyValue;
    }
    this.reason = Object.values(GapAttribute.reason)[data.gapReason];
    this.reasonLabel = this.reason.label;
    if (data.validationRequest) {
      this.requestedProficiency = data.validationRequest.proficiencyValue;
    }

    if (this.mandatory) this.type = AbstractAttributeBase.type.mandatory.label;
    else if (this.required) this.type = "Required";
    else this.type = "Desirable";
  }

  hasValidationRequest() {
    return this.validationRequestState;
  }

  isNeedsFeedback() {
    return (
      this.validationRequestState &&
      this.validationRequestState === AbstractAttributeBase.status.feedback
    );
  }

  isLowProficiency() {
    return this.reason === GapAttribute.reason.lowProficiency;
  }

  isMissing() {
    return this.reason === GapAttribute.reason.missing;
  }

  isGap() {
    return true;
  }

  loadRequestActivity(context: any, updateCache: any) {
    return new Promise((resolve, reject) => {
      if (!this.requestActivity || updateCache === true) {
        this.requestActivity = new AttributeRequestActivity({
          attributeId: this.employeeAttributeId,
          resolve: (requestActivity: any) => {
            resolve(requestActivity);
          },
          reject,
          context
        });
      } else resolve(this.requestActivity);
    });
  }
}
