import { Data } from "../Data";
import { SettingsRegulationCategory } from "./SettingRegulationCategory";

export class SettingsRegulationCategoryList extends Data {
  static instance: any = null;

  categories: any;

  constructor(options: any) {
    const { apiUrl, ...otherOptions } = options;

    const apiOptions = {
      api: apiUrl,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    super(apiOptions);
    this.categories = [];
  }

  onDataReceived(data: any) {
    this.categories = [];
    data.regulationCategories.forEach((g: any, index: any) =>
      this.categories.push(new SettingsRegulationCategory(g))
    );
  }

  static onGetData(context: any, options: any) {
    const { apiUrl } = options;
    return new Promise((resolve, reject) => {
      SettingsRegulationCategoryList.instance = new SettingsRegulationCategoryList({
        apiUrl,
        resolve,
        reject,
        context
      });
    });
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve: any, reject) => {
      if (SettingsRegulationCategoryList.instance && updateCache !== true)
        resolve(SettingsRegulationCategoryList.instance, true);
      else
        SettingsRegulationCategoryList.instance = new SettingsRegulationCategoryList({
          resolve,
          reject,
          context
        });
    });
  }
}
