import { Box, Grid, Stack } from "@mui/material";
import { BreakpointTemplate, Delayed, Switch, Theme } from "klayowebshared";
import { isEmpty } from "lodash";
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { TeamFilterModel } from "../../common/models/TeamFilterModel";
import { AttributeBubbleChart } from "../../components/charts/AttributeBubbleChart";
import { CategoryDonutChart } from "../../components/charts/CategoryDonutChart";
import { Paper } from "../../components/Paper";
import { SectionLoader } from "../../components/SectionLoader";
import { SortSelector } from "../../components/selectors/SortSelector";
import { AttributeTable } from "../../components/table/AttributeTable";
import { useTeamContext } from "../../providers/TeamProvider";
import { AttributePeopleListDialog } from "./AttributePeopleListDialog";

interface TeamAttributesSectionProps {
  allowExport?: boolean;
  heading?: string;
  organization?: any;
  theme?: any;
  attributes?: any;
  onAttributeSelect?: any;
  onGapSelect?: any;
  onShowExportDialog?: any;
  sortOrder?: any;
  onSortOrderChange?: (e: any, sortOrder: any) => void;
  loading?: boolean;
  onEmployeeSelect: (e: any, employee: any) => void;
  filter: TeamFilterModel;
  basePath: string;
  basePathDepth: number;
}

const TeamAttributesSection: React.FC<TeamAttributesSectionProps> = ({
  heading = "Team attributes",
  organization,
  theme,
  attributes,
  sortOrder,
  onSortOrderChange,
  loading,
  onEmployeeSelect,
  filter,
  basePath,
  basePathDepth
}) => {
  const [sortModel, setSortModel] = useState([
    { field: "attribute", sort: sortOrder?.method || "asc" }
  ]);
  const tableRef = useRef<any>(null);
  const attrPeopleListDialogRef = useRef<any>();

  const history = useHistory();
  const location = useLocation();

  const { gapMode, setGapMode } = useTeamContext();

  const isGapMode = useMemo(() => gapMode.competencies, [gapMode.competencies]);

  const classificationsSummary = useMemo(() => {
    const classificationsSummary = isGapMode
      ? Object.fromEntries(
          Object.entries(attributes?.classificationsSummary || {})
            .filter(([key, value]: any) => value.gapEmployees.length)
            .map(([key, value]: any) => [key, { ...value, employees: value.gapEmployees }])
        )
      : Object.fromEntries(
          Object.entries(attributes?.classificationsSummary || {})
            .filter(([key, value]: any) => value.metEmployees.length)
            .map(([key, value]: any) => [
              key,
              { ...value, employees: value.metEmployees, isBubble: true }
            ])
        );

    return classificationsSummary;
  }, [attributes?.classificationsSummary, isGapMode]);

  const categories = useMemo(() => {
    if (isEmpty(classificationsSummary)) return {};

    return Object.entries(classificationsSummary).reduce((acc: Record<string, any>, curr: any) => {
      const [_, value] = curr;
      value.categories.forEach((c: any) => {
        const category = acc[c];
        if (category) category.employees++;
        else acc[c] = { category: c, employees: 1, isGap: !!value.gapEmployees.length };
      });

      return acc;
    }, {});
  }, [classificationsSummary]);

  const title = useMemo(() => {
    const team = heading.split(" ")[0];
    return isGapMode ? `${team} gaps` : heading;
  }, [isGapMode, heading]);

  const rows = useMemo(() => {
    return isGapMode
      ? attributes?.attributes?.filter((a: any) => a.gapEmployees.length > 0)
      : attributes?.attributes || [];
  }, [isGapMode, attributes?.attributes]);

  const handleSortOrderChange = (e: any, sortOrder: any) => {
    handleSortModelChange([{ field: "attribute", sort: sortOrder.method }]);
    onSortOrderChange && onSortOrderChange(e, sortOrder);
  };

  const handleSortModelChange = (model: any) => {
    setSortModel(model);
    tableRef.current?.setSortModel(model);
  };

  const handleSwitchToGapMode = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setGapMode({
      ...gapMode,
      competencies: checked
    });
  };

  const setLocation = (path: any, useNative?: any) => {
    history.push(path + "?" + filter.toUrlParams());

    if (useNative === true) window.history.pushState(null, "", path + "?" + filter.toUrlParams());
    else history.push(path + "?" + filter.toUrlParams());
  };

  const onCloseAttrPeopleListDialog = () => {
    setLocation(basePath + "/competencies", true);
    if (attrPeopleListDialogRef.current) attrPeopleListDialogRef.current.close();
  };

  const onPeopleSelect = (e: any, attr: any) => {
    setLocation(basePath + "/competencies/" + attr.attributeDefinitionId, true);
    if (attrPeopleListDialogRef.current) attrPeopleListDialogRef.current.open(attr, false);
  };

  const onGapSelect = (e: any, attr: any) => {
    setLocation(basePath + "/competencies/" + attr.attributeDefinitionId + "?isGap=true", true);
    if (attrPeopleListDialogRef.current) attrPeopleListDialogRef.current.open(attr, true);
  };

  useEffect(() => {
    if (isEmpty(rows)) return;

    const pathSplit = location.pathname.split("/");

    const attribute = rows.find(
      (attribute: any) => attribute.attributeDefinitionId === pathSplit[basePathDepth + 2]
    );

    const isGap = location.search.includes("isGap=true");

    if (attrPeopleListDialogRef.current && attribute)
      attrPeopleListDialogRef.current.open(attribute, isGap);
  }, [rows, basePathDepth, location]);

  return (
    <>
      {loading ? (
        <Grid item xs={12}>
          <Box
            sx={{
              padding: "20px",
              background: "white",
              borderRadius: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <SectionLoader />
          </Box>
        </Grid>
      ) : (
        <Grid container spacing={4} alignItems='stretch'>
          <Grid item xs={12} md={8}>
            <Paper
              title={title}
              floatingTitle={true}
              padding={0}
              sx={{ height: "350px" }}
              overflow='visible'
            >
              <AttributeBubbleChart
                theme={theme}
                attributes={classificationsSummary}
                onAttributeSelect={!isGapMode ? onPeopleSelect : onGapSelect}
                sx={{ height: "350px" }}
                {...(isGapMode
                  ? {
                      bubbleColor: Theme.getStyleVar("--torch-red-tr-050"),
                      bubbleTextColor: Theme.getStyleVar("--grey-gr-900")
                    }
                  : {})}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper title='Competency categories' sx={{ padding: "20px", height: "310px" }}>
              <CategoryDonutChart
                categories={categories}
                sx={{ height: "280px", marginTop: "-16px" }}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center'>
              <BreakpointTemplate
                theme={theme}
                breakpoint='sm'
                to={
                  <SortSelector
                    className='w-full'
                    dense={true}
                    value={sortOrder}
                    onChange={handleSortOrderChange}
                  />
                }
                from={
                  <div className='klayo_teampeople_rowcount'>
                    {rows.length ? rows.length + " records" : ""}
                  </div>
                }
                sx={{
                  flex: 1
                }}
              />
              <BreakpointTemplate
                theme={theme}
                breakpoint='sm'
                to={
                  <Switch label='Gaps only' checked={isGapMode} onChange={handleSwitchToGapMode} />
                }
                from={
                  <Switch
                    label='Show gaps only'
                    checked={isGapMode}
                    onChange={handleSwitchToGapMode}
                  />
                }
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Delayed>
              <AttributeTable
                rows={rows}
                theme={theme}
                ref={tableRef}
                isGapMode={isGapMode}
                showHeaderFromBreakpoint='sm'
                columns={[
                  {
                    type: AttributeTable.columns.summaryWithPeople,
                    visibleToBreakpoint: "sm"
                  },
                  {
                    type: AttributeTable.columns.attribute,
                    label: "Competency",
                    visibleFromBreakpoint: "sm"
                  },
                  { type: AttributeTable.columns.category, visibleFromBreakpoint: "sm" },
                  { type: AttributeTable.columns.attributeEmployees, visibleFromBreakpoint: "sm" },
                  { type: AttributeTable.columns.gapEmployees, visibleFromBreakpoint: "sm" }
                ]}
                onSelectPeople={onPeopleSelect}
                onSelectGap={onGapSelect}
                showProficiency={organization?.showProficiency}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                noRowsMessage='Nothing here yet!'
              />
            </Delayed>
          </Grid>
        </Grid>
      )}
      <AttributePeopleListDialog
        ref={attrPeopleListDialogRef}
        organization={organization}
        theme={theme}
        onClose={onCloseAttrPeopleListDialog}
        onEmployeeSelect={onEmployeeSelect}
      />
    </>
  );
};

export { TeamAttributesSection };
