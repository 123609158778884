import { Data } from "../Data";
import { SettingsRegulationDomain } from "./SettingsRegulationDomain";

export class SettingsRegulationDomainList extends Data {
  static instance: any = null;
  domains: any;

  constructor(options: any) {
    const { apiUrl, ...otherOptions } = options;

    const apiOptions = {
      api: apiUrl,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    super(apiOptions);
    this.domains = [];
  }

  onDataReceived(data: any) {
    this.domains = [];
    data.regulationDomains.forEach((g: any, index: any) =>
      this.domains.push(new SettingsRegulationDomain(g))
    );
  }

  static onGetData(context: any, options: any) {
    const { apiUrl } = options;
    return new Promise((resolve, reject) => {
      SettingsRegulationDomainList.instance = new SettingsRegulationDomainList({
        apiUrl,
        resolve,
        reject,
        context
      });
    });
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve: any, reject) => {
      if (SettingsRegulationDomainList.instance && updateCache !== true)
        resolve(SettingsRegulationDomainList.instance, true);
      else
        SettingsRegulationDomainList.instance = new SettingsRegulationDomainList({
          resolve,
          reject,
          context
        });
    });
  }
}
