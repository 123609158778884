import { Utils } from "../../common/Utils";

export class ParticipantAttribute {
  name: any;
  attributeDefinitionId: any;
  mark: any;
  attendantStatus: any;
  comment: any;
  expiryDate: any;
  practicalParticipantId: any;
  hasExpiryDate: any;

  constructor(data: any) {
    // data from edit practical
    if (data.practicalParticipantId) {
      this.name = data.attributeDefinitionName;
      this.attributeDefinitionId = data.attributeDefinitionId;
      this.mark = data.mark;
      this.attendantStatus = data.attendantStatus;
      this.comment = data.comment ? data.comment : "";
      this.expiryDate = data.expiryDate ? Utils.toDtoDate(data.expiryDate) : null;
      this.practicalParticipantId = data.practicalParticipantId
        ? data.practicalParticipantId
        : null;
      this.hasExpiryDate = data.attributeDefinitionHasExpiryDate;

      return;
    }

    // data from create practical
    this.name = data.name;
    this.attributeDefinitionId = data.attributeDefinitionId;
    this.mark = data.mark || data.mark === 0 ? data.mark : null;
    this.attendantStatus = ![null, undefined].includes(data?.attendantStatus)
      ? data?.attendantStatus
      : true;
    this.comment = data?.comment ? data?.comment : "";
    this.expiryDate = data?.expiryDate ? Utils.toDtoDate(data?.expiryDate) : null;
    this.hasExpiryDate = data.hasExpiryDate;
  }
}
