import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { ReactComponent as MoreVertIcon } from "../../resources/images/icons-more-vertical.svg";
import { Table } from "./Table";

export class CategoryTable extends Table {
  static columns = {
    name: {
      field: "name",
      label: "Competency category"
    },
    actions: {
      field: "actions",
      label: "Actions"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    columns: [{ type: CategoryTable.columns.name }, { type: CategoryTable.columns.actions }],
    noRowsMessage: "Click the New category button to add a category",
    rowHasAction: null
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state,
      sortModel: this.props.sortModel
        ? this.props.sortModel
        : [
            {
              field: "name",
              sort: "asc"
            }
          ]
    };
  }

  getRowId(c: any) {
    return this.tableId + "_" + c.categoryId;
  }

  onRowClick(params: any, e: any) {
    const { onCategorySelect } = this.props;
    if (onCategorySelect) onCategorySelect(e, params.row);
  }

  initColumns() {
    const { columns, sortable } = this.props;

    this.columns = [];

    columns.forEach((c: any) => {
      if (this.hasColumn(c)) {
        if (c.type === CategoryTable.columns.name)
          this.columns.push({
            field: "name",
            sortable: sortable,
            headerName: c.label || c.type.label,
            flex: true,
            filterable: false,
            disableReorder: true
          });
      }

      if (c.type === CategoryTable.columns.actions)
        this.columns.push({
          field: "actions",
          type: "actions",
          filterable: false,
          disableReorder: true,
          align: "right",
          getActions: (params: any) => [
            <GridActionsCellItem
              icon={<MoreVertIcon />}
              label='Actions'
              onClick={(e) => this.props.onCategoryAction(e, params.row)}
              key={Math.random().toString(36).substring(7)}
            />
          ]
        });
    });
  }
}
