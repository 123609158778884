import { Stack } from "@mui/material";
import React, { Component } from "react";

export class DetailsGrid extends Component<any, any> {
  static defaultProps = {
    direction: "column",
    spacing: 2
  };

  render() {
    const { children, direction, spacing, fontSize, sx } = this.props;

    return (
      <Stack direction={direction} spacing={spacing} sx={sx}>
        {children}
      </Stack>
    );
  }
}
