import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { ReactComponent as MoreVertIcon } from "../../resources/images/icons-more-vertical.svg";
import { ProfilePicture } from "../ProfilePicture";
import { Table } from "./Table";

export class JobTable extends Table {
  static columns = {
    name: {
      field: "name",
      label: "Name"
    },
    profilePicFullName: {
      field: "profilePicFullName",
      label: "Name"
    },
    reportsTo: {
      field: "reportsTo",
      label: "Reports to",
      visibleFromBreakpoint: "md"
    },
    reports: {
      field: "reports",
      label: "Reports",
      visibleFromBreakpoint: "md"
    },
    positions: {
      field: "positions",
      label: "Positions",
      visibleFromBreakpoint: "md"
    },
    actions: {
      field: "actions",
      label: "Actions"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    columns: [
      { type: JobTable.columns.profilePicFullName },
      { type: JobTable.columns.reportsTo },
      { type: JobTable.columns.reports },
      { type: JobTable.columns.positions },
      { type: JobTable.columns.actions }
    ],
    noRowsMessage: null,
    rowHasAction: null,
    tree: true,
    disableSelection: true
  };
  deletedIds: any;

  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state,
      sortModel: this.props.sortModel
        ? this.props.sortModel
        : [
            {
              field: "profilePicFullName",
              sort: "asc"
            }
          ]
    };
    this.deletedIds = [];
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.focusPosition !== this.props.focusPosition) {
      const position = this.props.focusPosition;
      const api = this.apiRef.current;
      const jobRowId = this.getRowId(position);
      try {
        api.setRowChildrenExpansion(jobRowId, true);
      } catch (error) {}
    }
    if (this.apiRef.current) {
      this.apiRef.current.subscribeEvent("rowExpansionChange", (node: any) => {
        setTimeout(() => {
          if (this.deletedIds.length) {
            for (const id of this.deletedIds) {
              const element: any = document.querySelector(`[data-id=${id}]`);
              if (element && element.style) element.style.display = "none";
            }
          }
        }, 100);
      });
    }
  }

  getRowId(e: any) {
    return this.tableId + "_" + e.rowId;
  }

  getTreeDataPath(row: any) {
    return row.jobHierarchy;
  }

  onRowClick(params: any, e: any) {
    const { onJobSelect } = this.props;
    if (params.row.isJob && onJobSelect) onJobSelect(e, params.row);
  }

  initColumns() {
    const { columns, sortable, onJobAction, onPositionAction } = this.props;

    this.columns = [];

    columns.forEach((c: any) => {
      if (this.hasColumn(c)) {
        if (c.type === JobTable.columns.name)
          this.columns.push({
            field: "name",
            sortable: sortable,
            headerName: c.label || c.type.label,
            flex: true,
            filterable: false,
            disableReorder: true,
            valueGetter: (params: any) =>
              params.row.deleted ? "WW" : params.row.isJob ? params.row.name : params.row.fullName
          });
      }

      if (c.type === JobTable.columns.profilePicFullName)
        this.columns.push({
          field: "profilePicFullName",
          sortable: sortable,
          headerName: c.label || c.type.label,
          flex: 1,
          filterable: false,
          disableReorder: true,
          valueGetter: (params: any) =>
            params.row.deleted ? "WW" : params.row.isJob ? params.row.name : params.row.fullName,
          renderCell: (params: any) =>
            params.row.deleted ? null : params.row.isJob ? (
              params.row.name
            ) : (
              <div className='klayo_employeetable_pic klayo_employeetable_picname'>
                <ProfilePicture
                  user={params.row}
                  size='sm'
                  onClick={(e: any) => this.onUserClick(e, params.row)}
                />
                <div>
                  <div className='klayo_employeetable_name'>{params.row.fullName}</div>
                </div>
              </div>
            )
        });

      if (this.hasColumn(c)) {
        if (c.type === JobTable.columns.reportsTo)
          this.columns.push({
            field: "reportsTo",
            sortable: sortable,
            headerName: c.label || c.type.label,
            width: 250,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            valueGetter: (params: any) =>
              params.row.deleted ? null : params.row.manager ? params.row.manager.fullName : "",
            renderCell: (params: any) =>
              params.row.deleted ? null : params.row.manager ? (
                <div className='klayo_employeetable_pic klayo_employeetable_picname'>
                  <div>
                    <div className='klayo_employeetable_name'>{params.row.manager.fullName}</div>
                    <div className='klayo_employeetable_job'>{params.row.manager.jobTitle}</div>
                  </div>
                </div>
              ) : (
                ""
              )
          });
      }

      if (this.hasColumn(c)) {
        if (c.type === JobTable.columns.reports)
          this.columns.push({
            field: "reports",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            width: 100,
            type: "number",
            valueGetter: (params: any) =>
              params.row.deleted ? null : params.row ? params.row.numberOfTeamMembers : ""
          });
      }

      if (this.hasColumn(c)) {
        if (c.type === JobTable.columns.positions)
          this.columns.push({
            field: "positions",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            width: 100,
            type: "number",
            valueGetter: (params: any) =>
              params.row.deleted ? null : params.row.isJob ? params.row.numberOfPositions : ""
          });
      }

      if (c.type === JobTable.columns.actions)
        this.columns.push({
          field: "actions",
          type: "actions",
          filterable: false,
          disableReorder: true,
          align: "right",
          valueGetter: (params: any) => (params.row.deleted ? null : params),
          getActions: (params: any) =>
            params.row.deleted
              ? []
              : [
                  <GridActionsCellItem
                    icon={<MoreVertIcon />}
                    label='Actions'
                    onClick={(e) =>
                      params.row.isJob
                        ? onJobAction(e, params.row)
                        : onPositionAction(e, params.row)
                    }
                    key={Math.random().toString(36).substring(7)}
                  />
                ]
        });
    });
  }
}
