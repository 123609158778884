import { Data } from "../Data";
import { SettingsIntegration } from "./SettingsIntegration";

export class SettingsIntegrationList extends Data {
  integrations: any;
  currentPage: any;
  totalPages: any;
  totalCount: any;
  hasNext: any;
  hasPrevious: any;
  static instance: any = null;
  static defaultPageSize = 50;

  constructor(options: any) {
    const { integrationId, searchText, pageNumber, pageSize, orderBy, ...otherOptions } = options;
    const apiOptions = {
      api: `/Integration/Integrations`,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    // add params for api if provided
    const queryParams = [];
    if (searchText) queryParams.push(`searchText=${searchText}`);
    if (pageNumber) queryParams.push(`pageNumber=${pageNumber}`);
    if (pageSize)
      queryParams.push(`pageSize=${pageSize || SettingsIntegrationList.defaultPageSize}`);
    if (orderBy) queryParams.push(`orderBy=${orderBy}`);

    if (queryParams.length > 0) {
      apiOptions.api += `?${queryParams.join("&")}`;
    }

    super(apiOptions);

    this.integrations = [];
    this.currentPage = 1;
    this.totalPages = null;
    this.totalCount = null;
    this.hasNext = false;
    this.hasPrevious = false;
  }

  onDataReceived(data: any) {
    // reformat data from api then assign to state
    const { currentPage, totalPages, totalCount, hasNext, hasPrevious } = data.integrations;
    this.currentPage = currentPage;
    this.totalPages = totalPages;
    this.totalCount = totalCount;
    this.hasNext = hasNext;
    this.hasPrevious = hasPrevious;

    data.integrations.forEach((i: any, index: any) => {
      const integration = new SettingsIntegration(i);
      this.integrations.push(integration);
    });
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve: any, reject) => {
      if (SettingsIntegrationList.instance && updateCache !== true)
        resolve(SettingsIntegrationList.instance, true);
      else
        SettingsIntegrationList.instance = new SettingsIntegrationList({
          resolve,
          reject,
          context
        });
    });
  }

  static getWithPaging(context: any, options: any) {
    const { searchText, pageNumber, pageSize, orderBy, integrationId } = options;

    return new Promise((resolve, reject) => {
      SettingsIntegrationList.instance = new SettingsIntegrationList({
        searchText,
        pageNumber,
        pageSize,
        orderBy,
        integrationId,
        resolve,
        reject,
        context
      });
    });
  }
}
