import { Data } from "../Data";
import { SettingRegulation } from "./SettingRegulation";

export class SettingsRegulationList extends Data {
  static instance: any = null;

  regulations: any;

  constructor(options: any) {
    const { apiUrl, ...otherOptions } = options;

    const apiOptions = {
      api: apiUrl,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    super(apiOptions);
    this.regulations = [];
  }

  onDataReceived(data: any) {
    this.regulations = [];
    data.regulations.forEach((g: any, index: any) =>
      this.regulations.push(new SettingRegulation(g))
    );
  }

  static onGetData(context: any, options: any) {
    const { apiUrl } = options;
    return new Promise((resolve, reject) => {
      SettingsRegulationList.instance = new SettingsRegulationList({
        apiUrl,
        resolve,
        reject,
        context
      });
    });
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve: any, reject) => {
      if (SettingsRegulationList.instance && updateCache !== true)
        resolve(SettingsRegulationList.instance, true);
      else
        SettingsRegulationList.instance = new SettingsRegulationList({ resolve, reject, context });
    });
  }
}
