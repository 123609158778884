import React, { Component } from "react";
import { SettingsAttribute } from "../../data/settings/SettingsAttribute";
import { RepeatingSelect } from "./RepeatingSelect";

export class ExpiryNotificationSelector extends Component<any, any> {
  static defaultProps = {};
  constructor(props: any) {
    super(props);
  }

  render() {
    const { theme } = this.props;

    return (
      <RepeatingSelect
        theme={theme}
        {...this.props}
        defaultItem={SettingsAttribute.notificationPeriods.none}
        items={SettingsAttribute.notificationPeriods}
        itemComparator={(e1: any, e2: any) => e1.index === e2.index}
      />
    );
  }
}
