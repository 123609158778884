import { AppContext } from "../../common/AppContext";
import { Utils } from "../../common/Utils";
import { EmployeeSelectorList } from "../../data/filters/EmployeeSelectorList";
import { ManagerSelectorList } from "../../data/filters/ManagerSelectorList";
import { AbstractFilterSelect } from "./AbstractFilterSelect";

export class EmployeeSelector extends AbstractFilterSelect {
  static contextType = AppContext;

  static defaultProps = {
    ...AbstractFilterSelect.defaultProps,
    label: "People",
    managersOnly: false,
    listType: "Employees"
  };

  constructor(props: any) {
    super(props);

    this.dataList =
      props.dataList ?? (props.managersOnly ? ManagerSelectorList : EmployeeSelectorList);
  }

  compareItems(a: any, b: any) {
    return a.employeeId === b.employeeId;
  }

  getItemLabel(item: any) {
    const { hasNoneItem, noneItemLabel, hasAllItem, allItemLabel, hasNewItem, newItemLabel } =
      this.props;

    if (hasNoneItem && item.isNoneItem === true) return noneItemLabel;
    else if (hasAllItem && item.isAllItem === true) return allItemLabel;
    else if (hasNewItem && item.isNewItem === true) return newItemLabel;
    return item.fullName;
  }

  getItemSecondaryAction(item: any) {
    const { theme, getItemSecondaryAction } = this.props;
    return getItemSecondaryAction
      ? getItemSecondaryAction(item)
      : theme && theme.isBreakpointDown("sm")
        ? null
        : Utils.truncate(item.jobTitle, theme && theme.isBreakpointUp("md") ? 30 : 20);
  }

  getList(dataList: any) {
    return dataList.employees;
  }

  findValueItem(items: any, value: any) {
    return items
      ? items.find((c: any) => c.fullName === value)
      : items.find((c: any) => c.fullName && c.fullName.includes(value));
  }
}
