import { AbstractAttributeList } from "./AbstractAttributeList";
import AttributeSummary from "./AttributeSummary";

export class AbstractTeamAttributeList extends AbstractAttributeList {
  classificationsSummary: Record<string, any>;
  categorySummary: Record<string, any>;

  constructor(options: any) {
    super(options);

    this.classificationsSummary = {};
    this.categorySummary = {};

    options.data.forEach((attr: any, index: any) => {
      const summary: any = new AttributeSummary(index, attr);
      this.attributes.push(summary);
      this.classificationsSummary[attr.attributeName] = { id: index, ...summary };

      //const catName = attr.category && attr.category !== '' ? attr.category : 'Other';
      //const category = this.categorySummary[catName];
      //if (category) category.employees++;
      //else this.categorySummary[catName] = { category: catName, employees: 1 };
    });
  }

  initAttributesLists() {
    super.initAttributesLists();

    this.attributes.forEach((a: any) => {
      if (a.categories)
        a.categories.forEach((c: any) => {
          const category = this.categorySummary[c];
          if (category) category.employees++;
          else
            this.categorySummary[c] = { category: c, employees: 1, isGap: !!a.gapEmployees.length };
        });
    });
  }
}
