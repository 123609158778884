import { Alert, Stack } from "@mui/material";
import React, { Component } from "react";
import { Button } from "./Button";

export class BillingBanner extends Component<any, any> {
  render() {
    const { organization, user, onUpgradePlan } = this.props;

    return (
      <Alert className='klayo-billingbanner' icon={false} sx={{ overflow: "visible" }}>
        <Stack direction='row' spacing={4}>
          <div className='klayo-billingbanner_message'>
            {organization && organization.trialDaysRemaining >= 1
              ? "You have " +
                organization.trialDaysRemaining +
                " day" +
                (organization.trialDaysRemaining === 1 ? "" : "s") +
                " left in your trial"
              : "You are in trial mode"}
          </div>
          <div className='klayo-billingbanner_actions'>
            {user && user.isBillingUser && (
              <Button label='Upgrade now' size='sm' variant='filled' onClick={onUpgradePlan} />
            )}
          </div>
        </Stack>
      </Alert>
    );
  }
}
