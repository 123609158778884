import { HubConnectionBuilder } from "@microsoft/signalr";
import { Dashboard_mode } from "../../constants";
import { Data } from "../Data";
import { Notification } from "./Notification";

export class NotificationsConnection extends Data {
  static instance: any = null;

  constructor(options: any) {
    super(options);

    Data.getAccessToken(options.context)
      .then((accessToken: any) => {
        const connection = new HubConnectionBuilder()
          .withUrl(`${(window as any).app.env.API_URL}/live/notifications`, {
            accessTokenFactory: () => accessToken
          })
          .build();

        connection.on("receiveNotification", (notif) => {
          const json = JSON.parse(notif);
          this.onNotificationReceived(
            this.parseNotification(json),
            json.NumberOfUnreadNotification
          );
        });

        connection
          .start()
          .then(() => {
            options.resolve(this, false);
          })
          .catch((e) => {
            options.reject(e);
          });
      })
      .catch((e) => {
        options.reject(e);
      });
  }

  parseNotification(json: any) {
    return new Notification(json.Notification, this.getModeFromIndex(json.NotificationType));
  }

  onNotificationReceived(notif: any, numUnread: any) {}

  getModeFromIndex(index: any) {
    return Object.values(Dashboard_mode)[index];
  }

  static get(context: any) {
    return new Promise((resolve, reject) => {
      if (NotificationsConnection.instance) resolve(NotificationsConnection.instance);
      else
        NotificationsConnection.instance = new NotificationsConnection({
          resolve,
          reject,
          context
        });
    });
  }
}
