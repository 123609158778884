import { Box, ClickAwayListener } from "@mui/material";
import { Component } from "react";
import { ReactComponent as QuestionIcon } from "../resources/images/icons-question-circle.svg";
import { Button } from "./Button";
import { Tooltip } from "./Tooltip";

export class TooltipIcon extends Component<any, any> {
  static defaultProps = {
    placement: "left",
    icon: <QuestionIcon />,
    maxWidth: { xs: "150px", md: "300px" }
  };

  constructor(props: any) {
    super(props);

    this.state = {
      tooltipOpen: false
    };
  }

  onButtonClick() {
    this.setState({ tooltipOpen: true });
  }

  onTooltipClose() {
    this.setState({ tooltipOpen: false });
  }

  render() {
    const { text, icon, placement, tooltipSx, iconSx, sx, maxWidth } = this.props;
    const { tooltipOpen } = this.state;

    return (
      <ClickAwayListener onClickAway={this.onTooltipClose.bind(this)}>
        <Box sx={sx}>
          <Tooltip
            title={text}
            open={tooltipOpen}
            onClose={this.onTooltipClose.bind(this)}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement={placement}
            maxWidth={{ xs: "200px", md: "300px" }}
            sx={tooltipSx}
          >
            <div>
              <Button
                className='klayo-tooltipicon'
                onClick={this.onButtonClick.bind(this)}
                icon={icon}
                sx={iconSx}
              />
            </div>
          </Tooltip>
        </Box>
      </ClickAwayListener>
    );
  }
}
