import { Component } from "react";
import { AbstractAttribute } from "../../data/attributes/AbstractAttribute";
import { SelectMenu } from "../SelectMenu";

export class AttributeComplianceSelector extends Component<any, any> {
  showAllItem: any;
  constructor(props: any) {
    super(props);

    this.showAllItem = { key: "all", label: "Show all" };

    this.state = {
      compliance: this.props.value
    };
  }

  onChange(e: any) {
    const { onChange } = this.props;

    this.setState({ compliance: e.target.value }, () => {
      onChange(e);
    });
  }

  onClear(e: any) {
    e.target.value = "";
  }

  render() {
    const { sx } = this.props;
    const { compliance } = this.state;

    return (
      <SelectMenu
        value={compliance}
        label='Compliance'
        placeholder='Select'
        firstValueDefault={true}
        items={Object.entries({ all: this.showAllItem, ...AbstractAttribute.compliance })}
        onChange={this.onChange.bind(this)}
        sx={sx}
      />
    );
  }
}
