export class Document {
  documentId: string;
  type: string;
  documentName: string;
  isDeleted: boolean;
  pendingDelete: boolean;
  createDate: Date;

  constructor(data: any) {
    this.documentId = data.documentId;
    this.type = data.documentData ?? data.type;
    this.documentName = data.documentName;
    this.isDeleted = data.isDeleted === true;
    this.pendingDelete = data.pendingDelete === true;
    this.createDate = data.createDate;
  }
}
