import { Box, Grid } from "@mui/material";
import { JobDescriptionContainer, Switch } from "klayowebshared";
import { Component } from "react";
import { Utils } from "../../common/Utils";
import { AttributeProgressOverview } from "../../components/AttributeProgressOverview";
import { Paper } from "../../components/Paper";
import { Placeholder } from "../../components/Placeholder";
import { SectionLoader } from "../../components/SectionLoader";
import { AttributeTable } from "../../components/table/AttributeTable";
import { MyProfile_MyJob_ShowTasks_LocalStorageKey } from "../../constants/constants";
import { EmployeeAttribute } from "../../data/attributes/EmployeeAttribute";
import { GapAttribute } from "../../data/attributes/GapAttribute";
import { ReactComponent as UserIcon } from "../../resources/images/icons-user.svg";
import { JobDescriptionDialog } from "./JobDescriptionDialog";

export class ProfileJobSection extends Component<any, any> {
  static descriptionLimit = 500;

  constructor(props: any) {
    super(props);

    this.state = {
      showFullDescription: false,
      treeMode: true,
      hasTask: true
    };
  }

  checkShowTask() {
    const { taskAttributesTree, flatAttributes } = this.props.profile?.jobAttributes || {};
    const hasTask = taskAttributesTree?.length > flatAttributes?.length;
    this.setState({ hasTask });
    if (hasTask) {
      const storageShowTask = localStorage.getItem(MyProfile_MyJob_ShowTasks_LocalStorageKey);

      if (storageShowTask) this.setState({ treeMode: storageShowTask === "true" });
      else this.setState({ treeMode: true });
    } else {
      this.setState({ treeMode: false });
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.profile?.jobAttributes !== this.props.profile?.jobAttributes) {
      this.checkShowTask();
    }
  }

  componentDidMount() {
    this.checkShowTask();
  }

  onOpenJobDescriptionDialog(e: any) {
    e.preventDefault();
    this.setState({ showFullDescription: true });
  }

  onCloseJobDescriptionDialog(e: any) {
    e.preventDefault();
    this.setState({ showFullDescription: false });
  }

  onHighlightRowComplete() {
    Utils.removeLocationHash();
  }

  onSwitchShowTask(e: any) {
    const checked = e.target.checked;
    Utils.setLocalStorageOption(MyProfile_MyJob_ShowTasks_LocalStorageKey, checked.toString());
    this.setState({ treeMode: checked });
  }

  handleShowDialog(e: any, row: any) {
    const {
      onShowAttributeDetails,
      onAddChangeAction,
      onFeedbackAction,
      myView,
      isInstructorViewing
    } = this.props;

    const isAllow = myView || isInstructorViewing;

    if ((row as any).isTask) return;

    if ((row as any).missing && isAllow) onAddChangeAction(e, row);

    if (row instanceof EmployeeAttribute) {
      onShowAttributeDetails(e, row);
      return;
    }
    if (row instanceof GapAttribute && row?.validationRequestState) {
      if (row.validationRequestState?.index === 1 && isAllow) {
        onFeedbackAction(e, row);
        return;
      }
      onShowAttributeDetails(e, row);
      return;
    }
  }

  render() {
    const {
      organization,
      user,
      employee,
      theme,
      profile,
      readOnly,
      location,
      onShowAttributeDetails,
      onAddChangeAction,
      onFeedbackAction
    } = this.props;
    const { showFullDescription, treeMode, hasTask } = this.state;

    const highlightAttr = location.hash ? location.hash.substring(1) : null;

    let mandatoryMet,
      mandatoryTotal,
      requiredMet,
      requiredTotal,
      desirableMet,
      desirableTotal = 0;
    if (profile && profile.attributeSummary) {
      ({ mandatoryMet, mandatoryTotal, requiredMet, requiredTotal, desirableMet, desirableTotal } =
        profile.attributeSummary);
    }

    const { taskAttributesTree, flatAttributes } = profile?.jobAttributes || {};

    return (
      <Box>
        {employee && employee.hasJob && (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  className='klaro_section klaro_profile-jobdesc'
                  title={readOnly ? "Job description" : "My job description"}
                  sx={{ height: "100%", boxSizing: "border-box" }}
                >
                  {!employee || !profile ? (
                    <SectionLoader />
                  ) : (
                    <JobDescriptionContainer
                      text={profile.jobDescription ? profile.jobDescription : ""}
                      maxHeight={180}
                      onShowMore={this.onOpenJobDescriptionDialog.bind(this)}
                    />
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  className='klaro_section klaro_profile-attroverview'
                  title={readOnly ? "Job competencies overview" : "My job competencies overview"}
                  sx={{ height: "100%", boxSizing: "border-box" }}
                >
                  {!employee || !profile ? (
                    <SectionLoader />
                  ) : (
                    <AttributeProgressOverview
                      mandatoryMet={mandatoryMet}
                      mandatoryTotal={mandatoryTotal}
                      requiredMet={requiredMet}
                      requiredTotal={requiredTotal}
                      desirableMet={desirableMet}
                      desirableTotal={desirableTotal}
                      spacing='20px'
                    />
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <AttributeTable
                  title={readOnly ? "Job competencies" : "My job competencies"}
                  titleRightSide={
                    hasTask ? (
                      <Switch
                        label='Show tasks'
                        checked={treeMode}
                        onChange={this.onSwitchShowTask.bind(this)}
                      />
                    ) : null
                  }
                  theme={theme}
                  minHeight='300px'
                  dense={true}
                  rows={
                    profile?.jobAttributes ? (treeMode ? taskAttributesTree : flatAttributes) : null
                  }
                  tree={treeMode}
                  showHeaderFromBreakpoint='md'
                  selectable={true}
                  highlightRow={highlightAttr}
                  onHighlightRowComplete={this.onHighlightRowComplete.bind(this)}
                  columns={[
                    { type: AttributeTable.columns.summary },
                    {
                      type: AttributeTable.columns.attribute,
                      label: treeMode ? "TASKS / COMPETENCIES" : "COMPETENCIES"
                    },
                    { type: AttributeTable.columns.category },
                    { type: AttributeTable.columns.attributeType },
                    { type: AttributeTable.columns.requiredProficiency },
                    {
                      type: AttributeTable.columns.currentProficiency,
                      label: readOnly ? "Proficiency" : "My Proficiency"
                    },
                    { type: AttributeTable.columns.gapIcon }
                  ]}
                  readOnly={readOnly}
                  rowHasAction={() => true}
                  showProficiency={organization && organization.showProficiency}
                  noResultsMessage={{
                    primary: "No search results",
                    secondary: "We couldn’t find any results for your search"
                  }}
                  sx={{ padding: "23px" }}
                  tableSx={{ margin: "0 -7px" }}
                  onAttributeSelect={(e: any, row: any) => {
                    this.handleShowDialog(e, row);
                  }}
                  rowHasSelect={() => true}
                />
              </Grid>
            </Grid>
          </>
        )}

        {employee && !employee.hasJob && (
          <Placeholder
            text={{
              primary: "Nothing to show yet",
              secondary: (
                <div>
                  This section requires having a job assigned to{" "}
                  {user && user.isMe(employee) ? "you" : "the employee"}.
                  {user && user.isOrganizationAdmin && (
                    <div>Go to Settings &gt; Employees and add a job to your employee details.</div>
                  )}
                </div>
              )
            }}
            icon={<UserIcon />}
            sx={{ height: "100vh", maxHeight: "40vh" }}
          />
        )}

        {showFullDescription && (
          <JobDescriptionDialog
            theme={theme}
            description={profile.jobDescription}
            onClose={this.onCloseJobDescriptionDialog.bind(this)}
          />
        )}
      </Box>
    );
  }
}
