import { Card, CardActionArea, CardContent, Stack } from "@mui/material";
import { Component } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../resources/images/icons-chevron-right.svg";
import { Button } from "./Button";

export class SettingsCard extends Component<any, any> {
  render() {
    const { icon, heading, text, actionPath } = this.props;

    return (
      <Card variant='outlined' className='klayo-settingscard'>
        <CardActionArea
          to={actionPath}
          component={NavLink}
          disableRipple={true}
          disableTouchRipple={true}
          focusRipple={false}
        >
          <CardContent sx={{ padding: { xs: "32px 24px", md: "32px" } }}>
            <Stack direction='row' spacing={2}>
              <div className='klayo-settingscard_icon'>{icon}</div>
              <div>
                <h2>{heading}</h2>
                <p>{text}</p>
                <Button
                  path={actionPath}
                  size='sm'
                  variant='simple'
                  label='Set up now'
                  labelColor='var(--neon-blue-nb-500)'
                  endIcon={<ArrowIcon />}
                  sx={{ marginLeft: "-12px" }}
                />
              </div>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}
