import React, { Component } from "react";
import { MultiSelector } from "./MultiSelector";

export class MultiAttributeSelector extends Component<any, any> {
  static defaultProps = {
    label: "Competencies"
  };

  constructor(props: any) {
    super(props);

    this.state = {};
  }

  render() {
    const { items, label, getItemLabel, hasNewItem = true } = this.props;
    return <MultiSelector {...this.props} hasNewItem={hasNewItem} />;
  }
}
