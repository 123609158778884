import { Data } from "../Data";
export class AbstractAttributeList extends Data {
  static allFilter = { key: "all", label: "Show all" };

  attributes: any[];
  allAttributes: any[];
  flatAttributes: any[];
  treeAttributes: any[];

  constructor(options: any) {
    super(options);
    this.attributes = [];
    this.allAttributes = [];
    this.flatAttributes = [];
    this.treeAttributes = [];
  }

  initAttributesLists() {
    let childAttrs: any[] = [];

    this.attributes.forEach((a) => (childAttrs = childAttrs.concat(a.childAttributes)));

    this.allAttributes = [...this.attributes, ...childAttrs];

    this.flatAttributes = [...this.attributes];

    this.treeAttributes = [
      ...this.attributes.filter(
        (a) => !childAttrs.find((a2) => a?.attributeDefinitionId === a2?.attributeDefinitionId)
      ),
      ...childAttrs
    ];
  }

  getAttributeByEmployeeAttributeId(id: any) {
    return this.attributes.find((a) => a.employeeAttributeId === id);
  }

  fetchAttributeByEmployeeAttributeId(context: any, id: any) {
    return new Promise((resolve, reject) => {
      const attr = this.getAttributeByEmployeeAttributeId(id);
      if (attr) resolve(attr);
      else {
        this.updateData(context).then((data) => {
          const attr = this.getAttributeByEmployeeAttributeId(id);

          if (attr) resolve(attr);
          else reject();
        });
      }
    });
  }

  getAttribute(id: any) {
    return this.attributes.find((a) => a?.attributeDefinitionId === id && a.isChild !== true);
  }

  hasDefinition(id: any) {
    return this.attributes.find((a) => a?.attributeDefinitionId === id) !== undefined;
  }

  removeAttribute(attr: any) {
    this.attributes = this.attributes.filter(
      (a) => a.employeeAttributeId !== attr.employeeAttributeId
    );
  }

  getRequiredAttributes() {
    return this.attributes.filter((a) => a.required).length;
  }

  getMandatoryAttributes() {
    return this.attributes.filter((a) => a.mandatory).length;
  }
}
