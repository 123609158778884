import styled from "@emotion/styled";
import React, { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { KLAYO_COLORS } from "../../themes";
import { Dialog } from "../dialogs/Dialog";
import { SingleColumnTable } from "./SingleColumnTable";
export class RelatedTaskInAttributeDialog extends Component<any, any> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);

    this.state = {
      attributeData: []
    };
  }

  onAttributeSelect(e: any, attribute: any) {
    return;
  }

  render() {
    const { theme, onClose, type, tasksHasAttribute } = this.props;

    const dialogHeader = "Linked Tasks";

    const subHeader = `Some tasks require this competency:`;

    return (
      <StyledTrainingAttributesDialog
        className='klayo__training-attribute-dialog'
        open={true}
        theme={theme}
        onClose={onClose}
        fullWidth
        maxWidth='sm'
        actions={[
          {
            label: "Close",
            variant: "filled",
            onClick: onClose
          }
        ]}
      >
        <h1>{dialogHeader}</h1>
        <h2>{subHeader}</h2>

        <SingleColumnTable
          showFooterRowCount={false}
          pagination={false}
          rowHasAction={true}
          minHeight='56px'
          theme={theme}
          showHeaderFromBreakpoint='md'
          hideFirstLastBorder={true}
          onAttributeSelect={this.onAttributeSelect.bind(this)}
          dense={true}
          paginationMode='client'
          columns={[
            {
              type: SingleColumnTable.columns.task
            }
          ]}
          paper={false}
          rows={tasksHasAttribute ? tasksHasAttribute : []}
        />
      </StyledTrainingAttributesDialog>
    );
  }
}

const StyledTrainingAttributesDialog = styled(Dialog)`
  &.klayo__training-attribute-dialog {
    h1 {
      color: ${KLAYO_COLORS.GreyGR900};
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
      letter-spacing: -0.5px;
      margin-top: 0px;
      margin-bottom: 32px;
    }

    h2 {
      color: ${KLAYO_COLORS.GreyGR900};
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 0px;
      margin-bottom: 24px;
    }

    .main-title {
      font-weight: 700;
    }

    .MuiDialogContent-root {
      padding: 40px 40px 0px 40px;
    }

    .MuiPaper-root {
      max-width: 640px;
    }
  }
`;
