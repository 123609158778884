import { DataGridProProps, GridSortModel } from "@mui/x-data-grid-pro";
import { isEmpty } from "lodash";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { TeamFilterModel } from "../common/models/TeamFilterModel";
import { Utils } from "../common/Utils";
import { AbstractAttribute } from "../data/attributes/AbstractAttribute";
import { ITeamRegulationResponse } from "../interfaces/Task";
import { ITeamRegulationSummary } from "../interfaces/Team";
import { useTeamContext } from "../providers/TeamProvider";
import { appendIfUnique, mapEmployees } from "../utilities";
import { useAppQuery } from "./useAppQuery";

type Props = {
  filter: TeamFilterModel;
  groupId?: string;
  basePath: string;
  basePathDepth: number;
  sortOrder?: any;
  onSortOrderChange?: (e: any, sortOrder: any) => void;
};

const useTeamRegulationSection = ({
  groupId,
  filter,
  basePath,
  basePathDepth,
  sortOrder,
  onSortOrderChange
}: Props) => {
  const [search, setSearch] = useState<string>("");
  const tableRef = useRef<any>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "attribute", sort: sortOrder?.method || "asc" }
  ]);
  const regulationPeopleListDialogRef = useRef<any>();

  const history = useHistory();
  const location = useLocation();

  const { gapMode, setGapMode } = useTeamContext();

  const isGapMode = useMemo(() => gapMode.regulations, [gapMode.regulations]);

  const filterParams = useMemo(() => {
    if (isEmpty(filter)) return "";

    const params = new URLSearchParams();

    filter.people.forEach((e) => appendIfUnique(params, "EmployeeIds", e.employeeId));
    filter.peopleIds.forEach((id) => appendIfUnique(params, "EmployeeIds", id));
    filter.jobs.forEach((j) => appendIfUnique(params, "Jobs", j));
    filter.locations.forEach((l) => appendIfUnique(params, "Locations", l));
    filter.attributes.forEach((a) =>
      appendIfUnique(params, "AttributeDefinitionIds", a.attributeDefinitionId)
    );
    filter.attributeIds.forEach((id) => appendIfUnique(params, "AttributeDefinitionIds", id));
    if (filter.compliance)
      appendIfUnique(
        params,
        "IsCompliant",
        (filter.compliance === AbstractAttribute.compliance.compliant) as any
      );
    if (filter.attributeType) appendIfUnique(params, "AttributeType", filter.attributeType.index);
    if (filter.expireAfter)
      appendIfUnique(params, "ExpiryDateFrom", Utils.toApiDate(filter.expireAfter) as any);
    if (filter.expireBefore)
      appendIfUnique(params, "ExpiryDateTo", Utils.toApiDate(filter.expireBefore) as any);

    return params.toString();
  }, [filter]);

  const { data, isFetching } = useAppQuery<ITeamRegulationResponse>(
    `/${groupId ? `MyGroup/${groupId}` : "Teams"}/regulations?${filterParams}`
  );

  const regulations: ITeamRegulationSummary[] = useMemo(() => {
    if (!data) return [] as ITeamRegulationSummary[];

    return mapEmployees(data.teamRegulationSummary) as ITeamRegulationSummary[];
  }, [data]);

  const classificationsSummary = useMemo(() => {
    if (!regulations?.length) return {};

    return regulations.reduce(
      (
        summary: { [key: string]: Partial<ITeamRegulationSummary> },
        regulation: ITeamRegulationSummary
      ) => {
        if (
          (isGapMode && !regulation.gapEmployees.length) ||
          (!isGapMode && !regulation.metEmployees.length)
        )
          return summary;

        summary[regulation.name] = {
          name: regulation.name,
          employees: !isGapMode ? regulation.metEmployees : regulation.gapEmployees,
          gapEmployees: regulation.gapEmployees,
          id: regulation.id,
          type: "regulation"
        };
        return summary;
      },
      {}
    );
  }, [regulations, isGapMode]);

  const generateRegulationTree = (regulations: ITeamRegulationSummary[]) => {
    const result: any[] = [];

    function generateTree(
      item: any,
      hierarchy: string[],
      type: "regulation" | "task" | "competency"
    ): void {
      if (!item) return;

      const baseData: any = {
        metEmployees: item.metEmployees || [],
        gapEmployees: item.gapEmployees || [],
        employees: item.employees || [],
        regulationHierarchy: [...hierarchy],
        type
      };

      if (type === "competency") {
        baseData.attributeDefinitionId = item.attributeDefinitionId;
        baseData.attributeName = item.attributeName;
        baseData.attributeCategoryNames = item.attributeCategoryNames;
        baseData.averageProficiency = item.averageProficiency;
        baseData.name = item.attributeName;
        baseData.id = item.attributeDefinitionId;
      } else {
        baseData.name = item.name;
        baseData.id = item.id;
      }
      baseData.rowId = baseData.regulationHierarchy.join("_");

      result.push(baseData);

      // If the item has regulationTasks, transform each task
      if (item.regulationTasks) {
        item.regulationTasks.forEach((task: any) =>
          generateTree(task, [...hierarchy, task.id], "task")
        );
      }

      // If the item has competencies, transform each competency
      if (item.competencies) {
        item.competencies.forEach((competency: any) =>
          generateTree(competency, [...hierarchy, competency.attributeDefinitionId], "competency")
        );
      }
    }

    // Start transforming each regulation with an initial empty hierarchy
    regulations.forEach((regulation: any) =>
      generateTree(regulation, [regulation.id], "regulation")
    );

    return result;
  };

  const filteredRegulations = useMemo(() => {
    const filteredRegulations = regulations.filter((regulation) =>
      isGapMode ? regulation.gapEmployees.length : true
    );

    if (!search) return filteredRegulations;

    const lowerCaseKeyword = search.toLowerCase();

    return filteredRegulations.filter((regulation) => {
      if (regulation.name.toLowerCase().includes(lowerCaseKeyword)) {
        return true;
      }

      return regulation.regulationTasks.some((task) => {
        if (task.name.toLowerCase().includes(lowerCaseKeyword)) {
          return true;
        }

        return task.competencies.some((competency) =>
          competency.attributeName.toLowerCase().includes(lowerCaseKeyword)
        );
      });
    });
  }, [regulations, search, isGapMode]);

  const regulationsTree = useMemo(() => {
    return generateRegulationTree(filteredRegulations);
  }, [filteredRegulations]);

  const onSwitchToGapMode = (e: ChangeEvent<HTMLInputElement>) => {
    setGapMode({
      ...gapMode,
      regulations: e.target.checked
    });
  };

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => row.regulationHierarchy;

  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    tableRef?.current?.setSortModel(model);
  };

  const handleSortOrderChange = (e: any, sortOrder: any) => {
    onSortModelChange([{ field: "attribute", sort: sortOrder.method }]);
    onSortOrderChange && onSortOrderChange(e, sortOrder);
  };

  const setLocation = (path: any, useNative?: any) => {
    history.push(path + "?" + filter.toUrlParams());

    if (useNative === true) window.history.pushState(null, "", path + "?" + filter.toUrlParams());
    else history.push(path + "?" + filter.toUrlParams());
  };

  const onCloseRegulationPeopleListDialog = () => {
    setLocation(basePath + "/" + "regulations", true);
    if (regulationPeopleListDialogRef.current) regulationPeopleListDialogRef.current.close();
  };

  const onPeopleSelect = (e: any, task: any) => {
    setLocation(basePath + "/regulations/" + task.id, true);
    if (regulationPeopleListDialogRef.current)
      regulationPeopleListDialogRef.current.open(task, false, task.type);
  };

  const onGapSelect = (e: any, task: any) => {
    setLocation(basePath + "/regulations/" + task.id + "?isGap=true", true);
    if (regulationPeopleListDialogRef.current)
      regulationPeopleListDialogRef.current.open(task, true, task.type);
  };

  useEffect(() => {
    if (isEmpty(regulationsTree)) return;

    const pathSplit = location.pathname.split("/");

    const task = regulationsTree.find((task) => task.id === pathSplit[basePathDepth + 2]);

    const isGap = location.search.includes("isGap=true");

    if (regulationPeopleListDialogRef.current && task)
      regulationPeopleListDialogRef.current.open(task, isGap, task.type);
  }, [regulationsTree, basePathDepth, location]);

  return {
    isLoading: isFetching,
    classificationsSummary,
    search,
    setSearch,
    regulationsTree,
    onSwitchToGapMode,
    isGapMode,
    getTreeDataPath,
    onSortModelChange,
    tableRef,
    sortModel,
    regulationPeopleListDialogRef,
    onCloseRegulationPeopleListDialog,
    onPeopleSelect,
    onGapSelect,
    handleSortOrderChange
  };
};

export default useTeamRegulationSection;
