import { AvatarGroup } from "@mui/material";
import React, { Component } from "react";
import { ProfilePicture } from "./ProfilePicture";

export class ProfilePictureGroup extends Component<any, any> {
  static defaultProps = {
    max: 5,
    size: "xs"
  };

  onUserClick(e: any, user: any) {
    if (this.props.onUserClick) this.props.onUserClick(e, user);
  }

  render() {
    const { max, size, users } = this.props;

    return (
      <AvatarGroup
        max={max}
        sx={{
          "& .MuiAvatar-root": {
            width: (ProfilePicture as any).size[size],
            height: (ProfilePicture as any).size[size],
            fontSize: (ProfilePicture as any).fontSize[size]
          }
        }}
      >
        {users.map((e: any, index: any) => (
          <ProfilePicture
            key={index}
            user={e}
            size={size}
            onClick={(event: any) => this.onUserClick(event, e)}
            sx={{ zIndex: index }}
          />
        ))}
      </AvatarGroup>
    );
  }
}
