import { Box, Stack } from "@mui/material";
import React, { Component } from "react";

export class DetailsGridRow extends Component<any, any> {
  static defaultProps = {
    direction: "row",
    spacing: 2,
    lineHeight: "18px",
    fontSize: "16px"
  };

  render() {
    const { label, children, direction, spacing, lineHeight, fontSize } = this.props;

    return (
      <Stack direction={direction} spacing={spacing} sx={{ fontSize }}>
        <Box sx={{ width: "168px" }}>{label}</Box>
        <Box sx={{ fontWeight: "600", lineHeight }}>{children}</Box>
      </Stack>
    );
  }
}
