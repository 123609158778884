import { Utils } from "../../common/Utils";
import { Abstract_Attribute, Gap_Attribute } from "../../constants";
import { IAbstractAttribute } from "../../interfaces/Attribute";
export class AbstractAttributeBase implements IAbstractAttribute {
  static compliance = Abstract_Attribute.compliance;

  static type = Abstract_Attribute.type;

  static gapReason = Abstract_Attribute.gapReason;

  static status = Abstract_Attribute.status;

  childAttributes: IAbstractAttribute[];
  expiryDate!: Date | null;
  currentExpiryDate!: Date | null;
  requestedExpiryDate!: Date | null;
  pendingExpiryDate!: Date | null;
  attributeId!: any;
  attributeDefinitionId!: any;
  name!: string;
  attributeName!: string;
  attribute!: string;
  description!: string;
  isParent!: boolean;
  hasExpiryDate!: boolean;
  hasProficiency!: boolean;
  id!: number | string;
  rowId!: number | string;
  attributeHierarchy!: string[];
  categories!: string[];
  required!: boolean;
  mandatory!: boolean;
  type!: string;
  hasRequiredDetails!: boolean;
  requestActivity!: any;
  employeeAttributeId!: string;
  status!: any;
  reason!: any;
  validationRequestState!: any;
  pendingProficiency!: any;

  constructor(id: any, data: any) {
    this.childAttributes = [];

    if (data) {
      //Is child? - diff names, no children
      this.expiryDate = Utils.parseApiDate(data.expiryDate);
      this.currentExpiryDate = Utils.parseApiDate(data.expiryDate);
      this.requestedExpiryDate = data.validationRequest
        ? Utils.parseApiDate(data.validationRequest.expiryDate)
        : null;
      this.pendingExpiryDate = data.pendingExpiryDate
        ? data.pendingExpiryDate
        : this.requestedExpiryDate || this.currentExpiryDate;

      if (data.attributeDefinitionChildId) {
        this.attributeId = data.attributeDefinitionChildId;
        this.attributeDefinitionId = data.attributeDefinitionId;
        this.name = data.attributeDefinitionName;
        this.attributeName = data.attributeDefinitionName || data.attributeName;
        this.attribute = data.attributeDefinitionName || data.attributeName;
        this.description = data.attributeDefinitionDescription;
        this.isParent = false;
        this.hasExpiryDate = data.attributeDefinitionHasExpiryDate;
        this.hasProficiency = data.attributeDefinitionHasProficiency;
      } else {
        this.id = data.attributeDefinitionId;
        this.rowId = data.attributeDefinitionId;
        this.attributeDefinitionId = data.attributeDefinitionId;
        this.attributeName = data.attributeDefinitionName || data.attributeName;
        this.attribute = data.attributeDefinitionName || data.attributeName;
        this.attributeHierarchy = [this.attributeDefinitionId];
        this.isParent = data.isParent;
        this.categories =
          data.attributeDefinitionAttributeCategories ||
          data.attributeDefinitionCategories ||
          data.attributeCategoryNames;
        this.hasExpiryDate = data.hasExpiryDate || data.attributeDefinitionHasExpiryDate;
        this.hasProficiency = data.hasProficiency || data.attributeDefinitionHasProficiency;

        const childAttrs =
          data.attributeDefinitionChildren ||
          data.jobAttributeChildren ||
          data.attributeDefinitionAttributeDefinitionChildren;
        if (childAttrs && childAttrs.length !== 0) {
          this.isParent = true;

          childAttrs.forEach((c: any, index: any) => {
            const childAttr: any = new AbstractAttributeBase(0, c);
            childAttr.rowId = this.attributeDefinitionId + childAttr.attributeDefinitionId;
            childAttr.isChild = true;
            childAttr.childIndex = index;
            childAttr.isLastChild = index === childAttrs.length - 1;
            childAttr.attributeHierarchy = [
              this.attributeDefinitionId,
              childAttr.attributeDefinitionId
            ];
            childAttr.parentAttributeId = this.attributeId;
            this.childAttributes.push(childAttr);
          });
        }
      }

      this.required = data.required;
      this.mandatory = data.mandatory;

      if (this.mandatory) this.type = AbstractAttributeBase.type.mandatory.label;
      else if (this.required) this.type = "Required";
      else this.type = "Desirable";

      this.hasRequiredDetails = AbstractAttributeBase.hasRequiredDetails(this);
    }
  }

  static hasRequiredDetails(attr: any) {
    if (attr.status === AbstractAttributeBase.status.feedback) return true;
    if (attr.hasExpiryDate === true && !attr.expiryDate) return false;
    if (attr.hasProficiency === true && !Number.isInteger(attr.proficiency)) return false;
    return true;
  }

  isUnderReview() {
    return this?.status?.index === AbstractAttributeBase.status.review.index;
  }

  isApproved() {
    return this?.status?.index === AbstractAttributeBase.status.approved.index;
  }

  isDeclined() {
    return this?.status?.index === AbstractAttributeBase.status.declined.index;
  }

  isNeedsFeedback() {
    return this?.status?.index === AbstractAttributeBase.status.feedback.index;
  }

  hasValidationRequest() {
    return this.validationRequestState;
  }

  isLowProficiency() {
    return this.reason === Gap_Attribute.reason.lowProficiency;
  }

  isMissing() {
    return this.reason === Gap_Attribute.reason.missing;
  }

  hasValidPendingExpiryDate() {
    return this.pendingExpiryDate !== undefined && this.pendingExpiryDate !== null;
  }

  hasValidPendingProficiency() {
    return (
      this.pendingProficiency !== undefined &&
      this.pendingProficiency !== null &&
      !isNaN(this.pendingProficiency) &&
      this.pendingProficiency !== 0
    );
  }
}
