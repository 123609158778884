import { AppContext } from "../../common/AppContext";
import { PersonSelectorList } from "../../data/filters/PersonSelectorList";
import { AbstractFilterSelect } from "./AbstractFilterSelect";
export class PeopleSelector extends AbstractFilterSelect {
  static contextType = AppContext;

  static defaultProps = {
    ...AbstractFilterSelect.defaultProps,
    label: "People",
    listType: "People"
  };

  constructor(props: any) {
    super(props);

    this.dataList = PersonSelectorList;
  }

  compareItems(a: any, b: any) {
    return a.employeeId === b.employeeId;
  }

  getItemLabel(item: any) {
    return item.fullName;
  }

  getList(dataList: any) {
    return dataList.employees;
  }
}
