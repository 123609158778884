import { ListItemText, MenuItem, Stack } from "@mui/material";
import { ConfirmationDialog, Delayed } from "klayowebshared";
import { isEqual, map } from "lodash";
import { AppContext } from "../../common/AppContext";
import axiosClient from "../../common/AxiosClient";
import { ErrorModel } from "../../common/models/ErrorModel";
import { AttributeActionMenu } from "../../components/AttributeActionMenu";
import { Button } from "../../components/Button";
import { Paper } from "../../components/Paper";
import { Snackbar } from "../../components/Snackbar";
import { TablePlaceholder } from "../../components/TablePlaceholder";
import { TextField } from "../../components/TextField";
import { ViewComponent } from "../../components/ViewComponent";
import { TasksTable } from "../../components/table/TasksTable";
import { TableSearchDebounceTime } from "../../constants";
import { CREATE_TASK_MODE, TASK_ATTRIBUTE_DIALOG_TYPE } from "../../constants/constants";
import { SettingsTasksList } from "../../data/settings/SettingsTasksList";
import { ReactComponent as AddIcon } from "../../resources/images/icons-add.svg";
import { ReactComponent as CheckListIcon } from "../../resources/images/icons-checklist.svg";
import { ReactComponent as SearchIcon } from "../../resources/images/icons-search.svg";
import { getSortByApiValue } from "../../utilities";
import { ConfirmEditTaskLinkedJobDialog } from "../settings/ConfirmEditTaskLinkedJobDialog";
import { CreateTaskDialog } from "../settings/CreateTaskDialog";
import { TaskLinkedEntitiesDialog } from "../settings/TaskLinkedEntitiesDialog";

export class SettingsTasksView extends ViewComponent {
  static contextType = AppContext;

  debounceTimer: any;
  selectedTask: any;
  setMenuRef: (menu: any) => void;
  setTableRef: (tableRef: any) => void;
  menu: any;
  tableRef: any;

  constructor(props: any) {
    super(props);

    this.state = {
      ...(ViewComponent as any).state,
      search: null,
      actionTarget: null,
      actionTask: null,
      pageSize: SettingsTasksList.defaultPageSize,
      taskSortModel: [
        {
          field: "name",
          sort: "asc"
        }
      ],
      tasksPaginationModel: {
        page: 0,
        pageSize: SettingsTasksList.defaultPageSize
      },
      isShowCreateTaskDialog: false,
      isShowDeleteTaskDialog: false,
      isShowCannotDeleteTaskDialog: false,
      taskOrderByConvertList: [
        { tableFieldName: "name", apiFieldName: "name" },
        { tableFieldName: "numberOfCompetencies", apiFieldName: "numberOfCompetencies" },
        { tableFieldName: "numberOfRegulations", apiFieldName: "numberOfRegulations" },
        { tableFieldName: "numberOfJobDefinitions", apiFieldName: "numberOfJobDefinitions" }
      ],
      taskError: null,
      isShowTaskAttributesDialog: false,
      createTaskMode: CREATE_TASK_MODE.CREATE, // "create" or "edit"
      taskAttributesDialogType: null,
      messageSnackbar: null,
      isShowConfirmEditTaskLinkedJobDialog: false,
      newCompetencyListInTask: [],
      newTaskName: null,
      sortModel: null
    };

    this.debounceTimer = null;
    this.selectedTask = null;
    this.setMenuRef = (menu) => {
      this.menu = menu;
    };

    this.setTableRef = (tableRef: any) => {
      this.tableRef = tableRef;
    };
  }

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    const { onLoadTasks, onLoadAttributes, attributes } = this.props;
    const {
      tasksPaginationModel: { page, pageSize },
      taskSortModel,
      taskOrderByConvertList
    } = this.state;
    const [{ field, sort }] = taskSortModel;
    const orderBy = getSortByApiValue(taskOrderByConvertList, field, sort);
    onLoadTasks(null, page + 1, pageSize, orderBy);
    if (!attributes) {
      onLoadAttributes();
    }
  }

  componentWillUnmount() {
    this.setState({
      sortModel: null
    });
  }

  onShowCreateTaskDialog() {
    this.setState({
      isShowCreateTaskDialog: true,
      taskError: null,
      createTaskMode: CREATE_TASK_MODE.CREATE
    });
  }

  onEditTaskDialog() {
    const { actionTask } = this.state;
    this.selectedTask = actionTask;
    this.setState({
      isShowCreateTaskDialog: true,
      taskError: null,
      createTaskMode: CREATE_TASK_MODE.EDIT
    });
  }

  onCloseCreateTaskDialog() {
    this.setState({ isShowCreateTaskDialog: false });
  }

  onPositionAction(e: any, position: any) {
    if (this.menu) this.menu.open(e.target);
    this.setState({ actionTarget: e.target, actionTask: position });
  }

  onTaskAction(e: any, task: any) {
    if (this.menu) this.menu.open(e.target);
    this.setState({ actionTarget: e.target, actionTask: task });
  }

  onCloseActionMenu() {
    this.setState({ actionTarget: null, actionTask: null });
    if (this.menu) this.menu.close();
  }

  onSearchTask(e: any) {
    const { onLoadTasks } = this.props;
    const {
      tasksPaginationModel: { page, pageSize },
      taskSortModel,
      taskOrderByConvertList
    } = this.state;
    const [{ field, sort }] = taskSortModel;
    const orderBy = getSortByApiValue(taskOrderByConvertList, field, sort);
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      onLoadTasks(e.target.value, 1, pageSize, orderBy);
    }, TableSearchDebounceTime);
    const newModel = { page: 0, pageSize };
    this.setState({ search: e.target.value, tasksPaginationModel: newModel });
  }

  handleTaskPromise = (taskPromise: any) => {
    const { onLoadTasks } = this.props;
    const { createTaskMode, tasksPaginationModel, taskSortModel, taskOrderByConvertList } =
      this.state;
    return taskPromise
      .then(async () => {
        const { page, pageSize } = tasksPaginationModel;
        const [{ field, sort }] = taskSortModel;
        const orderBy = getSortByApiValue(taskOrderByConvertList, field, sort);
        await onLoadTasks(null, page + 1, pageSize, orderBy);
        this.onCloseCreateTaskDialog();
        this.setState({
          messageSnackbar:
            createTaskMode === CREATE_TASK_MODE.EDIT
              ? "Task updated successfully"
              : "Task created successfully"
        });
      })
      .catch((error: any) => {
        this.setState({ taskError: ErrorModel.parseServerError(error) });
      })
      .finally(() => {});
  };

  onSaveTask(taskName: any, allSelectedAttributes: any) {
    const { onCreateTask, onLoadTasks, onEditTask } = this.props;
    const { createTaskMode, tasksPaginationModel, taskSortModel, taskOrderByConvertList } =
      this.state;

    if (createTaskMode === CREATE_TASK_MODE.EDIT) {
      // compare allSelectedAttributes with this.selectedTask.competencies
      const isUpdateAllSelectedAttributes =
        allSelectedAttributes.length !== (this.selectedTask?.competencies?.length || 0) ||
        !isEqual(
          map(allSelectedAttributes, "attributeDefinitionId"),
          map(this.selectedTask.competencies, "attributeDefinitionId")
        );
      if (this.selectedTask.numberOfJobDefinitions > 0 && isUpdateAllSelectedAttributes) {
        this.setState({
          isShowConfirmEditTaskLinkedJobDialog: true,
          newCompetencyListInTask: allSelectedAttributes,
          newTaskName: taskName
        });
      } else {
        return this.handleTaskPromise(
          onEditTask(this.selectedTask.id, taskName, allSelectedAttributes)
        );
      }
    } else {
      return this.handleTaskPromise(onCreateTask(taskName, allSelectedAttributes));
    }
  }

  onTasksPaginationModelChange(model: any) {
    const { page, pageSize } = model;
    this.setState({ tasksPaginationModel: model });
    const { onLoadTasks } = this.props;
    const { taskSortModel, taskOrderByConvertList, search } = this.state;
    const [{ field, sort }] = taskSortModel;
    const orderBy = getSortByApiValue(taskOrderByConvertList, field, sort);
    onLoadTasks(search, page + 1, pageSize, orderBy);
  }

  renderActionMenu() {
    return (
      <div>
        <MenuItem onClick={this.onEditTaskDialog.bind(this)}>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={this.onOpenDeleteTaskDialog.bind(this)}>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </div>
    );
  }

  onTasksSortModelChange(sortModel: any) {
    if (!sortModel || sortModel.length === 0) return;
    const { onLoadTasks } = this.props;
    const {
      tasksPaginationModel: { page, pageSize },
      taskOrderByConvertList,
      search
    } = this.state;
    const [{ field, sort }] = sortModel;

    this.setState({ sortModel });

    const orderBy = getSortByApiValue(taskOrderByConvertList, field, sort);
    onLoadTasks(search, 1, pageSize, orderBy);
    const newModel = { page: 0, pageSize };
    this.setState({ taskSortModel: sortModel, tasksPaginationModel: newModel });
  }

  onCancelDeleteTask() {
    this.setState({ isShowDeleteTaskDialog: false });
  }

  onOpenDeleteTaskDialog() {
    const { actionTask } = this.state;
    this.selectedTask = actionTask;
    if (actionTask.numberOfJobDefinitions > 0) {
      this.setState({ isShowCannotDeleteTaskDialog: true });
    } else {
      this.setState({ isShowDeleteTaskDialog: true });
    }
  }

  onCloseCannotDeleteTaskDialog() {
    this.setState({ isShowCannotDeleteTaskDialog: false });
  }

  onDeleteTaskConfirmed() {
    this.context.setLoading("deleteTask", true);
    axiosClient
      .delete("/Regulation/Task", {
        data: {
          regulationTaskId: this.selectedTask.id
        }
      })
      .then((response: any) => {
        this.setState({
          isShowDeleteTaskDialog: false,
          messageSnackbar: "Task deleted successfully"
        });
        this.selectedTask = null;

        const { isHaveOneItemInLastPageInTasks } = this.props;

        const {
          tasksPaginationModel: { page, pageSize },
          taskOrderByConvertList,
          search,
          taskSortModel: [{ field, sort }]
        } = this.state;
        const orderBy = getSortByApiValue(taskOrderByConvertList, field, sort);

        if (isHaveOneItemInLastPageInTasks) {
          this.setState({ tasksPaginationModel: { page: 0, pageSize } });
          this.props.onLoadTasks(search, 1, pageSize, orderBy);
        } else {
          this.props.onLoadTasks(search, page + 1, pageSize, orderBy);
        }
      })
      .catch((e: any) => {
        this.setState({ deletingJobError: ErrorModel.parseServerError(e) });
      })
      .finally(() => {
        this.context.setLoading("deleteTask", false);
      });
  }

  onOpenTaskAttributesDialog(value: any, type: any) {
    this.setState({
      isShowTaskAttributesDialog: true,
      taskAttributesDialogType: type,
      actionTask: value
    });
  }

  onCloseTaskAttributesDialog() {
    this.setState({ isShowTaskAttributesDialog: false });
  }

  onTaskRowClick = (task: any) => {
    this.selectedTask = task.row;
    this.setState({
      isShowCreateTaskDialog: true,
      taskError: null,
      createTaskMode: CREATE_TASK_MODE.EDIT
    });
  };

  onCloseSnackbar() {
    this.setState({ messageSnackbar: null });
  }

  onCloseConfirmEditTaskLinkedJobDialog() {
    this.setState({ isShowConfirmEditTaskLinkedJobDialog: false });
  }

  onCloseAndSaveConfirmEditTaskLinkedJobDialog() {
    const { onEditTask } = this.props;
    const { newCompetencyListInTask, newTaskName } = this.state;
    this.setState({ isShowConfirmEditTaskLinkedJobDialog: false });
    return this.handleTaskPromise(
      onEditTask(this.selectedTask.id, newTaskName, newCompetencyListInTask)
    );
  }

  render() {
    const { theme, user, tasks, onShowNewAttributeDialog } = this.props;

    const {
      search,
      taskSortModel,
      tasksPaginationModel,
      isShowCreateTaskDialog,
      taskError,
      isShowDeleteTaskDialog,
      isShowCannotDeleteTaskDialog,
      isShowTaskAttributesDialog,
      actionTask,
      taskAttributesDialogType,
      createTaskMode,
      messageSnackbar,
      isShowConfirmEditTaskLinkedJobDialog
    } = this.state;

    const filterItems = [
      { id: "search", columnField: "name", operatorValue: "contains", value: search }
    ];

    return (
      <div>
        <Paper
          theme={theme}
          padding={{ xs: "46px 24px", md: "60px!important" }}
          borderFromBreakpoint='md'
        >
          <h1>Tasks</h1>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)", padding: "30px 0" }}
          >
            <TextField
              value={search}
              dense={true}
              placeholder='Search task'
              disabled={user === null}
              fullWidth={true}
              autoComplete={false}
              leadingIcon={<SearchIcon />}
              onChange={this.onSearchTask.bind(this)}
              sx={{ maxWidth: { md: "300px" } }}
            />
            <Button
              onClick={this.onShowCreateTaskDialog.bind(this)}
              size='md'
              theme={theme}
              variant='filled'
              showLabelFromBreakpoint='md'
              startIcon={<AddIcon />}
              label='New task'
            />
          </Stack>
          <Delayed>
            <TasksTable
              sortable={true}
              rowHasAction={true}
              minHeight='300px'
              theme={theme}
              filterMode='server'
              filterItems={filterItems}
              hideFirstLastBorder={true}
              dense={true}
              paginationMode='server'
              showDialog={{}}
              totalCount={tasks ? tasks.totalCount : 0}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              onRecurrentChange={() => {}}
              paginationModel={tasksPaginationModel}
              onPaginationModelChange={this.onTasksPaginationModelChange.bind(this)}
              onSortModelChange={this.onTasksSortModelChange.bind(this)}
              selectable={false}
              columns={TasksTable.defaultProps.columns}
              sortingMode='server'
              sortModel={taskSortModel}
              paper={false}
              rows={tasks ? tasks.tasks : null}
              onTaskAction={this.onTaskAction.bind(this)}
              onPositionAction={this.onPositionAction.bind(this)}
              onOpenTaskAttributesDialog={this.onOpenTaskAttributesDialog.bind(this)}
              noRowsComponent={
                <TablePlaceholder
                  text={search === null ? "Add your first New task to get started" : "No rows, yet"}
                  icon={<CheckListIcon />}
                />
              }
              onRowClick={this.onTaskRowClick.bind(this)}
              isUsingDeleteIcon={false}
            />
          </Delayed>

          <AttributeActionMenu
            ref={this.setMenuRef}
            onClose={this.onCloseActionMenu.bind(this)}
            onRender={this.renderActionMenu.bind(this)}
          />

          {isShowCreateTaskDialog && (
            <CreateTaskDialog
              user={user}
              employee={user}
              theme={theme}
              onClose={this.onCloseCreateTaskDialog.bind(this)}
              onSave={this.onSaveTask.bind(this)}
              error={taskError}
              mode={createTaskMode}
              actionTask={this.selectedTask}
              onShowNewAttributeDialog={onShowNewAttributeDialog}
            />
          )}

          {isShowDeleteTaskDialog && (
            <ConfirmationDialog
              theme={theme}
              title='Delete task'
              question={<div>Are you sure you want to delete this task?</div>}
              acceptButton='Delete task'
              acceptDanger={true}
              error={null}
              onCancel={this.onCancelDeleteTask.bind(this)}
              onAccept={this.onDeleteTaskConfirmed.bind(this)}
            />
          )}

          {isShowCannotDeleteTaskDialog && (
            <TaskLinkedEntitiesDialog
              type={TASK_ATTRIBUTE_DIALOG_TYPE.JOBS}
              onClose={this.onCloseCannotDeleteTaskDialog.bind(this)}
              theme={theme}
              actionTask={this.selectedTask}
              dialogHeader={`Delete task`}
              subHeader={`Please remove the task from its linked jobs first.`}
              {...this.props}
            />
          )}

          {isShowTaskAttributesDialog && (
            <TaskLinkedEntitiesDialog
              type={taskAttributesDialogType}
              onClose={this.onCloseTaskAttributesDialog.bind(this)}
              theme={theme}
              actionTask={actionTask}
              dialogHeader={
                taskAttributesDialogType === TASK_ATTRIBUTE_DIALOG_TYPE.COMPETENCIES
                  ? "Linked competencies"
                  : taskAttributesDialogType === TASK_ATTRIBUTE_DIALOG_TYPE.REGULATIONS
                    ? "Linked Regulations"
                    : "Linked Jobs"
              }
              subHeader={`This task has following linked ${taskAttributesDialogType === TASK_ATTRIBUTE_DIALOG_TYPE.COMPETENCIES ? "competencies" : taskAttributesDialogType === TASK_ATTRIBUTE_DIALOG_TYPE.REGULATIONS ? "regulations" : "jobs"}:`}
              {...this.props}
            />
          )}

          {isShowConfirmEditTaskLinkedJobDialog && (
            <ConfirmEditTaskLinkedJobDialog
              theme={theme}
              onClose={this.onCloseConfirmEditTaskLinkedJobDialog.bind(this)}
              onCloseAndSave={this.onCloseAndSaveConfirmEditTaskLinkedJobDialog.bind(this)}
              type={taskAttributesDialogType}
              actionTask={this.selectedTask}
              dialogHeader={"Confirm"}
              subHeader={`Editing this task will also impact these linked jobs, please be sure to review theme after saving.\n Competencies removed from this task will remain from the job. \n New competencies will be added as "Desired".`}
              {...this.props}
            />
          )}

          {messageSnackbar && (
            <Snackbar
              open={true}
              duration={5000}
              onClose={this.onCloseSnackbar.bind(this)}
              type={"success"}
              message={messageSnackbar}
            />
          )}
        </Paper>
      </div>
    );
  }
}
