import { Grid, Stack } from "@mui/material";
import { Component } from "react";
import { Button } from "../../components/Button";
import { Paper } from "../../components/Paper";
import Drawing from "../../resources/images/drawing-inactive.svg";
import { ReactComponent as SwitchToCompanyIcon } from "../../resources/images/icons-office.svg";

export class UnavailableView extends Component<any, any> {
  static defaultProps = {
    heading: "App is unavailable",
    message: "Please try again soon",
    actions: ["tryAgain"]
  };

  constructor(props: any) {
    super(props);
  }

  onSwitchToOrganizationClick(e: any) {
    const { onSwitchToOrganization } = this.props;
    if (onSwitchToOrganization) onSwitchToOrganization(e, false);
  }

  onTryAgainClick(e: any) {
    window.location.reload();
  }

  render() {
    const { heading, message, actions } = this.props;

    return (
      <Paper className='klayo-unavailableview' padding='44px 64px 48px 64px'>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <h1>{heading}</h1>
            <div className='klayo-unavailableview_message'>{message}</div>
            <Stack direction='row' className='klayo-unavailableview_actions' spacing={2}>
              {actions && actions.includes("switchOrg") && (
                <Button
                  label='Switch to your company'
                  startIcon={<SwitchToCompanyIcon />}
                  onClick={this.onSwitchToOrganizationClick.bind(this)}
                />
              )}

              {actions && actions.includes("tryAgain") && (
                <Button label='Try again' onClick={this.onTryAgainClick.bind(this)} />
              )}
            </Stack>
          </Grid>
          <Grid item xs={6} className='klayo-unavailableview_drawing'>
            <Drawing />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
