import { Data } from "../Data";

export class JobSelectorList extends Data {
  static instance: any = null;
  jobs: any;
  attributes: any;

  constructor(options: any) {
    super({
      api:
        (options.groupId ? "/MyGroup/" + options.groupId + "/GetJobs" : "/Teams/GetJobs") +
        "?searchText=" +
        options.search,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.jobs = data.jobs;
  }

  static get(context: any, search: any, groupId?: any) {
    return new Promise((resolve: any, reject) => {
      if (JobSelectorList.instance && search === "") resolve(JobSelectorList.instance, true);
      else
        JobSelectorList.instance = new JobSelectorList({
          resolve,
          reject,
          search,
          context,
          groupId
        });
    });
  }

  findMatch(attr: any) {
    return this.attributes.find((a: any) => a.name === attr);
  }
}
