import { Data } from "../Data";

export class SubmitOrgSignup extends Data {
  constructor(options: any) {
    super({
      api: "/Organization/FinishSignUp",
      postData: options.details,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  static post(context: any, details: any) {
    return new Promise(
      (resolve, reject) =>
        new SubmitOrgSignup({ resolve, reject, context, details, method: "post" })
    );
  }
}
