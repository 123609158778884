import { get } from "lodash";
import { Data } from "../Data";
import { SettingsTask } from "./SettingsTask";

export class SettingsTasksList extends Data {
  static instance: any = null;
  static defaultPageSize = 50;

  tasks: any;
  currentPage: any;
  totalPages: any;
  totalCount: any;
  hasNext: any;
  hasPrevious: any;

  constructor(options: any) {
    const { searchText, pageNumber, pageSize, orderBy, ...otherOptions } = options;
    const apiOptions = {
      api: `/Regulation/Task/List`,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    // add params for api if provided
    const queryParams = [];
    if (searchText) queryParams.push(`searchText=${searchText}`);
    if (pageNumber) queryParams.push(`pageNumber=${pageNumber}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize || SettingsTasksList.defaultPageSize}`);
    if (orderBy) queryParams.push(`orderBy=${orderBy}`);

    if (queryParams.length > 0) {
      apiOptions.api += `?${queryParams.join("&")}`;
    }

    super(apiOptions);

    this.tasks = [];
    this.currentPage = 1;
    this.totalPages = null;
    this.totalCount = null;
    this.hasNext = false;
    this.hasPrevious = false;
  }

  onDataReceived(data: any) {
    // reformat data from api then assign to state
    const { currentPage, totalPages, totalCount, hasNext, hasPrevious } = get(
      data,
      "regulationTasks",
      {}
    );
    this.currentPage = currentPage;
    this.totalPages = totalPages;
    this.totalCount = totalCount;
    this.hasNext = hasNext;
    this.hasPrevious = hasPrevious;
    get(data, "regulationTasks.data", []).forEach((c: any, index: any) => {
      const task = new SettingsTask(c);
      this.tasks.push(task);
    });
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve, reject) => {
      if (SettingsTasksList.instance && updateCache !== true)
        (resolve as any)(SettingsTasksList.instance, true);
      else SettingsTasksList.instance = new SettingsTasksList({ resolve, reject, context });
    });
  }

  static getWithPaging(context: any, options: any) {
    const { searchText, pageNumber, pageSize, orderBy } = options;

    return new Promise((resolve, reject) => {
      SettingsTasksList.instance = new SettingsTasksList({
        searchText,
        pageNumber,
        pageSize,
        orderBy,
        resolve,
        reject,
        context
      });
    });
  }
}
