import { Stack } from "@mui/material";
import { AbstractDialog } from "../../components/AbstractDialog";
import { Alert } from "../../components/Alert";
import { Dialog } from "../../components/dialogs/Dialog";
import { EmployeeTable } from "../../components/table/EmployeeTable";
import { unFocusTableRowOnClose } from "../../utilities";
import React from "react";

export class AttributePeopleListDialog extends AbstractDialog {
  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state,
      attribute: this.props.attribute,
      isGap: false
    };
  }

  componentDidMount() {
    this.setLoading("attribute", !this.props.attribute);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.attribute !== prevProps.attribute) this.setLoading("attribute", false);
  }

  open(attribute: any, isGap?: boolean) {
    this.setState({ open: true, attribute, isGap });
  }

  onClose() {
    const { onClose } = this.props;

    if (onClose) onClose();
    unFocusTableRowOnClose();
  }

  getTableRows() {
    const { attribute, isGap } = this.state;
    return (isGap ? attribute?.gapEmployees : attribute?.employees) || [];
  }

  render() {
    const { onEmployeeSelect, theme } = this.props;
    const { open, attribute, isGap } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.onClose.bind(this)}
        theme={theme}
        fullWidth={true}
        title={isGap ? "Gap details" : "People"}
        maxWidth='sm'
        actions={[
          {
            label: "Close",
            variant: "filled",
            onClick: this.onClose.bind(this)
          }
        ]}
      >
        {super.renderLoadingBar()}
        <Stack direction='column' spacing={4}>
          <div style={{ fontSize: "16px" }}>
            <Alert showIcon={false} severity='info'>
              <b>{attribute?.attributeName}</b>
            </Alert>
            {!isGap ? (
              <p>
                The following employees
                {!attribute?.isBubble && <span> should</span>} have the competency:
              </p>
            ) : (
              <p>The following employees are missing the competency:</p>
            )}
          </div>
          <EmployeeTable
            rows={this.getTableRows.bind(this)()}
            pageSize={50}
            paper={false}
            theme={theme}
            columns={[
              { type: EmployeeTable.columns.profilePicFullName, showRole: false },
              ...(!isGap
                ? [
                    {
                      type: EmployeeTable.columns.isCompliant,
                      visibleIcon: true,
                      align: "center",
                      headerAlign: "center",
                      visibleHeader: false,
                      sortable: false,
                      width: 100
                    }
                  ]
                : [])
            ]}
            pagination={true}
            rowsPerPageOptions={[50]}
            disableMultipleSelection={true}
            disableSelectionOnClick={true}
            onEmployeeSelect={onEmployeeSelect}
            className='klaro_attrppl_table'
            sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
            border='none'
          />
        </Stack>
      </Dialog>
    );
  }
}
