import { Utils } from "klayowebshared";
import { size } from "lodash";
import { AbstractAttribute } from "../attributes/AbstractAttribute";
import { AttributeRequestActivity } from "../attributes/AttributeRequestActivity";
import { DocumentList } from "../documents/DocumentList";
import { SettingsCourse } from "./SettingCourse";
import { SettingsAttributeCategory } from "./SettingsAttributeCategory";

export class SettingsAttribute {
  categories: any;
  trainingCourses: any;
  trainingCourseIds: any;
  childAttributes: any;
  expiryNotifications: any;
  attributeId: any;
  attributeDefinitionId: any;
  name: any;
  description: any;
  isParent: boolean;
  defaultProficiencyMinimum: any;
  requirementType: any;
  proficiencyMinimum: any;
  proficiencyValue: any;
  comment: any;
  currentProficiency: any;
  currentExpiryDate: any;
  proficiency: any;
  expiryDate: any;
  validationRequest: any;
  requestedProficiency: any;
  requestedExpiryDate: any;
  pendingExpiryDate: any;
  pendingProficiency: any;
  rowId: any;
  isChild: any;
  childIndex: any;
  isLastChild: any;
  attributeHierarchy: any;
  parentAttributeId: any;
  type: any;
  id: any;
  hasDocuments: any;
  importCode: any;
  validationRequestState: any;
  originalValidationRequestState: any;
  requestActivity: any;
  documents: any;
  files: any;
  isNew: any;
  needsFeedbackComment: any;
  needsFeedback: any;
  numberOfEmployeeAttributes: any;
  numberOfJobAttributes: any;
  isInUsed: any;
  employeeAttributeId: any;
  hasTrainingCourses: any;
  hasExpiryDate: any;
  hasProficiency: any;

  static notificationPeriods = {
    none: {
      index: 0,
      label: "None"
    },
    onExpiry: {
      //OnExpiry
      index: 6,
      label: "On expiry"
    },
    week: {
      //OneWeekBefore
      index: 1,
      label: "1 week before"
    },
    weeks2: {
      //TwoWeekBefore
      index: 2,
      label: "2 weeks before"
    },
    weeks3: {
      //ThreeWeekBefore
      index: 3,
      label: "3 weeks before"
    },
    month: {
      //OneMonthBefore
      index: 4,
      label: "1 month before"
    },
    months2: {
      //TwoMonthBefore
      index: 5,
      label: "2 months before"
    },
    months3: {
      //ThreeMonthBefore
      index: 7,
      label: "3 months before"
    }
  };

  constructor(data?: any) {
    this.categories = [];
    this.trainingCourses = [];
    this.trainingCourseIds = [];
    this.childAttributes = [];
    this.expiryNotifications = [];

    if (data) {
      //Is child? - diff names, no children
      if (data.attributeDefinitionChildId) {
        this.attributeId = data.attributeDefinitionId;
        this.attributeDefinitionId = data.attributeDefinitionId;
        this.name = data.attributeDefinitionName;
        this.description = data.attributeDefinitionDescription;
        this.isParent = false;
      } else {
        this.attributeId = data.attributeDefinitionId;
        this.attributeDefinitionId = data.attributeDefinitionId;
        this.name = data.name || data.attributeDefinitionName;
        this.description = data.description;
        this.isParent = data.isParent;
        this.numberOfEmployeeAttributes = data.numberOfEmployeeAttributes
          ? data.numberOfEmployeeAttributes
          : 0;
        this.numberOfJobAttributes = data.numberOfJobAttributes ? data.numberOfJobAttributes : 0;
        this.isInUsed =
          data.numberOfEmployeeAttributes !== 0 || data.numberOfEmployeeAttributes !== 0;
        this.employeeAttributeId = data.employeeAttributeId;
        this.hasTrainingCourses = data.hasTrainingCourses;

        if (data.attributeCategories)
          data.attributeCategories.forEach((cat: any, index: any) =>
            this.categories.push(new SettingsAttributeCategory(cat))
          );

        if (data.attributeNotifications)
          data.attributeNotifications.forEach((e: any, index: any) => {
            this.expiryNotifications.push(
              Object.values(SettingsAttribute.notificationPeriods).find(
                (n) => n.index === e.attributeNotificationType
              )
            );
          });

        if (data.requirementType !== null) {
          if (typeof data.requirementType === "object") this.requirementType = data.requirementType;
          else
            this.requirementType = Object.values(AbstractAttribute.type).find(
              (t: any) => t.index === data.requirementType
            );
        }

        if (data.trainingCourses)
          data.trainingCourses.forEach((course: any, index: any) =>
            this.trainingCourses.push(new SettingsCourse(course))
          );
        if (data.trainingCourses)
          data.trainingCourses.forEach((course: any, index: any) =>
            this.trainingCourseIds.push(course.id)
          );

        this.proficiencyMinimum = data.proficiencyMinimum || this.defaultProficiencyMinimum;
        this.proficiencyValue = !isNaN(data.proficiencyValue)
          ? data.proficiencyValue
          : data.proficiency;
        this.comment = data.comment;

        this.currentProficiency = data.proficiencyValue;
        this.currentExpiryDate = Utils.parseApiDate(data.expiryDate);

        this.proficiency = this.proficiencyValue ? this.proficiencyValue : this.proficiency;
        this.expiryDate = this.currentExpiryDate;
        this.validationRequest = data.validationRequest || {};

        this.requestedProficiency = data.validationRequest
          ? data.validationRequest.proficiencyValue
          : data.requestedProficiency;
        this.requestedExpiryDate = data.validationRequest
          ? Utils.parseApiDate(data.validationRequest.expiryDate)
          : data.requestedExpiryDate;

        this.pendingExpiryDate = data.pendingExpiryDate
          ? data.pendingExpiryDate
          : this.requestedExpiryDate || this.currentExpiryDate;
        this.pendingProficiency = data.pendingProficiency
          ? data.pendingProficiency
          : this.requestedProficiency || this.currentProficiency;

        const childAttrs =
          data.attributeDefinitionChildren ||
          data.jobAttributeChildren ||
          data.attributeDefinitionAttributeDefinitionChildren;
        if (childAttrs && childAttrs.length !== 0) {
          this.isParent = true;
          this.requirementType = null;

          childAttrs.forEach((c: any, index: any) => {
            const childAttr = new SettingsAttribute(c);
            childAttr.rowId = this.attributeDefinitionId + childAttr.attributeDefinitionId;
            childAttr.isChild = true;
            childAttr.childIndex = index;
            childAttr.isLastChild = index === childAttrs.length - 1;
            childAttr.attributeHierarchy = [
              this.attributeDefinitionId,
              childAttr.attributeDefinitionId
            ];
            childAttr.parentAttributeId = this.attributeId;
            this.childAttributes.push(childAttr);
          });
        }

        if (data.mandatory) this.type = AbstractAttribute.type.mandatory.label;
        else if (data.required) this.type = "Required";
        else this.type = "Desirable";
      }

      this.id = this.attributeId;
      this.rowId = this.attributeId;
      this.attributeHierarchy = [this.attributeDefinitionId];
      this.hasDocuments = data.hasDocuments;
      this.importCode = data.importCode ?? data.code;
      this.hasExpiryDate = data.hasExpiryDate ?? data.attributeDefinitionHasExpiryDate ?? false;
      this.hasProficiency = data.hasProficiency ?? data.attributeDefinitionHasProficiency ?? false;

      if (data.validationRequestState !== undefined)
        this.validationRequestState = isNaN(data.validationRequestState)
          ? data.validationRequestState
          : Object.values(AbstractAttribute.status)[data.validationRequestState];

      this.originalValidationRequestState =
        data.originalValidationRequestState || this.validationRequestState;

      if (data.requestActivity) this.requestActivity = data.requestActivity;
      if (data.documents) this.documents = new DocumentList(data.documents.list);
      if (data.files) {
        this.files = [];
        Object.values(data.files)
          .filter((f) => f !== null)
          .forEach((f, index) => (this.files["file_" + index] = f));
        //data.files;
      }

      this.isNew = data.isNew;

      /*(Attribute Needs Proficiency AND Proficiency not set)
                OR (Attribute Need Expiry AND Expiry not set)
                OR (Attribute has a new comment)*/
      this.updateNeedsFeedback();
    } else {
      this.numberOfEmployeeAttributes = 0;
      this.numberOfJobAttributes = 0;
      this.isInUsed = false;
      this.isParent = false;
      this.hasExpiryDate = false;
      this.hasProficiency = false;
    }
  }

  updateNeedsFeedback() {
    const feedbackState =
      this.validationRequestState &&
      this.validationRequestState === AbstractAttribute.status.feedback;
    if (feedbackState) this.needsFeedbackComment = "";

    this.needsFeedback =
      (this.hasProficiency === true && !this.proficiencyValue) ||
      this.hasExpiryDate === true ||
      (this.comment && this.comment !== "") ||
      feedbackState === true;

    if (this.needsFeedback) {
      if (this.hasProficiency === true && this.hasExpiryDate === true)
        this.needsFeedbackComment =
          "This attribute needs Expiry Date and Proficiency set. Please update and submit.";
      else if (this.hasProficiency === true)
        this.needsFeedbackComment =
          "This attribute needs Proficiency set. Please update and submit.";
      else if (this.hasExpiryDate === true)
        this.needsFeedbackComment =
          "This attribute needs Expiry Date set. Please update and submit.";
    }
  }

  hasValidationRequest() {
    return this.validationRequestState !== undefined;
  }

  hasValidExpiryDate() {
    return (
      this.expiryDate !== undefined && this.expiryDate !== null && this.expiryDate >= new Date()
    );
  }

  hasValidProficiency() {
    return (
      this.proficiency !== undefined &&
      this.proficiency !== null &&
      !isNaN(this.proficiency) &&
      this.proficiency !== 0
    );
  }

  hasValidPendingExpiryDate() {
    return this.pendingExpiryDate !== undefined && this.pendingExpiryDate !== null;
    //return this.pendingExpiryDate !== undefined && this.pendingExpiryDate !== null && this.pendingExpiryDate >= new Date();
  }

  hasValidPendingProficiency() {
    return (
      this.pendingProficiency !== undefined &&
      this.pendingProficiency !== null &&
      !isNaN(this.pendingProficiency) &&
      this.pendingProficiency !== 0
    );
  }

  toStandardAttribute() {
    const stdAttr = new SettingsAttribute(this);
    stdAttr.attributeHierarchy = [stdAttr.attributeDefinitionId];
    stdAttr.rowId = stdAttr.attributeDefinitionId;
    return stdAttr;
  }

  loadRequestActivity(context: any, updateCache: any) {
    return new Promise((resolve, reject) => {
      if (!this.requestActivity || updateCache === true) {
        this.requestActivity = new AttributeRequestActivity({
          attributeId: this.employeeAttributeId,
          resolve: (requestActivity: any) => {
            this.documents = requestActivity.documents;
            resolve(requestActivity);
          },
          reject,
          context
        });
      } else resolve(this.requestActivity);
    });
  }

  compareCategories(other: any) {
    return Utils.compareList(
      this.categories,
      other.categories,
      (m1: any, m2: any) => m1.categoryId === m2.categoryId
    );
  }

  deleteDocument(doc: any) {
    if (!this.documents) return;

    this.documents.delete(doc);
    this.hasDocuments = this.documents.list.some((d: any) => !d.isDeleted && !d.pendingDelete);
  }

  isSame(other: any) {
    if (!other) return false;

    if (this.validationRequestState && other.validationRequestState) {
      if (this.validationRequestState.index !== other.validationRequestState.index) {
        return false;
      }
    }

    if (this.hasProficiency && other.hasProficiency) {
      if (this.pendingProficiency !== other.pendingProficiency) {
        return false;
      }
    }

    if (this.hasExpiryDate && other.hasExpiryDate) {
      if (
        this.pendingExpiryDate &&
        other.pendingExpiryDate &&
        Utils.formatReadableDate(this.pendingExpiryDate) !==
          Utils.formatReadableDate(other.pendingExpiryDate)
      ) {
        return false;
      }
    }

    if (
      !(!this.comment && other.comment === "") &&
      !(this.comment === "" && !other.comment) &&
      this.comment !== other.comment
    ) {
      return false;
    }

    //Only compare if feed is loaded
    if (this.requestActivity && other.requestActivity) {
      if (JSON.stringify(this.documents) !== JSON.stringify(other.documents)) {
        return false;
      }
    }

    if (this.files || other.files) {
      const thisFilesLength = size(this.files);
      const otherFilesLength = size(other.files);

      if (thisFilesLength !== otherFilesLength) {
        return false;
      }
    }

    return true;
  }

  toApiDto() {
    const validExpiryNotifs =
      this.expiryNotifications && this.expiryNotifications.length !== 0
        ? Object.values(this.expiryNotifications)
        : null;
    const isParent = this.childAttributes && this.childAttributes.length !== 0;

    return {
      attributeDefinitionId: this.attributeId,
      name: this.name,
      description: this.description,
      attributeCategoryIds:
        this.categories && this.categories.length !== 0
          ? Object.values(this.categories).map((cat: any) => cat.categoryId)
          : [],
      attributeDefinitionChildren:
        this.childAttributes && this.childAttributes.length !== 0
          ? Object.values(this.childAttributes).map((attr: any) => attr.attributeId)
          : [],
      hasExpiryDate: !isParent && this.hasExpiryDate,
      hasProficiency: !isParent && this.hasProficiency,
      trainingCourseIds:
        this.trainingCourses && this.trainingCourses.length !== 0
          ? this.trainingCourses.map((course: any) => course.id)
          : [],
      code: this.importCode,
      attributeNotificationTypes:
        !isParent && this.hasExpiryDate
          ? validExpiryNotifs !== null && validExpiryNotifs.length !== 0
            ? validExpiryNotifs.map((e: any) => e.index)
            : [SettingsAttribute.notificationPeriods.none.index]
          : null
    };
  }
}
