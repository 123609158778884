import { Utils } from "../../common/Utils";
import { User } from "../users/User";
import { SettingsAttribute } from "./SettingsAttribute";
export class SettingsEmployee extends User {
  attributes: any;
  integrationUsers: any;
  integrationUserIds: any;
  statusLabel: any;
  role: any;
  manager: any;
  dateOfBirth: any;
  startDate: any;
  locationId: any;
  groupLevelType: any;
  groupNotificationType: any;
  job: any;
  reportsTo: any;
  employeeNumber: any;
  numberOfTeamMembers: any;
  gender: any;

  static status = {
    added: {
      //Added
      index: 0,
      label: "Added"
    },
    invited: {
      //Invited
      index: 1,
      label: "Invited"
    },
    active: {
      //Active
      index: 2,
      label: "Active"
    },
    inactive: {
      //Inactive
      index: 3,
      label: "Inactive"
    }
  };

  constructor(data?: any) {
    super({ data: data });

    this.attributes = [];
    this.integrationUsers = [];
    this.integrationUserIds = [];

    if (data) {
      this.status = Object.values(SettingsEmployee.status)[data.status] || null;
      this.statusLabel = this.status ? this.status.label : null;
      this.role = data.role;
      this.hasJob = data.hasJob;
      this.manager = data.manager;
      this.dateOfBirth = Utils.parseApiDate(data.dateOfBirth);
      this.startDate = Utils.parseApiDate(data.startDate);
      this.jobTitle = data.jobTitle ?? data.jobDefinitionName;
      this.locationId = data.locationId;
      this.groupLevelType = data.groupManagerLevelGroupManagerLevelTypeId;
      this.groupNotificationType = data.groupManagerNotificationGroupManagerNotificationTypeId;

      if (data.job) {
        this.job = {
          jobDefinitionId: data.job.jobDefinitionId,
          jobId: data.job.jobId,
          name: data.job.jobDefinitionName,
          manager: data.job.manager
        };

        if (this.job.manager) this.reportsTo = this.job.manager.jobId;
      }
      this.employeeNumber = data.employeeNumber;
      this.integrationUsers = data.integrationUsers;
      this.integrationUserIds = data.integrationUsers;
      this.isInstructor = data.isInstructor;

      if (data.attributes) this.attributes = data.attributes;
      else if (data.employeeAttributes) {
        data.employeeAttributes.forEach((a: any) => {
          const attr = new SettingsAttribute(a);
          if (!this.attributes.some((e: any) => e.rowId === a.rowId)) this.attributes.push(attr);
          this.attributes = this.attributes.concat(attr.childAttributes);
        });
      }
    } else {
      this.status = SettingsEmployee.status.added;
      this.statusLabel = this.status ? this.status.label : null;
      this.numberOfTeamMembers = 0;
      this.dateOfBirth = null;
      this.startDate = null;
    }

    this.initAttributesLists();
  }

  isActive() {
    return this.status === SettingsEmployee.status.active;
  }

  isInvited() {
    return this.status === SettingsEmployee.status.invited;
  }

  initAttributesLists() {
    //let childAttrs = [];
    //this.attributes.forEach(a => childAttrs = childAttrs.concat(a.childAttributes));
    //this.allAttributes = [...this.attributes, ...childAttrs];
    //this.flatAttributes = Array.from(new Set([...this.attributes.filter(a => !a.isParent), ...childAttrs]));
  }

  setManager(data: any) {
    const manager: any = new SettingsEmployee(data.employee);
    manager.numberOfTeamMembers = data.numberOfTeamMembers;
    manager.jobTitle = data.jobDefinitionName;
    manager.jobId = data.jobId;
    if (!data.employee) manager.fullName = "Vacancy";
    this.manager = manager;
  }

  setStatus(status: any) {
    this.status = status;
    this.statusLabel = this.status ? this.status.label : null;
  }

  getAttribute(id: any) {
    return this.attributes.find((a: any) => a.attributeId === id && !a.isChild);
  }

  hasManager() {
    return this.manager !== undefined && this.manager !== null;
  }

  updateAttribute(attribute: any) {
    const attributes = [...this.attributes];
    const index = attributes.indexOf(this.getAttribute(attribute.attributeId));
    if (index !== -1) attributes.splice(index, 1, attribute);
    this.attributes = [...attributes];
  }

  isAttributesSame(other: any, find: any) {
    if (!other) return false;
    const otherNoChildren = other.filter((a: any) => !a.isChild);
    if (other.length !== this.attributes.length) return false;

    for (const o of otherNoChildren) {
      const match = this.attributes.find((a: any) =>
        find ? find(a, o) : a.attributeId === o.attributeId && !a.isChild
      );
      if (!match || !match.isSame(o)) return false;
    }

    return true;
  }

  toApiFormData() {
    const formData = new FormData();
    formData.append("EmployeeId", this.employeeId);
    formData.append("FirstName", this.firstName);
    if (this.middleName) formData.append("MiddleName", this.middleName);
    if (this.preferredName) formData.append("PreferredName", this.preferredName);
    if (this.lastName) formData.append("LastName", this.lastName);
    if (this.dateOfBirth) formData.append("DateOfBirth", Utils.toApiDate(this.dateOfBirth) as any);
    if (this.employeeNumber) formData.append("EmployeeNumber", this.employeeNumber);
    if (this.gender) formData.append("Gender", this.gender);
    if (this.startDate) formData.append("StartDate", Utils.toApiDate(this.startDate) as any);
    formData.append("Email", this.email);
    if (this.locationId) formData.append("LocationId", this.locationId);
    formData.append("IsOrganizationAdmin", (this.isOrganizationAdmin as any) || false);
    formData.append("IsBilling", (this.isBilling as any) || false);
    if (this.job) {
      if (this.job.jobDefinitionId) formData.append("JobDefinitionId", this.job.jobDefinitionId);
      if (this.job.jobId) formData.append("JobId", this.job.jobId);
    }
    if (this.reportsTo) formData.append("ReportsToId", this.reportsTo);

    this.integrationUsers.forEach((user: any, index: any) => {
      formData.append(`IntegrationUserIds[${index}].UserName`, user.username);
      formData.append(
        `IntegrationUserIds[${index}].isForAllIntegrations`,
        user.isForAllIntegrations
      );
      // id from edit & server data
      if (user.organizationIntegrationId)
        formData.append(
          `IntegrationUserIds[${index}].IntegrationId`,
          user.organizationIntegrationId
        );
    });

    this.attributes
      .filter((a: any) => a.isChild !== true)
      .forEach((a: any, index: any) => {
        formData.append(
          "EmployeeAttributes[" + index + "][AttributeDefinitionId]",
          a.attributeDefinitionId
        );
        if (a.comment) formData.append("EmployeeAttributes[" + index + "][CommentText]", a.comment);
        if (a.validationRequestState)
          formData.append(
            "EmployeeAttributes[" + index + "][State]",
            a.validationRequestState.index
          );
        if (a.pendingProficiency)
          formData.append(
            "EmployeeAttributes[" + index + "][ProficiencyValue]",
            Number.isInteger(a.pendingProficiency) ? a.pendingProficiency : 0
          );
        if (a.pendingExpiryDate)
          formData.append(
            "EmployeeAttributes[" + index + "][ExpiryDate]",
            Utils.toApiDate(a.pendingExpiryDate) as any
          );

        if (a.files)
          Object.entries(a.files).map((f: any, i: any) => {
            if (f[1] !== null)
              formData.append("EmployeeAttributes[" + index + "][FormFiles]", f[1]);
          });

        if (a.documents) {
          a.documents.list
            .filter((d: any) => d.pendingDelete)
            .forEach((d: any, di: any) => {
              formData.append(
                "EmployeeAttributes[" + index + "][DeletedEvidences[" + di + "]]",
                d.documentId
              );
            });
        }
      });

    return formData;
  }

  toApiDto() {
    const attrs: any = [];
    this.attributes
      .filter((a: any) => a.isChild !== true)
      .forEach((a: any) => {
        attrs.push({
          attributeDefinitionId: a.attributeDefinitionId,
          proficiencyValue: a.proficiencyMinimum || 0,
          comment: a.comment,
          state: a.validationRequestState ? a.validationRequestState.index : null,
          expiryDate: null
        });

        /*a.childAttributes.forEach(child => {
                if (!attrs.some(a => a.attributeDefinitionId === child.attributeDefinitionId)) attrs.push({
                    attributeDefinitionId: child.attributeDefinitionId,
                    proficiencyValue: child.proficiencyMinimum,
                    comment: child.comment
                });
            });*/
      });

    return {
      employeeId: this.employeeId,
      firstName: this.firstName,
      middleName: this.middleName,
      preferredName: this.preferredName,
      lastName: this.lastName,
      dateOfBirth: Utils.toApiDate(this.dateOfBirth),
      employeeNumber: this.employeeNumber,
      gender: this.gender,
      startDate: Utils.toApiDate(this.startDate),
      email: this.email,
      locationId: this.locationId,
      isOrganizationAdmin: this.isOrganizationAdmin || false,
      isBilling: this.isBilling || false,
      jobDefinitionId: this.job ? this.job.jobDefinitionId : null,
      jobId: this.job ? this.job.jobId : null,
      reportsToId: this.reportsTo,
      employeeAttributes: attrs
    };
  }
}
