import React, { Component } from "react";
import { MultiSelector } from "./MultiSelector";

export class MultiCourseSelector extends Component<any, any> {
  static defaultProps = {
    label: "Courses"
  };

  constructor(props: any) {
    super(props);

    this.state = {};
  }

  render() {
    const { items } = this.props;

    return (
      <MultiSelector
        {...this.props}
        placeHolder='Search and add training'
        hasNewItem={false}
        hasAllItem={false}
      />
    );
  }
}
