import { Link, Stack } from "@mui/material";
import { Alert, Button, LoadingLogo, LoginLayout, Switch, TextField } from "klayowebshared";
import React, { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { ErrorModel } from "../../common/models/ErrorModel";
import { SubmitOrgSignup } from "../../data/signup/SubmitOrgSignup";

export class OrgSignupView extends Component<any, any> {
  static contextType = AppContext;

  static defaultProps = {
    termsUrl: "https://www.klayo.com/wp-content/uploads/Klayo-Terms-of-Use-220411.pdf"
  };

  constructor(props: any) {
    super(props);

    this.state = {
      submitting: props.user ? props.user.hasAcceptedTerms : false,
      firstName: "",
      lastName: "",
      companyName: "",
      jobTitle: "",
      termsAccepted: props.user ? props.user.hasAcceptedTerms : false,
      error: null
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.user !== this.props.user && this.props.user && this.props.user.hasAcceptedTerms) {
      this.setState({ termsAccepted: true, submitting: true });
      this.props.history.push("/");
    }
  }

  onFirstNameChange(e: any) {
    this.setState({ firstName: e.target.value });
  }

  onLastNameChange(e: any) {
    this.setState({ lastName: e.target.value });
  }

  onCompanyNameChange(e: any) {
    this.setState({ companyName: e.target.value });
  }

  onJobTitleChange(e: any) {
    this.setState({ jobTitle: e.target.value });
  }

  onAcceptTermsChange(e: any) {
    this.setState({ termsAccepted: e.target.checked });
  }

  onSubmit(e: any) {
    const { onComplete } = this.props;
    const { firstName, lastName, companyName, jobTitle, submitting } = this.state;

    if (!firstName) this.setState({ error: "Please enter your first name" });
    else if (!lastName) this.setState({ error: "Please enter your last name" });
    else if (!companyName) this.setState({ error: "Please enter your company name" });
    else if (!jobTitle) this.setState({ error: "Please enter your job title" });
    else {
      this.context.setLoading("submitting", true);
      this.setState({ submitting: true });

      SubmitOrgSignup.post(this.context, {
        firstName: firstName,
        lastName: lastName,
        organizationName: companyName,
        jobTitle: jobTitle
      })
        .then((n) => {
          onComplete(e);
        })
        .catch((e) => {
          const error = ErrorModel.parseServerError(e);
          this.setState({ error, submitting: false });
          this.context.setLoading("submitting", false);
        })
        .finally(() => {});
    }
  }

  render() {
    const { user, theme, termsUrl } = this.props;
    const { firstName, lastName, companyName, jobTitle, submitting, termsAccepted, error } =
      this.state;

    return (
      <LoginLayout
        heading={user && !submitting ? "Your account is ready!" : "Processing..."}
        text={
          user && !submitting
            ? "Tell us a bit about yourself so we can help you get the most out of Klayo."
            : "Please wait while we process your information."
        }
        theme={theme}
        loginMode={false}
        sx={{ position: "absolute", zIndex: 99999 }}
      >
        <Stack spacing={6}>
          {error && <Alert severity='info'>{error}</Alert>}

          {user && !submitting && (
            <>
              <TextField
                label='First name'
                value={firstName}
                dense={false}
                autoComplete='given-name'
                fullWidth={true}
                clearable={false}
                disabled={submitting}
                onChange={this.onFirstNameChange.bind(this)}
                onEnter={this.onSubmit.bind(this)}
              />

              <TextField
                label='Last name'
                value={lastName}
                dense={false}
                autoComplete='family-name'
                fullWidth={true}
                clearable={false}
                disabled={submitting}
                onChange={this.onLastNameChange.bind(this)}
                onEnter={this.onSubmit.bind(this)}
              />

              <TextField
                label='Company name'
                value={companyName}
                dense={false}
                autoComplete='organization'
                fullWidth={true}
                clearable={false}
                disabled={submitting}
                onChange={this.onCompanyNameChange.bind(this)}
                onEnter={this.onSubmit.bind(this)}
              />

              <TextField
                label='Job title'
                value={jobTitle}
                dense={false}
                autoComplete='organization-title'
                fullWidth={true}
                clearable={false}
                disabled={submitting}
                onChange={this.onJobTitleChange.bind(this)}
                onEnter={this.onSubmit.bind(this)}
              />

              <Switch
                label={
                  <div>
                    I agree with the{" "}
                    <Link href={termsUrl} target='_blank'>
                      Terms &amp; Conditions
                    </Link>
                  </div>
                }
                value={termsAccepted}
                onChange={this.onAcceptTermsChange.bind(this)}
              />

              <Button
                label='Start using Klayo'
                variant='filled'
                disabled={!termsAccepted || !firstName || !lastName || !companyName || !jobTitle}
                onClick={this.onSubmit.bind(this)}
              />

              {submitting && <LoadingLogo />}
            </>
          )}
        </Stack>
      </LoginLayout>
    );
  }
}
