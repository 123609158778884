import { AppBar, Box, Dialog, DialogContent, Divider, Stack, Toolbar } from "@mui/material";
import { AppContext } from "../../common/AppContext";
import { Utils } from "../../common/Utils";
import { ErrorModel } from "../../common/models/ErrorModel";
import { Alert } from "../../components/Alert";
import { Button } from "../../components/Button";
import { ViewComponent } from "../../components/ViewComponent";
import { SubmitBillingDetails } from "../../data/settings/SubmitBillingDetails";
import { ReactComponent as CloseIcon } from "../../resources/images/icons-close.svg";
import { ReactComponent as Logo } from "../../resources/images/logo.svg";
import { BillingContact } from "./BillingContact";
import { BillingDetails } from "./BillingDetails";

export class BillingDetailsDialog extends ViewComponent {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);

    this.state = {
      error: null,
      submitting: false,
      submitted: false,
      billingContact: this.getBillingContact(props.existingBillingDetails),
      billingDetails: this.getBillingDetails(props.existingBillingDetails)
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    super.componentDidUpdate(prevProps, prevState);
    if (this.props.existingBillingDetails !== prevProps.existingBillingDetails) {
      this.setState({
        billingContact: this.getBillingContact(this.props.existingBillingDetails),
        billingDetails: this.getBillingDetails(this.props.existingBillingDetails)
      });
    }
  }

  getBillingContact(existingBillingDetails: any) {
    const contactDetails = existingBillingDetails ? existingBillingDetails.contactDetails : null;

    return {
      firstName: contactDetails ? contactDetails.contactFirstName : null,
      lastName: contactDetails ? contactDetails.contactLastName : null,
      email: contactDetails ? contactDetails.contactEmail : null,
      phone: contactDetails ? contactDetails.contactPhone : null
    };
  }

  getBillingDetails(existingBillingDetails: any) {
    const contactDetails = existingBillingDetails ? existingBillingDetails.contactDetails : null;

    return {
      company: contactDetails ? contactDetails.companyName : null,
      billingAddress: contactDetails ? contactDetails.street1 : null,
      country: contactDetails ? contactDetails.country : null,
      city: contactDetails ? contactDetails.city : null,
      state: contactDetails ? contactDetails.state : null,
      zipCode: contactDetails ? contactDetails.postalCode : null
    };
  }

  onBillingContactChange(e: any, billingContact: any) {
    this.setState({ billingContact });
  }

  onBillingDetailsChange(e: any, billingDetails: any) {
    this.setState({ billingDetails });
  }

  validateBillingContactDetails(billingContact: any) {
    if (!billingContact.firstName) {
      this.setError("Please enter a first name.");
      return false;
    } else if (!billingContact.lastName) {
      this.setError("Please enter a last name.");
      return false;
    } else if (!Utils.isValidEmail(billingContact.email)) {
      this.setError("Please enter an email address.");
      return false;
    } else if (!billingContact.phone) {
      this.setError("Please enter a phone number.");
      return false;
    }
    return true;
  }

  validateBillingDetails(billingDetails: any) {
    if (!billingDetails.company) {
      this.setError("Please enter a company name.");
      return false;
    } else if (!billingDetails.billingAddress) {
      this.setError("Please enter a billing address.");
      return false;
    } else if (!billingDetails.country) {
      this.setError("Please enter a country.");
      return false;
    } else if (!billingDetails.city) {
      this.setError("Please enter a city.");
      return false;
    } else if (!billingDetails.state) {
      this.setError("Please enter a state.");
      return false;
    } else if (!billingDetails.zipCode) {
      this.setError("Please enter a zip code.");
      return false;
    }
    return true;
  }

  onSubmit(e: any) {
    const { existingBillingDetails, onClose } = this.props;
    const { billingContact, billingDetails } = this.state;

    if (!this.validateBillingContactDetails(billingContact)) return false;
    if (!this.validateBillingDetails(billingDetails)) return false;

    this.context.setLoading("submitting", true);
    this.setState({ error: null, submitting: true });

    if (existingBillingDetails) {
      SubmitBillingDetails.put(this.context, {
        contactFirstName: billingContact.firstName,
        contactLastName: billingContact.lastName,
        contactEmail: billingContact.email,
        contactPhone: billingContact.phone,
        companyName: billingDetails.company,
        street1: billingDetails.billingAddress,
        country: billingDetails.country.name,
        city: billingDetails.city,
        state: billingDetails.state,
        postalCode: billingDetails.zipCode
      })
        .then((n) => {
          this.setState({ error: null, submitted: true });
          onClose();
        })
        .catch((e) => {
          const error = ErrorModel.parseServerError(e);
          this.setState({ error });
        })
        .finally(() => {
          this.context.setLoading("submitting", false);
          this.setState({ submitting: false });
        });
    }
  }

  setError(error: any) {
    this.setState({ error });
    if (error) window.scrollTo(0, 0);
  }

  render() {
    const { organization, user, onClose } = this.props;
    const { error, submitting, submitted, billingContact, billingDetails } = this.state;

    return (
      <Dialog className='klayo-billingplandialog' onClose={onClose} open={true} fullScreen={true}>
        <AppBar className='klaro_employeeprofile-appbar' elevation={0}>
          <Toolbar>
            <Logo className='klaro-appbar_logo' />
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Button
                autoFocus
                label='Close'
                variant='outlined'
                size='md'
                startIcon={<CloseIcon />}
                onClick={onClose}
              />
            </Box>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ marginTop: "64px", backgroundColor: "#F8F8FE" }}>
          <div className='klayo-billingplandialog_content'>
            {error && (
              <Alert severity='error' scrollTo={true}>
                {error}
              </Alert>
            )}

            <Stack direction='column' spacing={4}>
              <BillingContact
                user={user}
                billingContact={billingContact}
                organization={organization}
                onChange={this.onBillingContactChange.bind(this)}
                disabled={submitting}
              />

              <Divider sx={{ margin: "30px 0 0 0" }} />

              <BillingDetails
                user={user}
                billingDetails={billingDetails}
                organization={organization}
                onChange={this.onBillingDetailsChange.bind(this)}
                disabled={submitting}
              />

              <div style={{ textAlign: "center", padding: "30px" }}>
                <Button
                  label='Save details'
                  variant='filled'
                  onClick={this.onSubmit.bind(this)}
                  disabled={submitting}
                />
              </div>
            </Stack>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
