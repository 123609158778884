import { Autocomplete, Stack, createFilterOptions } from "@mui/material";
import React, { Component } from "react";
import { ReactComponent as DropIcon } from "../../resources/images/icons-chevron-double.svg";
import { ReactComponent as RemoveIcon } from "../../resources/images/icons-remove.svg";
import { TextField } from "../TextField";
import { SingleSelectorContent } from "./SingleSelectorContent";

export class SingleSelector extends Component<any, any> {
  static defaultProps = {};
  selectorContainerRef: any;
  filterOptions: any;

  constructor(props: any) {
    super(props);

    this.state = {
      selectedValues: props.values ? props.values : null,
      valid: null,
      inputValue: null,
      selectorContainerSize: null
    };

    this.filterOptions = createFilterOptions({
      matchFrom: "any",
      limit: props.maxResults,
      stringify: (option) => this.getItemLabel(option)
    });

    this.selectorContainerRef = React.createRef();
  }

  componentDidMount() {
    this.onGetSelectorContainerSize();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.values !== this.props.values) {
      this.setState({ selectedValues: this.props.values });
    }
    if (prevProps.allSelected !== this.props.allSelected)
      this.setState({ allSelected: this.props.allSelected });
  }

  onGetSelectorContainerSize() {
    const selectorContainerSize = this.selectorContainerRef?.current.getBoundingClientRect();
    this.setState({ selectorContainerSize });
  }

  onChange(e: any, selected: any, reason: any, details: any) {
    const { onChange } = this.props;

    this.setState({ selectedValues: selected ? selected : null }, () => {
      if (onChange) onChange(e, selected);
    });
  }

  onInputChange(e: any) {
    const { onSearch } = this.props;

    if (e && onSearch) onSearch(e.target.value);
    if (e) this.setState({ inputValue: e.target.value });
  }

  onBlur(e: any) {
    const { onBlur } = this.props;

    if (onBlur) onBlur(e);
  }

  // render autocomplete
  renderOption(props: any, option: any, state: any) {
    const { hasNewItem, organization } = this.props;
    const { selectorContainerSize } = this.state;

    return (
      <SingleSelectorContent
        organization={organization}
        option={option}
        inputValue={state.inputValue}
        props={props}
        selectorContainerSize={selectorContainerSize}
        getItemLabel={this.getItemLabel.bind(this)}
        getItemId={this.getItemId.bind(this)}
      />
    );
  }

  getItemLabel(item: any) {
    const { getItemLabel } = this.props;

    return item ? (getItemLabel && !item.name ? getItemLabel(item) : item.name) : "";
  }

  getItemId(item: any) {
    const { getItemId } = this.props;
    return getItemId ? getItemId(item) : item;
  }

  render() {
    const {
      items,
      label,
      sx,
      helperText,
      placeHolder,
      validationText,
      itemFilter,
      renderSelector,
      disabled,
      className,
      organization
    } = this.props;
    const { selectedValues, valid } = this.state;

    return (
      <Stack
        className={"klayo-multiselector" + (className ? " " + className : "")}
        direction='column'
        ref={this.selectorContainerRef}
        spacing={2}
      >
        {renderSelector ? (
          renderSelector({
            onChange: this.onChange.bind(this),
            onBlur: this.onBlur.bind(this),
            clearable: false,
            clearOnSelection: true,
            excludeItems: selectedValues,
            disabled: disabled
          })
        ) : (
          <Autocomplete
            className='klayo-selector'
            options={items ? items : []}
            value={selectedValues ? selectedValues : null}
            getOptionLabel={this.getItemLabel.bind(this)}
            onChange={this.onChange.bind(this)}
            onInputChange={this.onInputChange.bind(this)}
            filterOptions={(options, params) => {
              const filtered = this.filterOptions(options, params);
              return filtered;
            }}
            includeInputInList
            filterSelectedOptions
            popupIcon={<DropIcon />}
            clearIcon={<RemoveIcon />}
            disableClearable={false}
            handleHomeEndKeys
            onBlur={this.onBlur.bind(this)}
            disabled={disabled}
            componentsProps={{
              paper: {
                className: "klayo-singleselector_dropdownlist_paper"
              }
            }}
            ListboxProps={{
              className: "klayo-singleselector_dropdownlist"
            }}
            ChipProps={{
              deleteIcon: <RemoveIcon />
            }}
            multiple={false}
            renderOption={this.renderOption.bind(this)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeHolder ? placeHolder : "Search"}
                error={valid === false}
                helperText={valid === false ? validationText : helperText}
                sx={{ width: "100%" }}
                endAdornment={params.InputProps.endAdornment}
              />
            )}
            sx={sx}
          />
        )}
      </Stack>
    );
  }
}
