import { Data } from "../Data";
import { TeamData } from "../users/TeamData";
import { User } from "../users/User";

export class TeamFilterOptions extends Data {
  static instance: any = {};
  static filteredInstance = null;
  employees: any;
  jobs: any;
  locations: any;

  constructor(options: any) {
    super({
      api: options.groupId
        ? "/MyGroup/" + options.groupId + "/GetFilterOptions"
        : "/Teams/GetFilterOptions",
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.employees = [];
    data.employees.forEach((e: any) =>
      this.employees.push(new User({ data: { id: e.employeeId, ...e } }))
    );

    this.jobs = data.jobs;
    this.locations = data.locations;
  }

  getEmployee(id: any) {
    return this.employees.find((a: any) => a.employeeId === id);
  }

  sortEmployees(sortedIds: any) {
    this.employees.sort(
      (a: any, b: any) => sortedIds.indexOf(a.employeeId) - sortedIds.indexOf(b.employeeId)
    );
  }

  static get(context: any, groupId: any) {
    return new Promise((resolve: any, reject) => {
      const instKey = TeamData.getInstanceKey(groupId);
      if (TeamFilterOptions.instance[instKey]) resolve(TeamFilterOptions.instance[instKey], true);
      else
        TeamFilterOptions.instance[instKey] = new TeamFilterOptions({
          resolve,
          reject,
          context,
          groupId
        });
    });
  }
}
