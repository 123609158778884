import { Stack } from "@mui/material";
import React, { Component } from "react";
import { ChipContainer } from "../../components/ChipContainer";
import { Dialog } from "../../components/dialogs/Dialog";

export class PracticalAttributeSelectorDialog extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedAttributes: []
    };
  }

  onSave() {
    const { onSave, onClose, value, attributes } = this.props;
    const { selectedAttributes } = this.state;

    const removedAttributes = attributes.filter(
      (attr: any) =>
        value.some(
          (defaultSelectedAttr: any) =>
            defaultSelectedAttr.attributeDefinitionId === attr.attributeDefinitionId
        ) &&
        !selectedAttributes.some(
          (selectedAttr: any) => selectedAttr.attributeDefinitionId === attr.attributeDefinitionId
        )
    );

    onSave?.(selectedAttributes, removedAttributes);
    onClose?.();
  }

  onAttributesChange(_: any, selectedAttributes: any) {
    this.setState({ selectedAttributes });
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.attributes !== this.props.attributes) {
      const attributes = this.props.attributes;
      const selectedAttrs = this.props.value;

      this.setState({
        selectedAttributes: attributes.filter((attr: any) =>
          selectedAttrs.some(
            (selectedAttr: any) => selectedAttr.attributeDefinitionId === attr.attributeDefinitionId
          )
        )
      });
    }
  }

  render() {
    const { task, onClose, attributes = [], theme } = this.props;
    const { selectedAttributes } = this.state;
    return (
      <Dialog
        open={!!task}
        theme={theme}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        title='Add competencies'
        actions={[
          {
            label: "Add competencies",
            primary: true,
            disabled: attributes.length === 0 && selectedAttributes.length === 0,
            onClick: this.onSave.bind(this)
          },
          {
            label: "Cancel",
            onClick: onClose
          }
        ]}
      >
        <Stack direction='column' spacing={4}>
          <Stack direction='column' spacing={4}>
            {attributes.length > 0 ? (
              <>
                <div>
                  <b>{task?.name}</b> task has the following linked competencies.
                  <br />
                  Select one or more to be added to the practical.
                </div>
                <ChipContainer
                  items={attributes}
                  selectedItems={selectedAttributes}
                  onChange={this.onAttributesChange.bind(this)}
                  showDivider={false}
                />
              </>
            ) : (
              <div>
                <b>{task?.name}</b> task has no linked competencies.
              </div>
            )}
          </Stack>
        </Stack>
      </Dialog>
    );
  }
}
