import { AbstractAttribute } from "./AbstractAttribute";

export class AttributeDefinition extends AbstractAttribute {
  category: string;
  description: string;
  attribute: string;
  organizationId: string;
  trainingCourses: string[];

  constructor(index: any, data: any) {
    super(index, data);

    this.category = data.category;
    this.description = data.description;
    this.attribute = data.name;
    this.organizationId = data.organizationId;
    this.trainingCourses = data.trainingCourses;
  }
}
