import { Link } from "@mui/material";
import { DateLabel } from "klayowebshared";
import React from "react";
import { FeedbackAttribute } from "../../data/attributes/FeedbackAttribute";
import { ProfilePicture } from "../ProfilePicture";
import { Table } from "./Table";

export class FeedbackTable extends Table {
  static columns = {
    summary: {
      field: "summary",
      label: "Summary",
      visibleToBreakpoint: "md"
    },
    requestDateTime: {
      field: "requestDateTime",
      label: "Request Date",
      visibleFromBreakpoint: "md"
    },
    profilePicFullNameJobTitle: {
      field: "profilePicFullNameJobTitle",
      label: "Employee",
      visibleFromBreakpoint: "md"
    },
    attribute: {
      field: "attribute",
      label: "Competency",
      visibleFromBreakpoint: "lg"
    },
    requestType: {
      field: "requestType",
      label: "Request Type",
      visibleFromBreakpoint: "md"
    },
    status: {
      field: "status",
      label: "Status",
      visibleFromBreakpoint: "md"
    }
  };

  static defaultProps: any = {
    ...Table.defaultProps,
    columns: [
      { type: FeedbackTable.columns.summary },
      { type: FeedbackTable.columns.profilePicFullNameJobTitle },
      { type: FeedbackTable.columns.requestDateTime },
      { type: FeedbackTable.columns.attribute },
      { type: FeedbackTable.columns.requestType },
      { type: FeedbackTable.columns.status }
    ],
    noRowsMessage: "No rows, yet",
    showProficiency: false,
    rowHasAction: null
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state,
      sortModel: this.props.sortModel
        ? this.props.sortModel
        : [
            {
              field: "requestDateTime",
              sort: "desc"
            }
          ]
    };
  }

  getRowId(a: any) {
    return this.tableId + "_" + a.employeeAttributeValidationRequestId;
  }

  onRowClick(params: any, e: any) {
    const { onFeedbackSelect, rowHasSelect } = this.props;
    if (onFeedbackSelect && (!rowHasSelect || rowHasSelect(params.row)))
      onFeedbackSelect(e, params.row);
  }

  initColumns() {
    const { showProficiency, columns, sortable, onReviewLinkClick } = this.props;

    this.columns = [];

    columns.forEach((c: any) => {
      if (this.hasColumn(c)) {
        if (c.type === FeedbackTable.columns.summary)
          this.columns.push({
            field: "summary",
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            flex: 1,
            numRows: 4,
            filterable: false,
            disableReorder: true,
            renderCell: (params: any) => (
              <div className='klayo-feedbacktable_summarycol'>
                <div className='klayo-feedbacktable_summarycol_date'>
                  <DateLabel date={params.row.requestDateTime} />
                </div>
                <div className='klayo-feedbacktable_summarycol_name'>
                  {params.row.employee.fullName}
                </div>
                <div className='klayo-feedbacktable_summarycol_attribute'>
                  {params.row.attribute}
                </div>
                <div className='klayo-feedbacktable_summarycol_action'>
                  {params.row.state === FeedbackAttribute.state.submitted ? (
                    <span>{params.row.stateLabel}</span>
                  ) : params.row.state === FeedbackAttribute.state.approved ? (
                    <span>{params.row.approvedStatus.label}</span>
                  ) : (
                    <Link
                      className='klaro_feedback-table_reviewlink'
                      onClick={(e) => onReviewLinkClick(e, params.row)}
                    >
                      {params.row.stateLabel}
                    </Link>
                  )}
                </div>
              </div>
            )
          });

        if (c.type === FeedbackTable.columns.requestDateTime)
          this.columns.push({
            field: "requestDateTime",
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            width: 200,
            filterable: false,
            disableReorder: true,
            renderCell: (params: any) => <DateLabel date={params.row.requestDateTime} />
          });

        if (c.type === FeedbackTable.columns.profilePicFullNameJobTitle)
          this.columns.push({
            field: "profilePicFullNameJobTitle",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            width: 300,
            disableReorder: true,
            valueGetter: (params: any) => params.row.employee.fullName,
            renderCell: (params: any) => (
              <div className='klayo_employeetable_pic klayo_employeetable_picjobname'>
                <ProfilePicture user={params.row.employee} size='sm' />
                <div>
                  <div className='klayo_employeetable_name'>{params.row.employee.fullName}</div>
                  <div className='klayo_employeetable_job'>{params.row.employee.jobTitle}</div>
                </div>
              </div>
            )
          });

        if (c.type === FeedbackTable.columns.attribute)
          this.columns.push({
            field: "attribute",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            flex: 1,
            disableReorder: true
          });

        if (c.type === FeedbackTable.columns.requestType)
          this.columns.push({
            field: "typeLabel",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            width: 200,
            filterable: false,
            disableReorder: true
          });

        //Todo: change rendering
        if (c.type === FeedbackTable.columns.status)
          this.columns.push({
            field: "stateLabel",
            sortable: sortable,
            headerName: c.label || c.type.label,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            visibleToBreakpoint: c.visibleToBreakpoint || c.type.visibleToBreakpoint,
            width: 200,
            align: "right",
            headerAlign: "right",
            filterable: false,
            disableReorder: true,
            disableColumnMenu: true,
            disableColumnFilter: true,
            renderCell: (params: any) => {
              return params.row.state === FeedbackAttribute.state.submitted ? (
                <span>{params.row.stateLabel}</span>
              ) : params.row.state === FeedbackAttribute.state.approved ? (
                <span>{params.row.approvedStatus.label}</span>
              ) : (
                <Link
                  className='klaro_feedback-table_reviewlink'
                  onClick={(e) => onReviewLinkClick(e, params.row)}
                >
                  {params.row.stateLabel}
                </Link>
              );
            },
            valueGetter: (params: any) =>
              params.row.state === FeedbackAttribute.state.submitted
                ? params.row.stateLabel
                : params.row.state === FeedbackAttribute.state.approved
                  ? params.row.approvedStatus.label
                  : params.row.stateLabel
          });
      }
    });
  }
}
