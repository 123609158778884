import { Box, Grid } from "@mui/material";
import { Delayed, Theme } from "klayowebshared";
import { TeamFilterModel } from "../../common/models/TeamFilterModel";
import { TaskBubbleChart } from "../../components/charts/TaskBubbleChart";
import { Paper } from "../../components/Paper";
import { SectionLoader } from "../../components/SectionLoader";
import { TeamTaskTable } from "../../components/table/TeamTaskTable";
import { TeamFilterSection } from "../../components/TeamFilterSection";
import { Organization } from "../../data/Organization";
import useTeamRegulationSection from "../../hooks/useTeamRegulationSection";
import { RegulationPeopleListDialog } from "./RegulationPeopleListDialog";

type Props = {
  heading: string;
  theme: any;
  organization: Organization;
  filter: TeamFilterModel;
  groupId?: string;
  onEmployeeSelect: (e: any, employee: any) => void;
  basePath: string;
  basePathDepth: number;
  sortOrder?: any;
  onSortOrderChange?: (e: any, sortOrder: any) => void;
};

const TeamRegulationSection = ({
  heading,
  theme,
  organization,
  filter,
  groupId,
  basePath,
  basePathDepth,
  onEmployeeSelect,
  sortOrder,
  onSortOrderChange
}: Props) => {
  const {
    isLoading,
    classificationsSummary,
    setSearch,
    regulationsTree,
    onSwitchToGapMode,
    isGapMode,
    getTreeDataPath,
    onSortModelChange,
    tableRef,
    sortModel,
    regulationPeopleListDialogRef,
    onCloseRegulationPeopleListDialog,
    onPeopleSelect,
    onGapSelect,
    search,
    handleSortOrderChange
  } = useTeamRegulationSection({
    filter,
    groupId,
    basePath,
    basePathDepth,
    sortOrder,
    onSortOrderChange
  });

  return (
    <>
      <Grid container spacing={4} alignItems='stretch'>
        {isLoading ? (
          <Grid item xs={12}>
            <Box
              sx={{
                padding: "20px",
                background: "white",
                borderRadius: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <SectionLoader />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                title={heading}
                floatingTitle={true}
                padding={0}
                sx={{ height: "350px" }}
                overflow='visible'
              >
                <TaskBubbleChart
                  theme={theme}
                  attributes={classificationsSummary}
                  onAttributeSelect={isGapMode ? onGapSelect : onPeopleSelect}
                  sx={{ height: "350px" }}
                  {...(isGapMode
                    ? {
                        bubbleColor: Theme.getStyleVar("--torch-red-tr-050"),
                        bubbleTextColor: Theme.getStyleVar("--grey-gr-900")
                      }
                    : {})}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TeamFilterSection
                search={search}
                onSearch={(keyword: string) => setSearch(keyword)}
                searchPlaceholder='Search regulation, task or competency'
                isGapMode={isGapMode}
                onSwitchToGapMode={onSwitchToGapMode}
                sortOrder={sortOrder}
                onSortOrderChange={handleSortOrderChange}
                theme={theme}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Delayed>
                <TeamTaskTable
                  rows={regulationsTree}
                  theme={theme}
                  ref={tableRef}
                  showHeaderFromBreakpoint='sm'
                  columns={[
                    {
                      type: TeamTaskTable.columns.summaryWithPeople,
                      visibleToBreakpoint: "sm"
                    },
                    {
                      type: TeamTaskTable.columns.attribute,
                      label: "Regulation",
                      visibleFromBreakpoint: "sm"
                    },
                    { type: TeamTaskTable.columns.attributeEmployees, visibleFromBreakpoint: "sm" },
                    { type: TeamTaskTable.columns.gapEmployees, visibleFromBreakpoint: "sm" }
                  ]}
                  showProficiency={organization && organization.showProficiency}
                  sortModel={sortModel}
                  onSortModelChange={onSortModelChange}
                  noRowsMessage='Nothing here yet!'
                  getTreeDataPath={getTreeDataPath}
                  tree
                  groupingColDef={{
                    headerName: "",
                    field: "job",
                    width: 50,
                    minWidth: 50,
                    cellClassName: "klayo-table_expandbuttoncell",
                    valueGetter: () => ""
                  }}
                  onPeopleSelect={onPeopleSelect}
                  onGapSelect={onGapSelect}
                  keyword={search}
                />
              </Delayed>
            </Grid>
          </>
        )}
      </Grid>
      <RegulationPeopleListDialog
        ref={regulationPeopleListDialogRef}
        organization={organization}
        theme={theme}
        onClose={onCloseRegulationPeopleListDialog}
        onEmployeeSelect={onEmployeeSelect}
      />
    </>
  );
};

export { TeamRegulationSection };
