export class Group {
  groupId: string;
  isManualMemberSelection: boolean;
  name: string;
  numberOfEmployees: number;
  numberOfManagers: number;
  hasManagePermission: boolean;
  hasViewPermission: boolean;

  constructor(data: any) {
    this.groupId = data.groupId;
    this.isManualMemberSelection = data.isManualMemberSelection;
    this.name = data.name;
    this.numberOfEmployees = data.numberOfEmployees;
    this.numberOfManagers = data.numberOfManagers;
    this.numberOfManagers = data.numberOfManagers;
    this.hasManagePermission = data.hasManagePermission;
    this.hasViewPermission = data.hasViewPermission;
  }
}
