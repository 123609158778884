import { ActionsContainer } from "klayowebshared";
import { Component } from "react";
import { Paper } from "./Paper";
import { Dialog } from "./dialogs/Dialog";

export class PaperOrDialog extends Component<any, any> {
  static defaultProps = {
    scrollToTopOnMount: true
  };

  componentDidMount() {
    const { scrollToTopOnMount, dialog } = this.props;
    if (!dialog && scrollToTopOnMount) window.scrollTo(0, 0);
  }

  render() {
    const {
      dialog,
      dialogToBreakpoint,
      className,
      disabledToBreakpoint,
      actions,
      justifyActions,
      theme,
      title,
      titleSize,
      children,
      sx,
      paperPadding,
      backButton,
      onBack,
      paperBorderFromBreakpoint,
      titleFontSize,
      titleSx,
      chipText,
      titleRightSide,
      showActions = true
    } = this.props;

    return dialog === true || (theme && theme.isBreakpointDown(dialogToBreakpoint)) ? (
      <Dialog
        open={true}
        theme={theme}
        disabledToBreakpoint={disabledToBreakpoint}
        fullWidth={true}
        maxWidth='sm'
        title={title}
        onClose={onBack}
        sx={sx}
        actions={actions}
        justifyActions={justifyActions}
      >
        {children}
      </Dialog>
    ) : (
      <Paper
        className={`${className ? className : ""}`}
        sx={sx}
        theme={theme}
        padding={paperPadding}
        title={title}
        titleSize={titleSize}
        backButton={backButton}
        borderFromBreakpoint={paperBorderFromBreakpoint}
        titleFontSize={titleFontSize}
        titleSx={titleSx}
        chipText={chipText}
        onBack={onBack}
        titleRightSide={titleRightSide}
      >
        {children}
        {showActions && (
          <ActionsContainer
            actions={actions}
            justifyActions={justifyActions}
            onClose={onBack}
            sx={{ paddingTop: "40px" }}
          />
        )}
      </Paper>
    );
  }
}
