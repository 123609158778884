import { unstable_composeClasses as composeClasses, styled } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { getDataGridUtilityClass, useGridApiContext, useGridRootProps } from "@mui/x-data-grid-pro";

export const GroupingCellWithLazyLoading = (props: any) => {
  const { id, rowNode, row, formattedValue } = props;
  const useUtilityClasses = (ownerState: any) => {
    const { classes } = ownerState;
    const slots = {
      root: ["treeDataGroupingCell"],
      toggle: ["treeDataGroupingCellToggle"]
    };
    return composeClasses(slots, getDataGridUtilityClass, classes);
  };

  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();
  const classes = useUtilityClasses({ classes: rootProps.classes });
  const isLoading = rowNode.childrenExpanded ? !row.childrenFetched : false;
  const Icon = rowNode.childrenExpanded
    ? rootProps.slots.treeDataCollapseIcon
    : rootProps.slots.treeDataExpandIcon;

  const handleClick = (event: any) => {
    event.stopPropagation();
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
  };

  return rowNode.type === "group" ? (
    <Box className={classes.root} sx={{ ml: 0 }} justifyContent='center'>
      {isLoading ? (
        <LoadingContainer>
          <CircularProgress size='1rem' color='inherit' />
        </LoadingContainer>
      ) : (
        <IconButton
          size='small'
          onClick={(event: any) => handleClick(event)}
          tabIndex={-1}
          aria-label={
            rowNode.childrenExpanded
              ? apiRef.current.getLocaleText("treeDataCollapse")
              : apiRef.current.getLocaleText("treeDataExpand")
          }
        >
          <Icon fontSize='inherit' />
        </IconButton>
      )}
    </Box>
  ) : null;
};

const LoadingContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%"
});
