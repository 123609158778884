import styled from "@emotion/styled";
import React, { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { Dialog } from "../../components/dialogs/Dialog";
import { SingleColumnTable } from "../../components/table/SingleColumnTable";
import { TASK_ATTRIBUTE_DIALOG_TYPE } from "../../constants";
import { Data } from "../../data/Data";
import { KLAYO_COLORS } from "../../themes";
export class ConfirmEditTaskLinkedJobDialog extends Component<any, any> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);

    this.state = {
      attributeData: []
    };
  }

  componentDidMount() {
    this.getTaskAttributesDetail();
  }

  getTaskAttributesDetail() {
    this.context.setLoading("tasks", true);
    const { type } = this.props;

    const typeApi =
      type === TASK_ATTRIBUTE_DIALOG_TYPE.COMPETENCIES
        ? "Competencies"
        : type === TASK_ATTRIBUTE_DIALOG_TYPE.REGULATIONS
          ? "Regulations"
          : "JobDefinitions";
    (Data as any)
      .get(this.context, `/Regulation/Task/${this.props.actionTask.row.id}/${typeApi}`, {
        withCredentials: true
      })
      .then((response: any) => {
        if (type === TASK_ATTRIBUTE_DIALOG_TYPE.COMPETENCIES) {
          this.setState({ attributeData: response.data.competencies });
        } else if (type === TASK_ATTRIBUTE_DIALOG_TYPE.REGULATIONS) {
          this.setState({ attributeData: response.data.regulations });
        } else {
          this.setState({ attributeData: response.data.jobDefinitions });
        }
      })
      .catch((e: any) => {})
      .finally(() => {
        this.context.setLoading("tasks", false);
      });
  }

  onAttributeSelect(e: any, attribute: any) {
    const { history, type } = this.props;
    if (type === TASK_ATTRIBUTE_DIALOG_TYPE.COMPETENCIES) {
      history.push("/settings/competencies/edit/" + attribute.attributeDefinitionId);
    } else if (type === TASK_ATTRIBUTE_DIALOG_TYPE.REGULATIONS) {
      history.push("/settings/regulations/edit/" + attribute.id);
    } else {
      history.push("/settings/jobs/edit/" + attribute.jobDefinitionId);
    }
  }

  render() {
    const { theme, onClose, type, dialogHeader, subHeader, onCloseAndSave } = this.props;
    const { attributeData } = this.state;
    return (
      <StyledTrainingAttributesDialog
        className='klayo__training-attribute-dialog'
        open={true}
        theme={theme}
        onClose={onClose}
        fullWidth
        maxWidth='sm'
        actions={[
          {
            label: "Confirm and Save",
            variant: "filled",
            onClick: onCloseAndSave
          },
          {
            label: "Close",
            variant: "filled",
            onClick: onClose
          }
        ]}
      >
        <h1>{dialogHeader}</h1>
        <h2>{subHeader}</h2>

        <SingleColumnTable
          showFooterRowCount={false}
          pagination={false}
          rowHasAction={true}
          minHeight='56px'
          theme={theme}
          showHeaderFromBreakpoint='md'
          hideFirstLastBorder={true}
          onAttributeSelect={this.onAttributeSelect.bind(this)}
          dense={true}
          paginationMode='client'
          columns={[
            {
              type:
                type === TASK_ATTRIBUTE_DIALOG_TYPE.COMPETENCIES
                  ? SingleColumnTable.columns.attributes
                  : type === TASK_ATTRIBUTE_DIALOG_TYPE.REGULATIONS
                    ? SingleColumnTable.columns.regulations
                    : SingleColumnTable.columns.jobDefinitions
            }
          ]}
          paper={false}
          rows={attributeData ? attributeData : null}
        />
      </StyledTrainingAttributesDialog>
    );
  }
}

const StyledTrainingAttributesDialog = styled(Dialog)`
  &.klayo__training-attribute-dialog {
    h1 {
      color: ${KLAYO_COLORS.GreyGR900};
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
      letter-spacing: -0.5px;
      margin-top: 0px;
      margin-bottom: 32px;
    }

    h2 {
      color: ${KLAYO_COLORS.GreyGR900};
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 0px;
      margin-bottom: 24px;
    }

    .main-title {
      font-weight: 700;
    }

    .MuiDialogContent-root {
      padding: 40px 40px 0px 40px;
    }

    .MuiPaper-root {
      max-width: 640px;
    }
  }
`;
