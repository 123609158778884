import { Data } from "../Data";

export class LocationSelectorList extends Data {
  static instance: any = null;
  locations: any;
  attributes: any;

  constructor(options: any) {
    super({
      api:
        (options.groupId
          ? "/MyGroup/" + options.groupId + "/GetLocations"
          : "/Teams/GetLocations") +
        "?searchText=" +
        options.search,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.locations = data.locations;
  }

  static get(context: any, search: any, groupId?: any) {
    return new Promise((resolve: any, reject) => {
      if (LocationSelectorList.instance && search === "")
        resolve(LocationSelectorList.instance, true);
      else
        LocationSelectorList.instance = new LocationSelectorList({
          resolve,
          reject,
          search,
          context,
          groupId
        });
    });
  }

  findMatch(attr: any) {
    return this.attributes.find((a: any) => a.name === attr);
  }
}
