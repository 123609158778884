import { Data } from "../Data";
import { User } from "../users/User";

export class GroupMemberSelectorList extends Data {
  static instance: any = null;
  static defaultPageSize = 100000;
  jobs: any;
  employees: any;
  positions: any;
  currentPage: number;
  totalPages: number | null;
  totalCount: number | null;
  hasNext: boolean;
  hasPrevious: boolean;

  constructor(options: any) {
    const { searchText, pageNumber, pageSize, ...otherOptions } = options;

    const apiOptions = {
      api: "/Group/SearchEmployees",
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    // add params for api if provided
    const queryParams = [];
    if (searchText) queryParams.push(`searchText=${searchText}`);
    if (pageNumber) queryParams.push(`pageNumber=${pageNumber || 1}`);
    if (pageSize)
      queryParams.push(`pageSize=${pageSize || GroupMemberSelectorList.defaultPageSize}`);

    if (queryParams.length > 0) {
      apiOptions.api += `?${queryParams.join("&")}`;
    }

    super(apiOptions);

    this.jobs = [];
    this.positions = [];
    this.currentPage = 1;
    this.totalPages = null;
    this.totalCount = null;
    this.hasNext = false;
    this.hasPrevious = false;
  }

  onDataReceived(data: any) {
    this.employees = [];
    data.employees.data.forEach((employee: any, index: any) =>
      this.employees.push(new User({ data: { id: index, ...employee } }))
    );
  }

  static get(context: any, search: any) {
    return new Promise((resolve, reject) => {
      if (GroupMemberSelectorList.instance && search === "")
        (resolve as any)(GroupMemberSelectorList.instance, true);
      else
        GroupMemberSelectorList.instance = new GroupMemberSelectorList({
          resolve,
          reject,
          search,
          context
        });
    });
  }

  static getWithPaging(context: any, options: any) {
    const { searchText, pageNumber, pageSize } = options;
    return new Promise((resolve, reject) => {
      GroupMemberSelectorList.instance = new GroupMemberSelectorList({
        searchText,
        pageNumber,
        pageSize,
        resolve,
        reject,
        context
      });
    });
  }
}
