import { ListItemText, Menu, MenuItem, Stack } from "@mui/material";
import { ConfirmationDialog } from "klayowebshared";
import { AppContext } from "../../common/AppContext";
import axiosClient from "../../common/AxiosClient";
import { Button } from "../../components/Button";
import { Paper } from "../../components/Paper";
import { GroupsTable } from "../../components/table/GroupsTable";
import { TextField } from "../../components/TextField";
import { ViewComponent } from "../../components/ViewComponent";
import { SettingsGroup } from "../../data/settings/SettingsGroup";
import { ReactComponent as AddIcon } from "../../resources/images/icons-add.svg";
import { ReactComponent as SearchIcon } from "../../resources/images/icons-search.svg";
import { trimToLowercase } from "../../utilities";
import { AddEditGroupView } from "./AddEditGroupView";

export class SettingsGroupsView extends ViewComponent {
  static contextType = AppContext;

  static defaultProps = {
    allowAssignAdminBilling: true
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ...(ViewComponent as any).state,
      saveGroupError: null,
      search: null,
      actionGroup: null,
      deletingGroup: null
    };
  }

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    const { onLoadGroups, onLoadGroupMembers } = this.props;
    onLoadGroups();
    onLoadGroupMembers();
  }

  onViewChange(location: any) {
    if (this.pathEquals("/settings/groups")) this.resetErrors();
  }

  resetErrors() {
    this.setState({ saveGroupError: null });
  }

  onSearch(e: any) {
    this.setState({ search: e.target.value });
  }

  getGroupId() {
    const { location } = this.props;

    const path = location.pathname;
    return path.includes("edit") ? path.replace("/settings/groups/edit/", "") : null;
  }

  getGroup() {
    const { groups } = this.props;
    if (!groups) return null;

    const editGroupId = this.getGroupId();
    return groups.get(editGroupId);
  }

  onGroupAction(e: any, group: any) {
    this.setState({ actionTarget: e.target, actionGroup: group });
  }

  onGroupSelect(e: any, group: any) {
    const { history } = this.props;
    history.push("/settings/groups/edit/" + group.groupId);
  }

  onEditGroup() {
    const { history } = this.props;
    const { actionGroup } = this.state;
    this.onCloseActionMenu();

    history.push("/settings/groups/edit/" + actionGroup.groupId);
  }

  onDeleteGroup() {
    const { actionGroup } = this.state;
    this.setState({ deletingGroup: actionGroup });
    this.onCloseActionMenu();
  }

  onCloseActionMenu() {
    this.setState({ actionTarget: null, actionGroup: null });
  }

  onDeleteGroupConfirmed() {
    const { deletingGroup } = this.state;
    this.deleteGroup(deletingGroup);
    this.setState({ deletingGroup: null });
  }

  onCancelDeleteGroup() {
    this.setState({ deletingGroup: null });
  }

  deleteGroup(group: any) {
    const { groups, onLoadGroups } = this.props;
    this.context.setLoading("deleting", true);

    axiosClient
      .delete("/Group", {
        data: {
          groupId: group.groupId
        }
      })
      .then((response: any) => {
        groups.delete(group);
        if (onLoadGroups) onLoadGroups(true);
      })
      .finally(() => {
        this.context.setLoading("deleting", false);
      });
  }

  onSaveGroup(group: any, callback: any) {
    const { user, groups, onLoadGroups, onDataChanged } = this.props;

    this.resetErrors();
    this.context.setLoading("saveGroup", true);

    if (!group.groupId) {
      axiosClient
        .post("/Group", group.toApiDto())
        .then((response: any) => {
          group.groupId = response.data.group.groupId;
          groups.add(group);
          if (onLoadGroups) onLoadGroups(true);
          if (onDataChanged) onDataChanged("groups");
          this.props.history.push("/settings/groups");
        })
        .finally(() => {
          this.context.setLoading("saveGroup", false);
          callback();
        });
    } else {
      axiosClient
        .put("/Group", group.toApiDto())
        .then((response: any) => {
          groups.update(group);
          if (onLoadGroups) onLoadGroups(true);
          if (onDataChanged) onDataChanged("groups");
          this.props.history.push("/settings/groups");
        })
        .finally(() => {
          this.context.setLoading("saveGroup", false);
        });
    }
  }

  render() {
    const {
      organization,
      theme,
      user,
      location,
      history,
      jobs,
      groups,
      orgSettings,
      onDataChanged,
      onNavigation,
      onBlockNavigation,
      onAllowNavigation,
      onLoadJobs,
      groupMembers
    } = this.props;
    const { search, saveGroupError, actionTarget, actionGroup, deletingGroup } = this.state;

    const path = location.pathname;
    const editMode = path.includes("edit");
    const showAddEditGroupView =
      path.startsWith("/settings/groups/new") || path.startsWith("/settings/groups/edit");

    const editGroup = this.getGroup();
    const editGroupId = this.getGroupId();

    const filterItems = [
      { id: "search", columnField: "name", operatorValue: "contains", value: search }
    ];

    const filterGroups = groups
      ? groups.groups.filter((group: any) => {
          if (search) {
            const keyword = trimToLowercase(search || "");
            const groupName = trimToLowercase(group?.name || "");
            return groupName?.includes(keyword);
          } else {
            return true;
          }
        })
      : [];

    return (
      <div>
        {!showAddEditGroupView ? (
          <Paper theme={theme} padding={{ xs: "46px 24px", md: "60px" }} borderFromBreakpoint='md'>
            <h1>Groups</h1>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={2}
              sx={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)", padding: "30px 0" }}
            >
              {groups && groups.groups.length > 0 ? (
                <TextField
                  value={search}
                  dense={true}
                  placeholder='Search groups'
                  disabled={user === null}
                  fullWidth={true}
                  autoComplete={false}
                  leadingIcon={<SearchIcon />}
                  onChange={this.onSearch.bind(this)}
                  sx={{ maxWidth: { md: "300px" } }}
                />
              ) : (
                <div></div>
              )}
              <Button
                path={"/settings/groups/new"}
                size='md'
                theme={theme}
                variant='filled'
                showLabelFromBreakpoint='md'
                startIcon={<AddIcon />}
                label='New group'
              />
            </Stack>
            <GroupsTable
              pagination={false}
              showFooter={false}
              rowHasAction={true}
              minHeight='300px'
              theme={theme}
              sortable={true}
              showHeaderFromBreakpoint='md'
              filterItems={filterItems}
              hideFirstLastBorder={true}
              dense={true}
              columns={[
                { type: GroupsTable.columns.name },
                { type: GroupsTable.columns.memberCount },
                { type: GroupsTable.columns.managerCount },
                { type: GroupsTable.columns.actions }
              ]}
              sortModel={[
                {
                  field: "name",
                  sort: "asc"
                }
              ]}
              onGroupSelect={this.onGroupSelect.bind(this)}
              onGroupAction={this.onGroupAction.bind(this)}
              paper={false}
              // rows={groups ? groups.groups : null}
              rows={filterGroups}
            />

            {actionGroup && (
              <Menu
                open={actionTarget !== null}
                anchorEl={actionTarget}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                onClose={this.onCloseActionMenu.bind(this)}
              >
                <MenuItem onClick={this.onEditGroup.bind(this)}>
                  <ListItemText>Edit group</ListItemText>
                </MenuItem>
                <MenuItem onClick={this.onDeleteGroup.bind(this)}>
                  <ListItemText>Delete group</ListItemText>
                </MenuItem>
              </Menu>
            )}

            {deletingGroup && (
              <ConfirmationDialog
                theme={theme}
                title='Delete group'
                question={
                  "Are you sure you want to delete " +
                  deletingGroup.name +
                  "? This operation cannot be undone."
                }
                cancelButton='Cancel'
                acceptButton='Delete group'
                acceptDanger={true}
                onCancel={this.onCancelDeleteGroup.bind(this)}
                onAccept={this.onDeleteGroupConfirmed.bind(this)}
              />
            )}
          </Paper>
        ) : (
          <AddEditGroupView
            user={user}
            organization={organization}
            orgSettings={orgSettings}
            theme={theme}
            editMode={editMode}
            group={editMode ? editGroup : new SettingsGroup()}
            groupId={editGroupId}
            error={saveGroupError}
            jobs={jobs}
            locations={orgSettings ? orgSettings.locations : null}
            onDataChanged={onDataChanged}
            onCancel={(e: any) => history.push("/settings/groups")}
            onSave={this.onSaveGroup.bind(this)}
            onLoadJobs={onLoadJobs}
            onBlockNavigation={onBlockNavigation}
            onAllowNavigation={onAllowNavigation}
            onNavigation={onNavigation}
            groupMembers={groupMembers}
          />
        )}
      </div>
    );
  }
}
