import { Component } from "react";
import { AppContext } from "../../common/AppContext";
import { AbstractAttributeList } from "../../data/attributes/AbstractAttributeList";

export class AbstractProfileSection extends Component<any, any> {
  actionTarget: any;
  actionAttribute: any;
  menu: any;
  setMenuRef: (menu: any) => void;
  static contextType = AppContext;

  constructor(props: any) {
    super(props);

    this.state = {
      filter: AbstractAttributeList.allFilter,
      search: null,
      attribute: null
    };

    this.actionTarget = null;
    this.actionAttribute = null;

    this.setMenuRef = (menu: any) => {
      this.menu = menu;
    };
  }

  onFilterChange(filter: any) {
    this.setState({ filter: filter });
  }

  onSearch(search: any) {
    this.setState({ search: search });
  }

  onAttributeAction(e: any, attr: any) {
    if (this.menu) this.menu.open(e.target);
    this.actionTarget = e.target;
    this.actionAttribute = attr;
  }

  closeActionMenus() {
    this.actionTarget = null;
    this.actionAttribute = null;
    if (this.menu) this.menu.close();
  }
}
