import { Data } from "../Data";
import { User } from "../users/User";

export class PersonSelectorList extends Data {
  static instance: any = null;
  employees: any;
  attributes: any;

  constructor(options: any) {
    super({
      api:
        (options.groupId
          ? "/MyGroup/" + options.groupId + "/SearchPeopleByName"
          : "/Teams/SearchPeopleByName") +
        "?searchText=" +
        options.search,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.employees = [];
    data.employees.forEach((employee: any, index: any) =>
      this.employees.push(new User({ data: { id: index, ...employee } }))
    );
  }

  static get(context: any, search: any, groupId?: any) {
    return new Promise((resolve: any, reject) => {
      if (PersonSelectorList.instance && search === "") resolve(PersonSelectorList.instance, true);
      else
        PersonSelectorList.instance = new PersonSelectorList({
          resolve,
          reject,
          search,
          context,
          groupId
        });
    });
  }

  findMatch(attr: any) {
    return this.attributes.find((a: any) => a.name === attr);
  }
}
