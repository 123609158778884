import { CompanyView } from "../views/company/CompanyView";
import { Feedback } from "../views/feedback/Feedback";
import { Practicals } from "../views/practicals/Practicals";
import { Profile } from "../views/profile/Profile";
import { Settings } from "../views/settings/Settings";
import { OrgSignupView } from "../views/signup/OrgSignupView";
import { Group } from "../views/team/Group";
import { Team } from "../views/team/Team";

export const PageComponents: Record<any, any> = {
  profile: Profile,
  team: Team,
  company: CompanyView,
  groups: Group,
  practicals: Practicals,
  feedback: Feedback,
  settings: Settings,
  signup: OrgSignupView
};
