import styled from "@emotion/styled";
import { Skeleton, Tooltip, Typography } from "@mui/material";
import React, { Component } from "react";
export class ViewHeading extends Component<any, any> {
  static defaultProps = {
    maxLabelLength: 19
  };

  constructor(props: any) {
    super(props);
  }

  render() {
    const { text, maxLabelLength } = this.props;

    return (
      <StyledViewHeading
        variant='h1'
        className='klayo-viewheading'
        sx={{
          fontSize: {
            xs: "20px",
            sm: "20px",
            md: "30px"
          }
        }}
      >
        <Tooltip
          title={text && text?.length > maxLabelLength ? text : null}
          placement='bottom-start'
        >
          {text ? text : <Skeleton width={150} height={36} />}
        </Tooltip>
      </StyledViewHeading>
    );
  }
}

const StyledViewHeading = styled(Typography)`
  &.klayo-viewheading {
    margin: 0 0 40px 0;
    white-space: break-spaces;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
