import { Stack } from "@mui/material";
import React, { Component } from "react";
import { TextField } from "../../components/TextField";

export class BillingContact extends Component<any, any> {
  static defaultProps = {
    disabled: true
  };

  constructor(props: any) {
    super(props);

    this.state = this.getBillingContact(props.billingContact);
    this.onChange(null);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.billingContact !== prevProps.billingContact) {
      this.setState(this.getBillingContact(this.props.billingContact));
    }
  }

  getBillingContact(billingContact: any) {
    const { user } = this.props;

    return {
      firstName:
        billingContact && billingContact.firstName
          ? billingContact.firstName
          : user
            ? user.firstName
            : "",
      lastName:
        billingContact && billingContact.lastName
          ? billingContact.lastName
          : user
            ? user.lastName
            : "",
      email: billingContact && billingContact.email ? billingContact.email : user ? user.email : "",
      phone: billingContact && billingContact.phone ? billingContact.phone : ""
    };
  }

  onFirstNameChange(e: any) {
    this.setState({ firstName: e.target.value }, () => this.onChange(e));
  }

  onLastNameChange(e: any) {
    this.setState({ lastName: e.target.value }, () => this.onChange(e));
  }

  onEmailChange(e: any) {
    this.setState({ email: e.target.value }, () => this.onChange(e));
  }

  onPhoneChange(e: any) {
    this.setState({ phone: e.target.value }, () => this.onChange(e));
  }

  onChange(e: any) {
    const { onChange } = this.props;
    if (onChange) onChange(e, this.state);
  }

  render() {
    const { disabled } = this.props;
    const { firstName, lastName, email, phone } = this.state;

    return (
      <div>
        <h2>Billing contact</h2>
        <Stack spacing={5}>
          <Stack direction='row' spacing={4} justifyContent='space-between'>
            <TextField
              label='First name'
              value={firstName}
              clearable={false}
              autoComplete={false}
              onChange={this.onFirstNameChange.bind(this)}
              disabled={disabled}
              sx={{ width: "100%" }}
            />

            <TextField
              label='Last name'
              value={lastName}
              clearable={false}
              autoComplete={false}
              onChange={this.onLastNameChange.bind(this)}
              disabled={disabled}
              sx={{ width: "100%" }}
            />
          </Stack>

          <Stack direction='row' spacing={4} justifyContent='space-between'>
            <TextField
              label='Email'
              value={email}
              clearable={false}
              autoComplete={false}
              onChange={this.onEmailChange.bind(this)}
              disabled={disabled}
              sx={{ width: "100%" }}
            />

            <TextField
              label='Phone'
              value={phone}
              clearable={false}
              autoComplete={false}
              onChange={this.onPhoneChange.bind(this)}
              disabled={disabled}
              sx={{ width: "100%" }}
            />
          </Stack>
        </Stack>
      </div>
    );
  }
}
