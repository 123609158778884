import React, { createContext, ReactNode, useContext, useState } from "react";

interface TeamContextType {
  gapMode: {
    competencies: boolean;
    tasks: boolean;
    regulations: boolean;
    trainings: boolean;
  };
  setGapMode: (gapMode: {
    competencies: boolean;
    tasks: boolean;
    regulations: boolean;
    trainings: boolean;
  }) => void;
}

const TeamContext = createContext<TeamContextType | undefined>(undefined);

export const useTeamContext = () => {
  const context = useContext(TeamContext);
  if (!context) {
    throw new Error("useTeamContext must be used within a TeamProvider");
  }
  return context;
};

interface TeamProviderProps {
  children: ReactNode;
}

export const TeamProvider: React.FC<TeamProviderProps> = ({ children }) => {
  const [gapMode, setGapMode] = useState({
    competencies: false,
    tasks: false,
    regulations: false,
    trainings: false
  });

  return (
    <TeamContext.Provider
      value={{
        gapMode,
        setGapMode
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};
