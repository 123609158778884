import { Data } from "../Data";
import { User } from "./User";

export class SwitchUserList extends Data {
  static instance: any = null;

  list: any;
  users: any;

  constructor(options: any) {
    super({
      api: "/User/EmployeeIds",
      callback: (data: any) => {
        options.resolve({ data: this, cached: false });
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.users = [];

    Object.entries(data).forEach(([key, d]) => {
      this.users.push(new User({ data: d }));
    });
  }

  static get(context: any) {
    return new Promise((resolve, reject) => {
      if (SwitchUserList.instance) resolve({ data: SwitchUserList.instance, cached: true });
      else SwitchUserList.instance = new SwitchUserList({ resolve, reject, context });
    });
  }

  findMatch(attr: any) {
    return this.list.find((a: any) => a.name === attr);
  }
}
