import { Data } from "../Data";
import { SettingsEmployee } from "./SettingsEmployee";

export class SettingsGroupMemberCount extends Data {
  managerMemberOverlap: any;
  totalEmployees: any;

  constructor(options: any) {
    super({
      api: "/Group/CountEmployeesByJobDefinitionsAndLocations?" + options.params,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });

    this.managerMemberOverlap = [];
  }

  onDataReceived(data: any) {
    this.totalEmployees = data.totalEmployees;
    data.overlapEmployees.forEach((e: any) =>
      this.managerMemberOverlap.push(new SettingsEmployee(e))
    );
  }
}
