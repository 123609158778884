import { Data } from "../Data";
import { User } from "../users/User";

export class ManagerSelectorList extends Data {
  employees: any;
  attributes: any;
  static instance: any = null;

  constructor(options: any) {
    super({
      api: "/Employee/SearchManager?searchText=" + options.search,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.employees = [];
    data.employees.forEach((e: any, index: any) => {
      const manager: any = new User({ data: { id: index, ...e } });
      manager.jobId = e.jobId;
      manager.fullName = e.employeeFullName;
      if (manager.fullName === null) manager.fullName = "Vacancy";
      manager.jobTitle = e.jobDefinitionName;
      manager.reportsToEmployeeFullname = e.reportsToEmployeeFullname;
      manager.directReports = e.directReports;
      this.employees.push(manager);
    });
  }

  static get(context: any, search: any) {
    return new Promise((resolve: any, reject) => {
      if (ManagerSelectorList.instance && search === "")
        resolve(ManagerSelectorList.instance, true);
      else
        ManagerSelectorList.instance = new ManagerSelectorList({
          resolve,
          reject,
          search,
          context
        });
    });
  }

  findMatch(attr: any) {
    return this.attributes.find((a: any) => a.name === attr);
  }
}
