export class SettingRegulation {
  id: any;
  name: any;
  createdDate: any;
  lastUpdatedDate: any;
  regulationCategory: any;
  regulationDomain: any;
  regulationRegion: any;
  regulationTasks: any;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.createdDate = data.createdDate;
      this.lastUpdatedDate = data.lastUpdatedDate;
      this.regulationCategory = data.regulationCategory;
      this.regulationDomain = data.regulationDomain;
      this.regulationRegion = data.regulationRegion;
      this.regulationTasks = data.regulationTasks;
    }
  }
}
