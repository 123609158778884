import { Box, Chip } from "@mui/material";
import uniqueId from "lodash.uniqueid";
import React, { Component } from "react";
import { Utils } from "../common/Utils";
import { ReactComponent as DragIcon } from "../resources/images/icons-drag.svg";
import { ReactComponent as RemoveIcon } from "../resources/images/icons-remove.svg";
import { MultiFileUpload } from "./MultiFileUpload";
import { Placeholder } from "./Placeholder";

export class DragDropFileUpload extends Component<any, any> {
  static defaultProps = {
    allowedMimeTypes: ["*/*"],
    maxFileSize: 10240000,
    errorMimeType: "Please upload a valid file",
    errorFileSize: "Please upload a valid file",
    allowedMimeTypesLabel: "PDF, JPEG, PNG, DOC, DOCX, TXT, CSV, XLS, XLSX"
  };

  id: string;
  multiFileUploadRef: React.RefObject<MultiFileUpload>;
  dragDropSupport: boolean;

  constructor(props: any) {
    super(props);

    this.state = {
      error: null,
      files: props.files || [],
      dragOver: false
    };

    this.id = uniqueId("klayo_fileupload");
    this.multiFileUploadRef = React.createRef();

    this.dragDropSupport = "draggable" in document.createElement("div");
  }

  onDropZoneClick(e: any) {
    this.multiFileUploadRef.current?.selectFile(e);
  }

  onDragEnter(e: any) {
    e.preventDefault();
    this.setState({ dragOver: true });
  }

  onDragOver(e: any) {
    e.preventDefault();
  }

  onDragLeave(e: any) {
    e.preventDefault();
    this.setState({ dragOver: false });
  }

  onDrop(e: any) {
    e.preventDefault();
    this.multiFileUploadRef.current?.dropFile(e);
    this.setState({ dragOver: false });
  }

  onChange(e: any, newFiles: any) {
    const { onChange } = this.props;
    this.setState({ files: newFiles });
    if (onChange) onChange(e, newFiles);
  }

  onRemoveFile(e: any, key: any, file?: any) {
    this.multiFileUploadRef.current?.onRemoveFile(e, key, file);
  }

  onError(error: any) {
    this.setState({ error });
  }

  static isFilesEmpty(files: any) {
    return !files || Object.entries(files).filter(([key, file]) => file !== null).length === 0;
  }

  render() {
    const {
      label,
      accept,
      allowedMimeTypes,
      maxFileSize,
      errorMimeType,
      errorFileSize,
      allowedMimeTypesLabel,
      disabled
    } = this.props;
    const { files, error, dragOver } = this.state;

    const fileEntries = Object.entries(files).filter(([key, file]) => file !== null);

    return (
      <Box
        className={
          "klayo-upload" +
          (error ? " klayo-upload--error" : "") +
          (dragOver ? " klayo-upload--dragover" : "") +
          (disabled ? " klayo-upload--disabled" : "")
        }
      >
        {label && <div className='klayo-upload_label'>{label}</div>}
        <Box
          className='klayo-upload_dropzone'
          onDragOver={this.onDragOver.bind(this)}
          onDragEnter={this.onDragEnter.bind(this)}
          onDragLeave={this.onDragLeave.bind(this)}
          onDrop={this.onDrop.bind(this)}
          onClick={this.onDropZoneClick.bind(this)}
        >
          <Placeholder
            text={{
              primary: (
                <div>
                  <b>{Utils.isTouchDevice() ? "Tap" : "Click"} to upload</b>
                  {this.dragDropSupport ? " or drag and drop here" : ""}
                </div>
              ),
              secondary: allowedMimeTypesLabel
            }}
            primaryTextSize='16px'
            secondaryTextSize='14px'
            spacing={2}
            icon={<DragIcon />}
            sx={{ pointerEvents: "none" }}
          />
        </Box>

        {error && <Box className='klayo-upload_error'>{error}</Box>}

        {!DragDropFileUpload.isFilesEmpty(files) && (
          <Box className='klayo-upload_chipcontainer'>
            {fileEntries.map(([key, file]: any, index: any) => {
              return (
                file && (
                  <Chip
                    key={key}
                    label={file.name}
                    deleteIcon={<RemoveIcon />}
                    onDelete={(e) => this.onRemoveFile(e, key)}
                  />
                )
              );
            })}
          </Box>
        )}

        <MultiFileUpload
          id={this.id}
          ref={this.multiFileUploadRef}
          accept={accept}
          allowedMimeTypes={allowedMimeTypes}
          maxFileSize={maxFileSize}
          errorMimeType={errorMimeType}
          errorFileSize={errorFileSize}
          onChange={this.onChange.bind(this)}
          onError={this.onError.bind(this)}
          files={files}
          sx={{ display: "none" }}
        />
      </Box>
    );
  }
}
