import { Chip, Typography } from "@mui/material";
import React, { Component } from "react";

export class Heading extends Component<any, any> {
  static defaultProps = {
    size: 3
  };

  render() {
    const { size, sx, className, text, chipText } = this.props;

    return (
      <Typography
        variant={("h" + size) as any}
        className={"klayo-viewheading" + (className ? " " + className : "")}
        sx={{
          fontSize: {
            xs: "20px",
            sm: "20px",
            md: "30px"
          },
          ...sx
        }}
      >
        {text}
        {chipText && (
          <Chip
            label={chipText}
            sx={{
              fontSize: {
                xs: "10px",
                sm: "10px",
                md: "12px"
              },
              ml: 1,
              mb: 1
            }}
          />
        )}
      </Typography>
    );
  }
}
