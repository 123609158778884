import React, { Component } from "react";
import { ErrorPage } from "./ErrorPage";

export class ErrorBoundary extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }
  componentDidCatch(error: any, errorInfo: any) {}

  render() {
    const { hasError, error } = this.state;

    if (hasError) return <ErrorPage error={error} />;
    else return this.props.children;
  }
}
