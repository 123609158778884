export class UserAttributeSummary {
  mandatoryTotal: number;
  mandatoryMet: number;
  requiredTotal: number;
  requiredMet: number;
  desirableTotal: number;
  desirableMet: number;

  constructor(data: any) {
    this.mandatoryTotal = data.totalNumberOfMandatoryAttributes;
    this.mandatoryMet = data.numberOfMetMandatoryAttributes;
    this.requiredTotal = data.totalNumberOfRequiredAttributes;
    this.requiredMet = data.numberOfMetRequiredAttributes;
    this.desirableTotal = data.totalNumberOfDesirableAttributes;
    this.desirableMet = data.numberOfMetDesirableAttributes;
  }
}
