import { Feedback_filters } from "../../constants";
import { AbstractAttributeList } from "./AbstractAttributeList";
import { FeedbackAttribute } from "./FeedbackAttribute";

export class FeedbackAttributeList extends AbstractAttributeList {
  static instance: any = null;

  constructor(options: any) {
    super({
      api: options.groupId
        ? "/EmployeeValidationRequest/groupId?groupId=" + options.groupId
        : "/EmployeeValidationRequest",
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.attributes = Object.assign([], this.attributes);
    data.validationRequests.forEach((attr: any, index: any) =>
      this.attributes.push(new FeedbackAttribute(index, attr))
    );
  }

  static get(context: any, updateCache: any, groupId?: any) {
    return new Promise((resolve: any, reject) => {
      if (FeedbackAttributeList.instance && updateCache !== true && !groupId)
        resolve(FeedbackAttributeList.instance, true);
      else
        FeedbackAttributeList.instance = new FeedbackAttributeList({
          resolve,
          reject,
          context,
          groupId
        });
    });
  }

  searchMatch(attribute: any, search: any) {
    const searchLower = search.toLowerCase();
    return (
      attribute.jobName.toLowerCase().indexOf(searchLower) !== -1 ||
      attribute.attribute.toLowerCase().indexOf(searchLower) !== -1 ||
      attribute.employeeFullName.toLowerCase().indexOf(searchLower) !== -1
    );
  }

  filter(search: any, filter: any) {
    let filtered =
      filter.label !== Feedback_filters.all.label
        ? this.attributes.filter((a) => a.stateLabel === filter.label)
        : this.attributes;

    if (search !== null && search.length > 0) {
      filtered = filtered.filter((a) => this.searchMatch(a, search));
    }
    return filtered;
  }
}
