import React, { Component } from "react";
import { AbstractAttribute } from "../../data/attributes/AbstractAttribute";
import { SelectMenu } from "../SelectMenu";

export class AttributeTypeSelector extends Component<any, any> {
  showAllItem: any;

  constructor(props: any) {
    super(props);

    this.showAllItem = { key: "all", label: "Show all" };

    this.state = {
      type: this.props.value
    };
  }

  onChange(e: any) {
    const { onChange } = this.props;

    this.setState({ type: e.target.value }, () => {
      onChange(e);
    });
  }

  getItemLabel(item: any) {
    return item.filterLabel;
  }

  render() {
    const { sx } = this.props;
    const { type } = this.state;

    return (
      <SelectMenu
        value={type}
        label='Competency type'
        placeholder='Select'
        firstValueDefault={true}
        items={Object.entries({ all: this.showAllItem, ...AbstractAttribute.type })}
        onChange={this.onChange.bind(this)}
        sx={sx}
      />
    );
  }
}
