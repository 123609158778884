import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { AppContext } from "../common/AppContext";

export class AbstractView extends Component<any, any> {
  static contextType = AppContext;

  page: any;

  constructor(props: any, page: any) {
    super(props);
    this.page = page;
  }

  onNotificationReceived(notif: any, numUnread: any) {}

  componentDidMount() {
    this.updateTitle();
    if (this.props.user) this.onHasUser(this.props.user);
    if (this.props.org) this.onHasOrganization(this.props.org);
    if (this.props.user && this.props.org) this.onHasUserAndOrg(this.props.user, this.props.org);
  }

  onViewChange(location: any) {}

  pathEquals(path: any) {
    return this.props.location.pathname === path;
  }

  hasQuery(query: any) {
    return this.props.location.search.includes(query);
  }

  updateTitle() {
    document.title =
      this.page.name +
      (this.props.organization ? " - " + this.props.organization.name : "") +
      " - Klayo";
  }

  onHasUser(user: any) {}

  onHasOrganization(organization: any) {}

  onHasUserAndOrg(user: any, organization: any) {}

  componentDidUpdate(prevProps: any, prevState?: any) {
    if (this.props.location !== prevProps.location) this.onViewChange(this.props.location);

    if (this.props.user !== prevProps.user || this.props.organization !== prevProps.organization) {
      if (this.props.user !== prevProps.user) {
        this.onHasUser(this.props.user);
      }

      if (this.props.organization !== prevProps.organization) {
        this.onHasOrganization(this.props.organization);
        this.updateTitle();
      }

      if (this.props.user && this.props.organization)
        this.onHasUserAndOrg(this.props.user, this.props.organization);
    }
  }

  verifyAccess() {
    const { user, organization } = this.props;
    if (this.page.developerOnly && user && !user.isDeveloper) return false;
    return this.page.hasAccess(user, organization);
  }

  renderAccessDenied() {
    return this.props.user ? <Redirect to={{ pathname: "/" }} /> : "";
  }
}
