import { Data } from "../Data";

export class CountrySelectorList extends Data {
  static instance: any = null;
  countries: any;
  attributes: any;

  constructor(options: any) {
    super({
      api: "/Organization/Admin/Billing/SupportedCountries",
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.countries = data.countries;
  }

  static get(context: any, search: any) {
    return new Promise((resolve: any, reject) => {
      if (CountrySelectorList.instance && (search === "" || search === null))
        resolve(CountrySelectorList.instance, true);
      else
        CountrySelectorList.instance = new CountrySelectorList({
          resolve,
          reject,
          search,
          context
        });
    });
  }

  findMatch(attr: any) {
    return this.attributes.find((a: any) => a.name === attr);
  }
}
