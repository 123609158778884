import { Data } from "../Data";
import { BillingInvoice } from "./BillingInvoice";

export class BillingInvoices extends Data {
  static instance: any = null;

  invoices: any;

  constructor(options: any) {
    super({
      api: "/Organization/Invoices",
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });

    this.invoices = [];
  }

  onDataReceived(data: any) {
    data.invoices.forEach((i: any) => {
      const invoice = new BillingInvoice(i);
      this.invoices.push(invoice);
    });
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve, reject) => {
      if (BillingInvoices.instance && updateCache !== true)
        (resolve as any)(BillingInvoices.instance, true);
      else BillingInvoices.instance = new BillingInvoices({ resolve, reject, context });
    });
  }
}
