import { Data } from "../Data";

export class OrganizationSettings extends Data {
  static instance: any = null;
  locations: any;
  organizationId: any;
  name: any;
  organizationIntegrations: any;
  showProficiency: any;
  address: any;
  groupLevelTypes: any;
  groupNotificationTypes: any;

  constructor(options: any) {
    super({
      api: "/Organization/Admin",
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });

    this.locations = [];
  }

  onDataReceived(data: any) {
    this.organizationId = data.organization.organizationId;
    this.name = data.organization.name;
    this.organizationIntegrations = data.organization.organizationIntegrations;
    this.showProficiency = data.organization.showProficiency;
    this.address = data.organization.address;
    this.groupLevelTypes = data.organization.levelTypes.map((l: any) => {
      return { id: l.groupManagerLevelTypeId, label: l.name };
    });
    this.groupNotificationTypes = data.organization.notificationTypes.map((l: any) => {
      return { id: l.groupManagerNotificationTypeId, label: l.name };
    });

    data.organization.locations.forEach((l: any) => this.locations.push(l));
  }

  getLocation(id: any) {
    return this.locations.find((l: any) => l.locationId === id);
  }

  updateLocation(location: any) {
    const locations = [...this.locations];
    const index = locations.indexOf(this.getLocation(location.locationId));
    if (index !== -1) locations.splice(index, 1, location);
    this.locations = [...locations];
  }

  addLocation(location: any) {
    this.locations = [...this.locations];
    this.locations.push(location);
  }

  deleteLocation(location: any) {
    const locations = [...this.locations];
    const index = locations.indexOf(location);
    if (index !== -1) locations.splice(index, 1);
    this.locations = [...locations];
  }

  deleteAddress() {
    this.address = null;
  }

  addAddress(address: any) {
    this.address = address;
  }

  static get(context: any) {
    return new Promise((resolve: any, reject) => {
      if (OrganizationSettings.instance) resolve(OrganizationSettings.instance, true);
      else OrganizationSettings.instance = new OrganizationSettings({ resolve, reject, context });
    });
  }
}
