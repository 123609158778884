import { Data } from "../Data";
import { Group } from "./Group";

export class GroupsList extends Data {
  static instance: any = null;
  groups: any;

  constructor(options: any) {
    super({
      api: "/MyGroup",
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  get(id: any) {
    return this.groups.find((g: any) => g.groupId === id);
  }

  onDataReceived(data: any) {
    this.groups = [];

    data.groups.forEach((g: any) => {
      this.groups.push(new Group(g));
    });
  }

  static get(context: any) {
    return new Promise((resolve, reject) => {
      if (GroupsList.instance) (resolve as any)(GroupsList.instance, true);
      else GroupsList.instance = new GroupsList({ resolve, reject, context });
    });
  }
}
