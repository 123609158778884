import { Abstract_Attribute } from "../../constants";
import { AbstractAttributeList } from "./AbstractAttributeList";
import { EmployeeAttribute } from "./EmployeeAttribute";

export class EmployeeAttributeList extends AbstractAttributeList {
  constructor(options: any) {
    super(options);

    options.data.forEach((attr: any, index: any) =>
      this.attributes.push(new EmployeeAttribute(index, attr))
    );
    this.attributes.sort(
      (a, b) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime()
    );
    this.initAttributesLists();
  }

  initAttributesLists() {
    this.attributes.forEach((a) => {
      if (a.isParent) {
        a.childAttributes.forEach((c: any) => {
          let approved = 0;
          const attrDef = this.getByDefinition(c.attributeDefinitionId);
          if (attrDef) {
            c.categories = attrDef.categories;
            c.lastUpdate = attrDef.lastUpdate;
            c.status = attrDef.status;
            c.statusLabel = attrDef.statusLabel;
            c.proficiency = attrDef.proficiency;
            c.expiryDate = attrDef.expiryDate;
            c.currentExpiryDate = attrDef.currentExpiryDate;
            c.requestedExpiryDate = attrDef.requestedExpiryDate;
            c.currentProficiency = attrDef.currentProficiency;
            c.requestedProficiency = attrDef.requestedProficiency;
            c.employeeAttributeId = attrDef.employeeAttributeId;
            c.validationRequestId = attrDef.validationRequestId;
            if (c.status === Abstract_Attribute.status.approved) approved++;
          } else {
            c.isDeleted = true;
            c.statusLabel = "Missing";
          }

          //a.status = approved === a.childAttributes.length ? AbstractAttribute.status.approved : AbstractAttribute.status.review;
          //a.statusLabel = a.status.label;
        });
      }
    });

    super.initAttributesLists();
  }

  static getFilterItems() {
    return {
      all: { ...AbstractAttributeList.allFilter },
      approved: { ...Abstract_Attribute.status.approved },
      feedback: { ...Abstract_Attribute.status.feedback },
      review: { ...Abstract_Attribute.status.review }
    }; //, declined: { ...AbstractAttribute.status.declined } };
  }

  getByDefinition(defId: any) {
    return this.attributes.find((a) => a.attributeDefinitionId === defId);
  }
}
