import { Box, Tooltip as MuiTooltip } from "@mui/material";
import React, { Component } from "react";

export class Tooltip extends Component<any, any> {
  render() {
    const {
      title,
      placement,
      children,
      open,
      componentsProps,
      onClose,
      disableFocusListener,
      disableHoverListener,
      disableTouchListener,
      maxWidth,
      sx
    } = this.props;

    return !title ? (
      <div>{children}</div>
    ) : (
      <MuiTooltip
        title={<Box>{title}</Box>}
        open={open}
        placement={placement}
        arrow={true}
        onClose={onClose}
        disableFocusListener={disableFocusListener}
        disableHoverListener={disableHoverListener}
        disableTouchListener={disableTouchListener}
        sx={{ ...sx, maxWidth: maxWidth }}
        componentsProps={{ tooltip: { sx: { maxWidth: maxWidth, ...sx } }, ...componentsProps }}
      >
        {children as any}
      </MuiTooltip>
    );
  }
}
