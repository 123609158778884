import { Dashboard_mode } from "../../constants";
import { Data } from "../Data";

export class Notification {
  static type = {
    added: {
      index: 0,
      label: "Competency added"
    },
    edited: {
      index: 1,
      label: "Competency edited"
    },
    changeRequest: {
      index: 2,
      label: "Competency change request"
    },
    changeResponse: {
      index: 3,
      label: "Competency change response"
    },
    approved: {
      index: 4,
      label: "Competency approved"
    },
    aboutToExpire: {
      index: 5,
      label: "Competency about to expire"
    },
    demoOrgResetting: {
      index: 6,
      label: "Your demo organization is resetting"
    },
    demoOrgResetDone: {
      index: 7,
      label: "Your demo organization has been reset successfully"
    },
    employeeAddedAsGroupManager: {
      index: 8,
      label: ""
    },
    attributeExpired: {
      index: 9,
      label: "Competency expired"
    },
    participateAcknowedgementRequest: {
      index: 10,
      label: "Pending your confirmation"
    },
    participantAcknowledged: {
      index: 11,
      label: "Confirmed participation"
    }
  };

  static state = {
    unread: {
      index: 0,
      label: "Unread"
    },
    read: {
      index: 1,
      label: "Read"
    },
    dismissed: {
      index: 2,
      label: "Dismissed"
    }
  };

  id: string;
  isNew: boolean;
  notificationId: string;
  dashboardMode: any;
  date: string;
  type: any;
  state: any;
  primaryText: any;
  secondaryText: any;
  practicalId?: string;
  practicalParticipantId?: string;
  employeeAttributeId?: string;
  employeeId?: string;
  groupId?: string;
  alsoInOtherGroupIds?: string[];
  showOnceId: string;

  constructor(data: any, dashboardMode: any) {
    this.id = data.notificationId;

    this.isNew = false;
    this.notificationId = Data.getFromData(data, "notificationId");
    this.id = this.notificationId;
    this.dashboardMode = dashboardMode;
    this.date = Data.getFromData(data, "createDate");
    this.type = Notification.getTypeFromIndex(Data.getFromData(data, "notificationType"));
    this.state = Notification.getStateFromIndex(Data.getFromData(data, "notificationState"));

    //Personal
    if (dashboardMode === Dashboard_mode.personal) {
      if (this.type === Notification.type.participateAcknowedgementRequest) {
        this.primaryText = `Practical: ${Data.getFromData(data, "practicalName")}`;
        this.secondaryText = this.type.label;
        this.practicalId = Data.getFromData(data, "practicalId");
        this.practicalParticipantId = Data.getFromData(data, "practicalParticipantId");
      } else if (this.type === Notification.type.participantAcknowledged) {
        this.primaryText = `Practical: ${Data.getFromData(data, "practicalName")} at ${Data.getFromData(data, "PracticalDateTime")}`;
        const participantName = Data.getFromData(data, "participantFullName");
        this.secondaryText = `${participantName} confirmed participation`;
        this.practicalId = Data.getFromData(data, "practicalId");
      } else {
        this.primaryText = Data.getFromData(data, "attributeDefinitionName");
        this.secondaryText = this.type.label;
        this.employeeAttributeId = Data.getFromData(data, "employeeAttributeId");
      }
    }

    //Team
    else if (dashboardMode === Dashboard_mode.team) {
      this.primaryText =
        Data.getFromData(data, "employeeAttributeValidationRequestEmployeeFirstName") +
        " " +
        Data.getFromData(data, "employeeAttributeValidationRequestEmployeeLastName");
      this.secondaryText = this.type ? this.type.label : "";
      this.employeeId = Data.getFromData(data, "employeeId");
      this.employeeAttributeId = Data.getFromData(data, "EmployeeAttributeId");
    }

    //Groups
    else if (dashboardMode === Dashboard_mode.groups) {
      this.primaryText =
        Data.getFromData(data, "employeeAttributeValidationRequestEmployeeFirstName") +
        " " +
        Data.getFromData(data, "employeeAttributeValidationRequestEmployeeLastName");
      this.secondaryText = this.type ? this.type.label : "";
      this.employeeId = Data.getFromData(data, "employeeId");
      this.employeeAttributeId = Data.getFromData(data, "EmployeeAttributeId");
      this.groupId = Data.getFromData(data, "groupId");
      this.alsoInOtherGroupIds = Data.getFromData(data, "alsoInOtherGroupIds");
    }

    this.showOnceId = this.employeeId + "_" + this.employeeAttributeId + "_" + this.type.index;
  }

  isValid() {
    return (
      this.primaryText &&
      this.primaryText.length > 0 &&
      this.secondaryText &&
      this.secondaryText.length > 0
    );
  }

  isState(state: any) {
    return this.state === state;
  }

  isRead() {
    return this.isState(Notification.state.read);
  }

  setAsRead() {
    return (this.state = Notification.state.read);
  }

  isMode(dashboardMode: any) {
    return this.dashboardMode === dashboardMode;
  }

  static getTypeFromIndex(index: any) {
    return Object.values(Notification.type)[index];
  }

  static getStateFromIndex(index: any) {
    return Object.values(Notification.state)[index];
  }
}
