import { User } from "../users/User";
import { AbstractAttribute } from "./AbstractAttribute";
export default class AttributeSummary extends AbstractAttribute {
  averageProficiency: number;
  totalNumberOfEmployees: number;
  employees: User[];
  gapEmployees: User[];
  metEmployees: User[];

  constructor(index: any, data: any) {
    super(index, data);

    this.id = index;
    this.rowId = index;
    this.attributeName = data.attributeName;
    this.averageProficiency = data.averageProficiency;
    this.totalNumberOfEmployees = data.totalNumberOfEmployees;

    this.employees = [];
    this.gapEmployees = [];
    this.metEmployees = [];

    const employees = [
      ...data.gapEmployees.map((e: any) => ({ ...e, isCompliant: false })),
      ...data.metEmployees.map((e: any) => ({ ...e, isCompliant: true }))
    ];

    employees.forEach((employee: any, index: any) =>
      this.employees.push(new User({ data: { id: index, ...employee } }))
    );

    data.gapEmployees?.forEach((employee: any, index: any) =>
      this.gapEmployees.push(new User({ data: { id: index, ...employee, isCompliant: false } }))
    );
    data.metEmployees?.forEach((employee: any, index: any) =>
      this.metEmployees.push(new User({ data: { id: index, ...employee, isCompliant: true } }))
    );
  }
}
