import { Data } from "../Data";
import { User } from "../users/User";

export class SettingsManagerList extends Data {
  static instance: any = null;
  employees: any;

  constructor(options: any) {
    super({
      api: "/Employee/SearchManager?searchText=",
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.employees = [];
    data.employees.forEach((e: any, index: any) => {
      const manager: any = new User({ data: { id: index, ...e } });
      manager.jobId = e.jobId;
      manager.fullName = e.employeeFullName;
      if (manager.fullName === null) manager.fullName = "Vacancy";
      manager.jobTitle = e.jobDefinitionName;
      manager.reportsToEmployeeFullname = e.reportsToEmployeeFullname;
      manager.directReports = e.directReports;
      this.employees.push(manager);
    });
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve: any, reject) => {
      if (SettingsManagerList.instance && updateCache !== true)
        resolve(SettingsManagerList.instance, true);
      else SettingsManagerList.instance = new SettingsManagerList({ resolve, reject, context });
    });
  }
}
