import styled from "@emotion/styled";
import { ListItem, Tooltip } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { Component } from "react";
import { KLAYO_COLORS } from "../../themes";
import IntegrationAbbreviation from "../../views/settings/IntegrationAbbreviation";

export class MultiSelectorContent extends Component<any, any> {
  hoveredOptionRef: any;

  constructor(props: any) {
    super(props);

    this.state = {
      isOptionTooltipVisible: false
    };

    this.hoveredOptionRef = React.createRef();
  }

  handleOptionMouseEnter = (optionElement: any) => {
    const { selectorContainerSize } = this.props;

    // Get the size of the option content
    const optionSize = optionElement?.current?.getBoundingClientRect();

    // Check if the option is too large
    const isOptionTooLarge = optionSize.width > selectorContainerSize.width;

    if (isOptionTooLarge) {
      // Set the state to show the tooltip
      this.setState({ isOptionTooltipVisible: true });
    }
  };

  handleOptionMouseLeave = () => {
    // Hide the tooltip when the mouse leaves the option
    this.setState({ isOptionTooltipVisible: false });
  };

  render() {
    const { organization, option, inputValue, props, getItemId } = this.props;
    const { getItemLabel } = this.props;
    const { isOptionTooltipVisible } = this.state;

    const name = getItemLabel(option);
    const matches = match(name, inputValue, { insideWords: true });
    const parts = parse(name, matches);

    const renderTitle =
      parts.length === 1 ? (
        <>
          {name}{" "}
          {organization && organization.hasMultipleIntegrations && (
            <IntegrationAbbreviation
              data={option ? option : null}
              type={IntegrationAbbreviation.dataType.integrationUser.value}
            />
          )}
        </>
      ) : (
        parts.map((part, index) => (
          <span
            key={Math.random().toString(36).substring(7)}
            className={part.highlight === true ? " klayo-selector_highlight" : ""}
          >
            {part.text}
            {index === parts.length - 1 && organization && organization.hasMultipleIntegrations && (
              <IntegrationAbbreviation
                data={option ? option : null}
                type={IntegrationAbbreviation.dataType.integrationUser.value}
              />
            )}
          </span>
        ))
      );

    return (
      <Tooltip
        PopperProps={{
          style: { maxWidth: "none" }
        }}
        title={renderTitle}
        placement='top'
        open={isOptionTooltipVisible}
        arrow
      >
        <ListItemStyled
          {...props}
          key={String(getItemId(option))}
          onMouseEnter={(e) => this.handleOptionMouseEnter(this.hoveredOptionRef)}
          onMouseLeave={this.handleOptionMouseLeave.bind(this)}
        >
          {" "}
          <p ref={this.hoveredOptionRef}>{renderTitle}</p>{" "}
        </ListItemStyled>
      </Tooltip>
    );
  }
}

const ListItemStyled = styled(ListItem)`
  :hover {
    .klayo_integration-abbreviation {
      color: ${KLAYO_COLORS.WhiteWH900};
    }
  }
`;
