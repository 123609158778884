import React, { Component } from "react";
import { AppContext } from "../common/AppContext";
import { LoadingModel } from "../common/models/LoadingModel";
import { LoadingBar } from "./LoadingBar";

export class AbstractDialog extends Component<any, any> {
  static contextType = AppContext;

  loadingModel: LoadingModel;
  loadingBarRef: React.RefObject<LoadingBar>;
  loadingBar!: any;

  constructor(props: any) {
    super(props);

    this.state = {
      open: this.props.open ? this.props.open : false,
      loadingModel: new LoadingModel()
    };

    this.loadingModel = new LoadingModel();
    this.loadingBarRef = React.createRef();
  }

  setLoading(event: any, loading: any) {
    this.loadingModel.setLoading(event, loading);
    if (this.loadingBar) this.loadingBar.current.setLoading(this.loadingModel.isLoading);
  }

  renderLoadingBar() {
    return <LoadingBar ref={this.loadingBarRef} />;
  }

  open(open: any) {
    this.setState({ open: open !== undefined ? open : true });
  }

  close() {
    this.setState({ open: false });
  }
}
