import styled from "@emotion/styled";
import { Menu } from "@mui/material";
import React, { Component } from "react";
import { AppContext } from "../common/AppContext";
import { KLAYO_COLORS } from "../themes";

export class AttributeActionMenu extends Component<any, any> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);

    this.state = {
      open: false,
      actionTarget: null
    };
  }

  render() {
    const { onClose, onRender } = this.props;
    const { open, actionTarget } = this.state;

    return (
      <StyledMenu
        open={open}
        anchorEl={actionTarget}
        onClick={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={onClose}
        isOpen={open}
      >
        {onRender()}
      </StyledMenu>
    );
  }

  open(actionTarget: any) {
    this.setState({ open: true, actionTarget });
  }

  close() {
    this.setState({ open: false });
  }
}

const StyledMenu = styled<any>(Menu)`
  .MuiMenu-paper {
    padding: ${(props) => (!props.isOpen ? "0px" : "15px 0 16px")};
    border: ${(props) =>
      !props.isOpen ? "none !important" : `solid 1px ${KLAYO_COLORS.NeonBlueNB200}`};
    background-color: ${(props) =>
      !props.isOpen ? "transparent !important" : `${KLAYO_COLORS.WhiteWH900}`};
  }
`;
