import { AppContext } from "../../common/AppContext";
import { JobSelectorList } from "../../data/filters/JobSelectorList";
import { AbstractFilterSelect } from "./AbstractFilterSelect";

export class JobSelector extends AbstractFilterSelect {
  static contextType = AppContext;

  static defaultProps = {
    ...AbstractFilterSelect.defaultProps,
    label: "Job",
    listType: "Jobs"
  };

  constructor(props: any) {
    super(props);

    this.dataList = JobSelectorList;
  }

  getItemId(item: any) {
    return item.jobDefinitionId;
  }

  getItemLabel(item: any) {
    const { getItemLabel } = this.props;

    if (getItemLabel) return getItemLabel(item);
    return item;
  }

  getList(dataList: any) {
    return dataList.jobs;
  }
}
