import { Stack } from "@mui/material";
import { AbstractDialog } from "../../components/AbstractDialog";
import { Alert } from "../../components/Alert";
import { Dialog } from "../../components/dialogs/Dialog";
import { EmployeeTable } from "../../components/table/EmployeeTable";
import { unFocusTableRowOnClose } from "../../utilities";
import React from "react";

export class RegulationPeopleListDialog extends AbstractDialog {
  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state,
      regulation: this.props.regulation,
      isGap: false,
      type: ""
    };
  }

  componentDidMount() {
    this.setLoading("regulation", !this.props.regulation);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.regulation !== prevProps.regulation) this.setLoading("regulation", false);
  }

  open(regulation: any, isGap?: boolean, type?: "regulation" | "competency") {
    this.setState({ open: true, regulation, isGap, type });
  }

  onClose() {
    const { onClose } = this.props;

    if (onClose) onClose();
    unFocusTableRowOnClose();
  }

  getTaskDescription() {
    const { isGap, type } = this.state;

    switch (type) {
      case "regulation":
        return isGap ? (
          <p>The following employees are not compliant with the regulation:</p>
        ) : (
          <p>The following employees are in jobs linked to the regulation:</p>
        );
      case "task":
        return isGap ? (
          <p>The following employees are not compliant with the task:</p>
        ) : (
          <p>The following employees have been assigned the task:</p>
        );

      default:
        return isGap ? (
          <p>The following employees are missing the competency:</p>
        ) : (
          <p>The following employees should have the competency:</p>
        );
    }
  }

  render() {
    const { onEmployeeSelect, theme } = this.props;
    const { open, regulation, isGap } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.onClose.bind(this)}
        theme={theme}
        fullWidth={true}
        title={isGap ? "Gap details" : "People"}
        maxWidth='sm'
        actions={[
          {
            label: "Close",
            variant: "filled",
            onClick: this.onClose.bind(this)
          }
        ]}
      >
        {super.renderLoadingBar()}
        <Stack direction='column' spacing={4}>
          <div style={{ fontSize: "16px" }}>
            <Alert showIcon={false} severity='info'>
              <b>{regulation?.name}</b>
            </Alert>
            {this.getTaskDescription.bind(this)()}
          </div>
          <EmployeeTable
            rows={(isGap ? regulation?.gapEmployees : regulation?.employees) || []}
            pageSize={50}
            paper={false}
            theme={theme}
            columns={[
              { type: EmployeeTable.columns.profilePicFullName, showRole: false },
              ...(!isGap
                ? [
                    {
                      type: EmployeeTable.columns.isCompliant,
                      visibleIcon: true,
                      align: "center",
                      headerAlign: "center",
                      visibleHeader: false,
                      sortable: false,
                      width: 100
                    }
                  ]
                : [])
            ]}
            pagination={true}
            rowsPerPageOptions={[50]}
            disableMultipleSelection={true}
            disableSelectionOnClick={true}
            onEmployeeSelect={onEmployeeSelect}
            className='klaro_attrppl_table'
            sx={{ "& .MuiDataGrid-row": { cursor: "pointer" } }}
            border='none'
            style={{
              marginTop: 0
            }}
          />
        </Stack>
      </Dialog>
    );
  }
}
