import React, { Component } from "react";
import { Dialog } from "../../components/dialogs/Dialog";
import { LoadingBar } from "../../components/LoadingBar";
import { TextField } from "../../components/TextField";

export class AddEditLocationDialog extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      locationId: props.location ? props.location.locationId : null,
      locationName: props.location ? props.location.name : "",
      valid: this.isValid(props.location ? props.location.name : ""),
      saving: false
    };
  }

  onChange(e: any) {
    this.setState({ locationName: e.target.value, valid: this.isValid(e.target.value) });
  }

  isValid(locationName: any) {
    return locationName != null && locationName.length > 2;
  }

  onSave() {
    const { locationId, locationName } = this.state;
    if (this.props.onSave) this.props.onSave(locationId, locationName);
  }

  render() {
    const { theme, onClose, saving, error } = this.props;
    const { locationId, locationName, valid } = this.state;

    return (
      <Dialog
        open={true}
        theme={theme}
        title={locationId ? "Edit location" : "New location"}
        onClose={onClose}
        fullWidth={true}
        maxWidth='sm'
        actions={[
          {
            label: locationId ? "Save" : "Add location",
            primary: true,
            disabled: !valid,
            onClick: this.onSave.bind(this)
          },
          {
            label: "Cancel",
            onClick: onClose
          }
        ]}
      >
        {saving && <LoadingBar loading={true} />}
        <TextField
          label='Location'
          placeholder='Location'
          autoComplete={false}
          autoFocus={true}
          sx={{ width: "100%" }}
          error={error !== null}
          helperText={error}
          value={locationName}
          onChange={this.onChange.bind(this)}
        />
      </Dialog>
    );
  }
}
