import React, { Component } from "react";
import { MultiSelector } from "./MultiSelector";

export class MultiParticipantSelector extends Component<any, any> {
  static defaultProps = {
    label: ""
  };

  constructor(props: any) {
    super(props);

    this.state = {};
  }

  render() {
    return <MultiSelector {...this.props} hasNewItem={false} />;
  }
}
