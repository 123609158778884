import { Stack } from "@mui/material";
import React, { Component } from "react";
import { PillButtons } from "../../components/PillButtons";
import { BillingPlan } from "../../data/billing/BillingPlan";

export class InvoicePayment extends Component<any, any> {
  static defaultProps = {
    disabled: false,
    visible: false
  };

  render() {
    const { billingCycle, onBillingCycleChange, visible } = this.props;

    return (
      <div style={{ display: visible === true ? "block" : "none" }}>
        <Stack spacing={5}>
          <div>
            Please fill and submit the form below. We’ll send you an invoice and payment
            instructions shortly.
          </div>

          {(billingCycle === BillingPlan.cycle.quarterly ||
            billingCycle === BillingPlan.cycle.annually) && (
            <PillButtons
              label='Billing cycle'
              value={billingCycle}
              items={[BillingPlan.cycle.quarterly, BillingPlan.cycle.annually]}
              onChange={onBillingCycleChange}
              sx={{ alignSelf: "flex-start" }}
            />
          )}
        </Stack>
      </div>
    );
  }
}
