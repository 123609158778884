import { Data } from "../Data";
import { SettingsRegulationRegion } from "./SettingRegulationRegion";

export class SettingsRegulationRegionList extends Data {
  static instance: any = null;
  regions: any;

  constructor(options: any) {
    const { apiUrl, ...otherOptions } = options;

    const apiOptions = {
      api: apiUrl,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    super(apiOptions);
    this.regions = [];
  }

  onDataReceived(data: any) {
    this.regions = [];
    data.regions.forEach((g: any, index: any) =>
      this.regions.push(new SettingsRegulationRegion(g))
    );
  }

  static onGetData(context: any, options: any) {
    const { apiUrl } = options;
    return new Promise((resolve, reject) => {
      SettingsRegulationRegionList.instance = new SettingsRegulationRegionList({
        apiUrl,
        resolve,
        reject,
        context
      });
    });
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve: any, reject) => {
      if (SettingsRegulationRegionList.instance && updateCache !== true)
        resolve(SettingsRegulationRegionList.instance, true);
      else
        SettingsRegulationRegionList.instance = new SettingsRegulationRegionList({
          resolve,
          reject,
          context
        });
    });
  }
}
