import styled from "@emotion/styled";
import { ChipProps, Chip as MuiChip } from "@mui/material";

type Props = ChipProps & {
  textColor?: string;
  backgroundColor?: string;
};

export const Chip = ({ textColor, backgroundColor, ...rest }: Props) => {
  return <ChipStyle backgroundColor={backgroundColor} textColor={textColor} {...rest} />;
};

const ChipStyle = styled(MuiChip)<{
  backgroundColor?: string;
  textColor?: string;
}>`
  background-color: ${(props) => props.backgroundColor || "#e9e9fe"} !important;
  border: none !important;
  height: 24px !important;

  & .MuiChip-label {
    color: ${(props) => props.textColor || "#1b3df2"} !important;
    font-weight: 500 !important;
    padding: 2px 12px !important;
  }
`;
