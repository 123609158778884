import { Tab, Tabs } from "@mui/material";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "./Tooltip";

export class PillButtons extends Component<any, any> {
  static defaultProps = {};

  render() {
    const {
      user,
      organization,
      sx,
      className,
      path,
      value,
      items,
      pages,
      basePath,
      params,
      disabled,
      label,
      onChange
    } = this.props;

    return (
      <div className={"klayo-pillbuttons " + className}>
        {label && <div className='klayo-pillbuttons_label'>{label}</div>}
        <Tabs className='klayo-pillbuttons_tabs' value={path || value} onChange={onChange} sx={sx}>
          {Object.entries(pages || items).map(([key, page]: any) => {
            return (
              <Tab
                key={key}
                value={
                  page.slug !== undefined
                    ? basePath + (page.slug !== "" ? "/" + page.slug : "")
                    : (page.value ?? page)
                }
                exact={true}
                to={
                  page.slug !== undefined
                    ? page.slug === ""
                      ? basePath + (params ? "?" + params : "")
                      : basePath + "/" + page.slug + (params ? "?" + params : "")
                    : "#"
                }
                activeClassName='active'
                disableRipple={true}
                disabled={
                  disabled
                    ? disabled
                    : user && organization && page.hasAccess && page.hasAccess(user, organization)
                }
                label={
                  page.tooltip ? (
                    <Tooltip title={page.tooltip || null}>
                      <div>{page.name || page.label}</div>
                    </Tooltip>
                  ) : (
                    <div>{page.name || page.label}</div>
                  )
                }
                component={NavLink}
              />
            );
          })}
        </Tabs>
      </div>
    );
  }
}
