import { Badge, Box, Input, InputLabel, Skeleton, Stack } from "@mui/material";
import { Component } from "react";
import { ReactComponent as EditIcon } from "../resources/images/icons-edit.svg";
import { Profile } from "../views/profile/Profile";
import { Button } from "./Button";
import { Paper } from "./Paper";
import { ProfilePicture } from "./ProfilePicture";

export class UserProfileBox extends Component<any, any> {
  render() {
    const {
      user,
      profile,
      paperSx,
      onViewDetails,
      allowEditProfilePicture,
      onProfilePictureChange,
      readOnly,
      profilePictureReset,
      profilePictureUpdating,
      showViewDetailsButton
    } = this.props;

    return (
      <Paper className='klayo-userprofilebox' sx={paperSx} overflow='unset'>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent='space-between'
          alignItems='center'
          width='100%'
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent='flex-start'
            alignItems={{ xs: "center", md: "flex-start" }}
            spacing={2}
          >
            <div>
              {allowEditProfilePicture ? (
                <div>
                  <Input
                    id='klayo_profilepic-file'
                    inputProps={{
                      accept: Profile.allowedFileTypes.join(", ")
                    }}
                    type='file'
                    value=''
                    onChange={onProfilePictureChange}
                    style={{ display: "none" }}
                  />
                  <Badge
                    className='klayo_profilepic-container'
                    overlap='circular'
                    sx={{ margin: "0" }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      readOnly ? (
                        <div></div>
                      ) : (
                        <InputLabel htmlFor='klayo_profilepic-file' sx={{ cursor: "pointer" }}>
                          <Button
                            className='klayo_profilepic-edit'
                            aria-label='Change profile picture'
                            icon={<EditIcon />}
                            htmlFor='klayo_profilepic-file'
                            onClick={(e: any) => {}}
                            tooltip='Choose profile picture'
                            sx={{ pointerEvents: "none" }}
                          />
                        </InputLabel>
                      )
                    }
                  >
                    <ProfilePicture
                      size='xl'
                      user={user}
                      updateCache={profilePictureReset}
                      updating={profilePictureUpdating}
                    />
                  </Badge>
                </div>
              ) : (
                <ProfilePicture
                  size='xl'
                  user={user}
                  updateCache={profilePictureReset}
                  updating={profilePictureUpdating}
                />
              )}
            </div>

            <Stack
              direction='column'
              justifyContent='flex-start'
              alignItems={{ xs: "center", md: "flex-start" }}
              spacing={0}
            >
              <Box
                className='klayo-userprofilebox_name'
                sx={{
                  fontSize: { xs: "20px", md: "24px" },
                  height: { xs: "1.4", md: "1.33" }
                }}
              >
                {user ? user.fullName : <Skeleton variant='rectangular' width={210} height={20} />}
              </Box>

              <Box
                className='klayo-userprofilebox_title'
                sx={{
                  fontSize: { xs: "16px", md: "16px" }
                }}
              >
                {profile ? profile.jobTitle : null}
              </Box>

              <div className='klayo-userprofilebox_department'>
                {profile ? profile.department : null}
              </div>
            </Stack>
          </Stack>
          {showViewDetailsButton ? (
            <Button
              label='View details'
              size='md'
              onClick={onViewDetails}
              sx={{
                marginTop: { xs: "48px", md: "0" }
              }}
            />
          ) : null}
        </Stack>
      </Paper>
    );
  }
}
