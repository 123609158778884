import styled from "@emotion/styled";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { TASK_ATTRIBUTE_DIALOG_TYPE } from "../../constants";
import { ReactComponent as DeleteIcon } from "../../resources/images/icons-delete-blue.svg";
import { ReactComponent as MoreVertIcon } from "../../resources/images/icons-more-vertical.svg";
import { KLAYO_COLORS } from "../../themes";
import { Table } from "./Table";

export class TasksTable extends Table {
  static columns = {
    name: {
      field: "name",
      label: "Task"
    },
    competencies: {
      field: "numberOfCompetencies",
      label: "Competencies"
    },
    regulations: {
      field: "numberOfRegulations",
      label: "Regulations"
    },
    jobs: {
      field: "numberOfJobDefinitions",
      label: "Jobs"
    },
    actions: {
      field: "actions",
      label: "Actions"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    columns: [
      { type: TasksTable.columns.name },
      { type: TasksTable.columns.competencies },
      { type: TasksTable.columns.regulations },
      { type: TasksTable.columns.jobs },
      { type: TasksTable.columns.actions }
    ],
    noRowsMessage: "No rows, yet",
    showProficiency: false,
    rowHasAction: null
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state,
      sortModel: this.props.sortModel
        ? this.props.sortModel
        : [
            {
              field: "name",
              sort: "asc"
            }
          ]
    };
  }

  handleOnOpenTaskAttributesDialog = (value: any, type: any) => {
    const { onOpenTaskAttributesDialog } = this.props;
    onOpenTaskAttributesDialog(value, type);
  };

  initColumns() {
    const { columns, sortable, onTaskAction, onPositionAction, isUsingDeleteIcon } = this.props;

    this.columns = [];

    columns.forEach((c: any) => {
      if (this.hasColumn(c)) {
        if (c.type === TasksTable.columns.name) {
          this.columns.push({
            field: "name",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            flex: 1,
            valueGetter: (params: any) => params.row.name
          });
        }

        if (c.type === TasksTable.columns.competencies) {
          this.columns.push({
            field: "numberOfCompetencies",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            width: 120,
            headerAlign: "right",
            align: "right",
            valueGetter: (params: any) => params.row.numberOfCompetencies,
            cellClassName: "Klayo_associated-attribute-cell",
            renderCell: (params: any) => (
              <KlayoTag
                isTag={params.row.numberOfCompetencies !== 0}
                onClick={(event: any) => {
                  event.stopPropagation();
                  params.row.numberOfCompetencies !== 0 &&
                    this.handleOnOpenTaskAttributesDialog(
                      params,
                      TASK_ATTRIBUTE_DIALOG_TYPE.COMPETENCIES
                    );
                }}
              >
                {params.row.numberOfCompetencies}
              </KlayoTag>
            )
          });
        }

        if (c.type === TasksTable.columns.regulations) {
          this.columns.push({
            field: "numberOfRegulations",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            width: 120,
            headerAlign: "right",
            align: "right",
            valueGetter: (params: any) => params.row.numberOfRegulations,
            cellClassName: "Klayo_associated-attribute-cell",
            renderCell: (params: any) => (
              <KlayoTag
                isTag={params.row.numberOfRegulations !== 0}
                onClick={(event: any) => {
                  event.stopPropagation();
                  params.row.numberOfRegulations !== 0 &&
                    this.handleOnOpenTaskAttributesDialog(
                      params,
                      TASK_ATTRIBUTE_DIALOG_TYPE.REGULATIONS
                    );
                }}
              >
                {params.row.numberOfRegulations}
              </KlayoTag>
            )
          });
        }

        if (c.type === TasksTable.columns.jobs) {
          this.columns.push({
            field: "numberOfJobDefinitions",
            headerName: c.label || c.type.label,
            sortable: sortable,
            filterable: false,
            disableReorder: true,
            width: 120,
            headerAlign: "right",
            align: "right",
            valueGetter: (params: any) => params.row.numberOfJobDefinitions,
            cellClassName: "Klayo_associated-attribute-cell",
            renderCell: (params: any) => (
              <KlayoTag
                isTag={params.row.numberOfJobDefinitions !== 0}
                onClick={(event: any) => {
                  event.stopPropagation();
                  params.row.numberOfJobDefinitions !== 0 &&
                    this.handleOnOpenTaskAttributesDialog(params, TASK_ATTRIBUTE_DIALOG_TYPE.JOBS);
                }}
              >
                {params.row.numberOfJobDefinitions}
              </KlayoTag>
            )
          });
        }

        if (c.type === TasksTable.columns.actions) {
          this.columns.push({
            field: "actions",
            type: "actions",
            filterable: false,
            disableReorder: true,
            align: "right",
            valueGetter: (params: any) => (params.row.deleted ? null : params),
            getActions: (params: any) =>
              params.row.deleted
                ? []
                : [
                    <GridActionsCellItem
                      icon={isUsingDeleteIcon ? <DeleteIcon /> : <MoreVertIcon />}
                      label='Actions'
                      onClick={(e) =>
                        params.row.isTask
                          ? onTaskAction(e, params.row)
                          : onPositionAction(e, params.row)
                      }
                      key={Math.random().toString(36).substring(7)}
                    />
                  ]
          });
        }
      }
    });
  }

  onRowClick = (row: any) => {
    const { onRowClick } = this.props;
    onRowClick(row);
  };
}

const KlayoTag = styled.div<any>`
  padding: 2px 12px;
  background-color: ${(props) =>
    props.isTag ? KLAYO_COLORS.NeonBlueNB050 : KLAYO_COLORS.transparent};
  border-radius: 100px;

  color: ${(props) => (props.isTag ? KLAYO_COLORS.NeonBlueNB500 : KLAYO_COLORS.GreyGR900)};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  :hover {
    filter: ${(props) => (props.isTag ? "brightness(95%)" : "")};
    cursor: ${(props) => (props.isTag ? "pointer" : "")};
  }

  :focus {
    background-color: ${(props) => (props.isTag ? "#c6c7fc" : "")};
  }
`;
