import { Stack } from "@mui/material";
import React, { Component } from "react";
import { Button } from "./Button";

export class ConfirmationBox extends Component<any, any> {
  render() {
    const { icon, heading, message, action, onAction } = this.props;

    return (
      <div className='klayo-confirmationbox'>
        <Stack spacing={4}>
          <div className='klayo-confirmationbox_icon'>{icon}</div>
          <div className='klayo-confirmationbox_heading'> {heading} </div>
          <div className='klayo-confirmationbox_message'> {message} </div>
          {action && (
            <div className='klayo-confirmationbox_action'>
              <Button label={action} onClick={onAction} variant='filled' />
            </div>
          )}
        </Stack>
      </div>
    );
  }
}
