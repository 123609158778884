import { Data } from "../Data";
import { SettingsEmployee } from "./SettingsEmployee";

export class SettingsEmployeeList extends Data {
  static instance: any = null;
  static defaultPageSize = 50;

  employees: any;
  currentPage: any;
  totalPages: any;
  totalCount: any;
  hasNext: any;
  hasPrevious: any;

  numberOfActiveAdminEmployees: number;
  numberOfActiveBillingEmployees: number;

  constructor(options: any) {
    const { searchText, pageNumber, pageSize, orderBy, status, ...otherOptions } = options;

    const apiOptions = {
      api: `/Employee/List`,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    // add params for api if provided
    const queryParams = [];
    if (searchText) queryParams.push(`searchText=${searchText}`);
    if (pageNumber) queryParams.push(`pageNumber=${pageNumber}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize || SettingsEmployeeList.defaultPageSize}`);
    if (orderBy) queryParams.push(`orderBy=${orderBy}`);
    if (status || status === 0) queryParams.push(`status=${status}`);

    if (queryParams.length > 0) {
      apiOptions.api += `?${queryParams.join("&")}`;
    }

    super(apiOptions);

    this.employees = [];
    this.currentPage = 1;
    this.totalPages = null;
    this.totalCount = null;
    this.hasNext = false;
    this.hasPrevious = false;
    this.numberOfActiveAdminEmployees = 0;
    this.numberOfActiveBillingEmployees = 0;
  }

  onDataReceived(data: any) {
    // reformat data from api then assign to state
    const { currentPage, totalPages, totalCount, hasNext, hasPrevious } = data.employees;

    this.numberOfActiveAdminEmployees = data.numberOfActiveAdminEmployees;
    this.numberOfActiveBillingEmployees = data.numberOfActiveBillingEmployees;

    this.currentPage = currentPage;
    this.totalPages = totalPages;
    this.totalCount = totalCount;
    this.hasNext = hasNext;
    this.hasPrevious = hasPrevious;

    data.employees.data.forEach((emp: any, index: any) =>
      this.employees.push(new SettingsEmployee(emp))
    );
  }

  get(id: any) {
    return this.employees.find((l: any) => l.id === id || l.employeeId === id);
  }

  add(employee: any) {
    this.employees = [...this.employees];
    this.employees.push(employee);
  }

  update(employee: any) {
    const employees = [...this.employees];
    const index = employees.indexOf(this.get(employee.employeeId));
    if (index !== -1) employees.splice(index, 1, employee);
    this.employees = [...employees];
  }

  canDeactivate(employee: any) {
    if (
      employee.isOrganizationAdmin &&
      this.numberOfActiveAdminEmployees <= 1 &&
      (employee.isActive() || employee.isInvited())
    )
      return false;
    if (
      employee.isBilling &&
      this.numberOfActiveBillingEmployees <= 1 &&
      (employee.isActive() || employee.isInvited())
    )
      return false;
    return true;
  }

  canUnassignAdmin(employee: any) {
    if (!employee.isOrganizationAdmin) return false;
    if (this.numberOfActiveAdminEmployees <= 1 && (employee.isActive() || employee.isInvited()))
      return false;
    return true;
  }

  canUnassignBilling(employee: any) {
    if (!employee.isBilling) return false;
    if (this.numberOfActiveBillingEmployees <= 1 && (employee.isActive() || employee.isInvited()))
      return false;
    return true;
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve: any, reject) => {
      if (SettingsEmployeeList.instance && updateCache !== true)
        resolve(SettingsEmployeeList.instance, true);
      else SettingsEmployeeList.instance = new SettingsEmployeeList({ resolve, reject, context });
    });
  }

  static getWithPaging(context: any, options: any) {
    const { searchText, pageNumber, pageSize, orderBy, status } = options;

    return new Promise((resolve, reject) => {
      SettingsEmployeeList.instance = new SettingsEmployeeList({
        searchText,
        pageNumber,
        pageSize,
        orderBy,
        status,
        resolve,
        reject,
        context
      });
    });
  }
}
