import { GridActionsCellItem } from "@mui/x-data-grid";
import { PRACTICAL_STATUS } from "../../constants";
import { ReactComponent as DeleteIcon } from "../../resources/images/icons-delete-blue.svg";
import { ReactComponent as UsersIcon } from "../../resources/images/icons-users-1.svg";
import { KLAYO_COLORS } from "../../themes";
import { Table } from "./Table";

export class ParticipantTable extends Table {
  static columns = {
    participant: {
      field: "fullName",
      label: "Participant"
    },
    marking: {
      field: "marking",
      label: "Assessment"
    },
    status: {
      field: "status",
      label: "Status"
    },
    action: {
      field: "action",
      label: "Actions"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    columns: [
      { type: ParticipantTable.columns.participant, visibleFromBreakpoint: "sm" },
      { type: ParticipantTable.columns.marking },
      { type: ParticipantTable.columns.status, visibleFromBreakpoint: "sm" },
      { type: ParticipantTable.columns.action }
    ],
    noRowsMessage: "Use Add participants button to select participants for this practical",
    icon: <UsersIcon />
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ...this.state,
      sortModel: this.props.sortModel
        ? this.props.sortModel
        : [
            {
              field: "fullName",
              sort: "asc"
            }
          ]
    };
  }

  onRowClick(params: any, e: any) {
    const { onParticipantSelect } = this.props;
    if (params.row && onParticipantSelect) onParticipantSelect(e, params.row);
  }

  initColumns() {
    const { columns, sortable, onDeleteParticipantAction, isAssessment, practicalStatus } =
      this.props;

    this.columns = [];

    columns.forEach((c: any) => {
      if (this.hasColumn(c)) {
        if (c.type === ParticipantTable.columns.participant)
          this.columns.push({
            field: "fullName",
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            minWidth: 160,
            flex: 1,
            valueGetter: (params: any) => params.row.fullName
          });

        if (c.type === ParticipantTable.columns.marking && isAssessment)
          this.columns.push({
            field: "marking",
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            Width: 180,
            valueGetter: (params: any) => params.row.practical,
            renderCell: (params: any) => (
              <div>
                {
                  params.row?.participantAttributes?.filter(
                    (pa: any) => pa.mark !== undefined && pa.mark !== null
                  ).length
                }{" "}
                of {params.row?.participantAttributes?.length}
              </div>
            )
          });

        if (c.type === ParticipantTable.columns.status)
          this.columns.push({
            field: "status",
            headerName: c.label || c.type.label,
            sortable: sortable,
            visibleFromBreakpoint: c.visibleFromBreakpoint || c.type.visibleFromBreakpoint,
            filterable: false,
            disableReorder: true,
            width: 100,
            headerAlign: "left",
            align: "left",
            valueGetter: (params: any) => params.row.status,
            renderCell: (params: any) => <div>{params.row.status}</div>
          });

        if (c.type === ParticipantTable.columns.action)
          this.columns.push({
            field: "action",
            type: "actions",
            filterable: false,
            disableReorder: true,
            width: 50,
            align: "right",
            valueGetter: (params: any) => params,
            getActions: (params: any) =>
              practicalStatus !== PRACTICAL_STATUS[2].value && params.row.status !== "Completed"
                ? [
                    <GridActionsCellItem
                      icon={<DeleteIcon style={{ color: KLAYO_COLORS.NeonBlueNB500 }} />}
                      label='Actions'
                      onClick={(e) => onDeleteParticipantAction(e, params.row)}
                      key={Math.random().toString(36).substring(7)}
                    />
                  ]
                : []
          });
      }
    });
  }
}
