import { Data } from "../Data";
import { BillingPlan } from "./BillingPlan";

export class BillingPlans extends Data {
  static instance: any = null;
  groups: any;
  enterprisePlan: any;

  constructor(options: any) {
    super({
      api: "/Organization/BillingPlans",
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });

    this.groups = [];
  }

  onDataReceived(data: any) {
    data.billingPlans.forEach((p: any) => {
      const plan = new BillingPlan(p);
      this.groups.push(plan);
    });

    this.enterprisePlan = this.groups.find((p: any) => p.name === "Enterprise");
    this.enterprisePlan.isCustom = true;
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve, reject) => {
      if (BillingPlans.instance && updateCache !== true)
        (resolve as any)(BillingPlans.instance, true);
      else BillingPlans.instance = new BillingPlans({ resolve, reject, context });
    });
  }
}
