import { Data } from "../Data";

export class NotificationsUnread extends Data {
  static instance: any = null;
  count: any;

  constructor(options: any) {
    super({
      api: "/Notifications/TotalUnread",
      callback: (data: any) => {
        options.resolve(this);
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.count = data.totalNumberOfUnreadNotification;
  }

  /*update(inc) {
        this.count += inc;
        if(this.count < 0) this.count = 0;
        if(this.onChange) this.onChange(this.count);
    }*/

  set(count: any) {
    this.count = count;
  }

  static get(context: any) {
    return new Promise((resolve, reject) => {
      if (NotificationsUnread.instance) resolve(NotificationsUnread.instance);
      else NotificationsUnread.instance = new NotificationsUnread({ resolve, reject, context });
    });
  }
}
