import React, { Component } from "react";
import { SelectMenu } from "../SelectMenu";
import { AttributeTableSearchBar } from "./AttributeTableSearchBar";

export class FeedbackTableSearchBar extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      groupId: props.groupId
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.groupId !== this.props.groupId) this.setState({ groupId: this.props.groupId });
  }

  onFilterChange(filter: any) {
    this.props.onFilterChange(filter);
  }

  onSearch(search: any) {
    this.props.onSearch(search);
  }

  onGroupChange(e: any) {
    this.props.onGroupChange(e.target.value);
  }

  render() {
    const {
      user,
      filters,
      theme,
      defaultValue,
      groups,
      sx,
      disabled,
      showSearchFromBreakpoint,
      searchPlaceholder,
      disabledSearch,
      disabledFilter
    } = this.props;
    const { groupId } = this.state;

    const items = [];
    items.push({ label: "My team" });
    if (groups)
      items.push(...Object.values(groups).map((g: any) => ({ id: g.groupId, label: g.name })));

    return (
      <AttributeTableSearchBar
        user={user}
        filters={filters}
        theme={theme}
        disabled={disabled}
        defaultValue={defaultValue}
        showViewModeToggle={false}
        showSearchFromBreakpoint={showSearchFromBreakpoint}
        searchPlaceholder={searchPlaceholder}
        disabledSearch={disabledSearch}
        disabledFilter={disabledFilter}
        onFilterChange={this.onFilterChange.bind(this)}
        onSearch={this.onSearch.bind(this)}
        sx={{ width: "auto", ...sx }}
        otherFilters={
          user && user.hasGroup && groups && groups.length > 0
            ? [
                <SelectMenu
                  value={groupId ? items.find((g: any) => g.id === groupId) : null}
                  firstValueDefault={true}
                  dense={true}
                  disabled={user === null || disabled}
                  items={Object.entries(items)}
                  clearable={false}
                  highlightSelection={false}
                  onChange={this.onGroupChange.bind(this)}
                  sx={{
                    maxWidth: { md: "300px" },
                    width: { xs: "100%", sm: "100%" },
                    [theme.breakpoints.down(showSearchFromBreakpoint)]: {
                      margin: "0!important"
                    }
                  }}
                  key={Math.random().toString(36).substring(7)}
                />
              ]
            : null
        }
      />
    );
  }
}
