import { Alert, Snackbar } from "@mui/material";
import React, { createContext, ReactNode, useContext, useState } from "react";

interface SnackbarContextType {
  showSnackbar: (message: string, severity: "success" | "error" | "warning" | "info") => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

// Helper function to access Snackbar globally
let globalSnackbar: SnackbarContextType["showSnackbar"];

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error" | "warning" | "info">("info");

  const showSnackbar = (msg: string, sev: "success" | "error" | "warning" | "info") => {
    setMessage(msg);
    setSeverity(sev);
    setOpen(true);
  };

  // Expose showSnackbar globally
  globalSnackbar = showSnackbar;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ minWidth: "300px" }}
          variant='filled'
        >
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const showGlobalSnackbar = (
  message: string,
  severity: "success" | "error" | "warning" | "info"
) => {
  if (globalSnackbar) {
    globalSnackbar(message, severity);
  }
};
