import { Data } from "../Data";
import { DocumentList } from "../documents/DocumentList";
import { User } from "../users/User";

export class AttributeRequestActivity extends Data {
  static instance = null;

  documents: any;
  previousActivity: any;
  latestActivity: any;

  constructor(options: any) {
    super({
      api: "/EmployeeAttribute/GetActivityHistory/" + options.attributeId,
      callback: (data: any) => {
        options.resolve(this);
      },
      ...options
    });
  }

  hasDocuments() {
    return this.documents && this.documents.list.length > 0;
  }

  onDataReceived(data: any) {
    this.previousActivity = [];

    this.documents = new DocumentList(data.documents);

    if (data.activities && data.activities.length > 0) {
      const activities = data.activities;

      activities.sort(
        (a: any, b: any) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime()
      );

      activities.forEach((a: any, i: any) => {
        a.user = new User({
          data: {
            firstName: a.employeeFirstName,
            fullName: a.employeeFullName,
            hasProfilePicture: a.employeeHasProfilePicture,
            employeeId: a.employeeId,
            lastName: a.employeeLastName
          }
        });

        if (a.documentIds)
          a.documentIds.forEach((docId: any) => {
            const doc = this.documents.list.find((d: any) => d.documentId === docId);
            if (doc) doc.createDate = a.createDate;
          });

        if (i === 0) this.latestActivity = a;
        else this.previousActivity.push(a);
      });

      this.documents.list.sort(
        (a: any, b: any) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime()
      );
    }
  }
}
