import React from "react";
import { Organization } from "../data/Organization";
import { ReactComponent as CompanyIcon } from "../resources/images/icons-company2.svg";
import { ReactComponent as FeedbackIcon } from "../resources/images/icons-feedback.svg";
import { ReactComponent as ProfileIcon } from "../resources/images/icons-user.svg";
import { ReactComponent as TeamIcon } from "../resources/images/icons-users-1.svg";

export const AppPages = {
  dashboard: {
    name: "Dashboard",
    slug: "dashboard",
    label: "Dashboard",
    enabled: false,
    hasAccess: (user: any, organization: any) =>
      user &&
      (!organization || (organization && organization.status !== Organization.status.inactive)),
    developerOnly: true,
    ref: React.createRef()
  },
  profile: {
    name: "My profile",
    slug: "profile",
    paths: ["/profile"],
    //getSlug: (user, organization) => user && !user.hasJob ? 'profile/competencies' : 'profile',
    label: "My profile",
    isDefault: () => true,
    icon: <ProfileIcon />,
    hasAccess: (user: any, organization: any) =>
      user &&
      (!organization || (organization && organization.status !== Organization.status.inactive)),
    ref: React.createRef()
  },
  team: {
    name: "My team",
    slug: "team",
    paths: ["/team"],
    label: "My team",
    icon: <TeamIcon />,
    hasAccess: (user: any, organization: any) =>
      user &&
      (user.isTeamLead || user.isOrganizationAdmin) &&
      (!organization || (organization && organization.status !== Organization.status.inactive)),
    admin: true,
    ref: React.createRef()
  },
  company: {
    name: "My company",
    slug: "company",
    paths: ["/company"],
    label: "My company",
    icon: <CompanyIcon />,
    hasAccess: (user: any) => user,
    showInNav: true,
    ref: React.createRef()
  },
  groups: {
    name: "My groups",
    slug: "groups",
    paths: ["/groups"],
    label: "My groups",
    icon: <CompanyIcon />,
    hasAccess: (user: any) => user && user.hasGroup,
    hasChildren: true,
    showInNav: true,
    ref: React.createRef()
  },
  practicals: {
    name: "Practicals",
    slug: "practicals",
    paths: ["/practicals", "/practicals/new"],
    label: "Practicals",
    icon: <CompanyIcon />,
    hasAccess: (user: any) => user && (user.isInstructor || user.isParticipant),
    showInNav: true,
    ref: React.createRef()
  },
  feedback: {
    name: "Feedback",
    slug: "feedback",
    paths: ["/feedback"],
    label: "Feedback",
    icon: <FeedbackIcon />,
    hasAccess: (user: any, organization: any) =>
      user &&
      (user.isTeamLead || user.hasGroup || user.isOrganizationAdmin) &&
      (!organization || (organization && organization.status !== Organization.status.inactive)),
    admin: true,
    ref: React.createRef()
  },
  settings: {
    name: "Settings",
    slug: "settings",
    paths: ["/settings"],
    label: "Settings",
    hasAccess: (user: any) => user && (user.isOrganizationAdmin || user.isBillingUser),
    hasChildren: true,
    admin: true,
    showInNav: true,
    seperate: true,
    ref: React.createRef()
  },
  signup: {
    name: "Sign up",
    slug: "signup",
    paths: ["/signup"],
    label: "Sign up",
    hasAccess: (user: any) => true,
    admin: true,
    showInNav: false,
    ref: React.createRef()
  }
};
