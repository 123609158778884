import { ITeamRegulationSummary, RecursiveData } from "../interfaces/Team";

export const mapEmployees = (
  teamRegulationSummary: RecursiveData | ITeamRegulationSummary
): RecursiveData | ITeamRegulationSummary => {
  if (Array.isArray(teamRegulationSummary)) {
    // If data is an array, recursively apply mapEmployees on each element and return a new array
    return teamRegulationSummary.map((item) => mapEmployees(item) as typeof item);
  } else if (teamRegulationSummary && typeof teamRegulationSummary === "object") {
    // Create a shallow copy of the current object to avoid modifying the original one
    const newTeamRegulationSummary = { ...teamRegulationSummary };

    // If data has metEmployees and gapEmployees, merge them into employees
    if ("metEmployees" in newTeamRegulationSummary && "gapEmployees" in newTeamRegulationSummary) {
      const gapEmployees = newTeamRegulationSummary.gapEmployees.map((task) => ({
        ...task,
        isCompliant: false
      }));
      const metEmployees = newTeamRegulationSummary.metEmployees.map((task) => ({
        ...task,
        isCompliant: true
      }));

      newTeamRegulationSummary.employees = [...gapEmployees, ...metEmployees];
      newTeamRegulationSummary.gapEmployees = gapEmployees;
      newTeamRegulationSummary.metEmployees = metEmployees;
    }

    // Recursively apply mapEmployees to each nested object and assign the result back to newTeamRegulationSummary
    for (const key in newTeamRegulationSummary) {
      if (newTeamRegulationSummary.hasOwnProperty(key)) {
        const nestedData = newTeamRegulationSummary[key as keyof typeof newTeamRegulationSummary];
        if (typeof nestedData === "object") {
          newTeamRegulationSummary[key as keyof typeof newTeamRegulationSummary] = mapEmployees(
            nestedData
          ) as typeof nestedData;
        }
      }
    }

    return newTeamRegulationSummary;
  }

  // If data is neither an array nor an object, return it directly
  return teamRegulationSummary;
};
