export class SettingsAttributeCategory {
  categoryId: any;
  name: any;
  numberOfAttributeDefinitions: any;
  isInUsed: any;

  constructor(data?: any) {
    if (data) {
      this.categoryId = data.categoryId || data.attributeCategoryId;
      this.name = data.name;
      this.numberOfAttributeDefinitions = data.numberOfAttributeDefinitions;
      this.isInUsed = data.numberOfAttributeDefinitions !== 0;
    }
  }
}
