import { Skeleton, Stack } from "@mui/material";
import React, { Component } from "react";
import { Paper } from "./Paper";

export class StatCard extends Component<any, any> {
  render() {
    const { sx, icon, title, stat, statStyle, loading } = this.props;

    return (
      <Paper className='klayo-statcard' padding='23px' sx={sx}>
        <Stack direction='row' spacing={2}>
          <div className='klayo-statcard_badge'>{icon}</div>
          <div>
            {title && <div className='klayo-statcard_title'>{title}</div>}
            {!isNaN(stat) && (
              <div className='klayo-statcard_stat' style={statStyle}>
                {loading ? <Skeleton width='32px' /> : stat}
              </div>
            )}
          </div>
        </Stack>
      </Paper>
    );
  }
}
