import { User } from "./User";

export class OrgChartEmployee extends User {
  id: string;
  isMyTeam: boolean;
  isVacancy: boolean;
  type: string;
  jobTitle: any;
  parentId: string;
  highlight: boolean;
  hasJob: boolean;

  constructor(employee: any, jobTitle: any, reportsTo: any) {
    super({ data: employee });

    this.id = employee.employeeId;
    this.isMyTeam = employee.isMyTeam;
    this.isVacancy = employee.isVacancy;
    this.type = "person";
    this.jobTitle = jobTitle;
    this.parentId = reportsTo ? reportsTo : "org-root-job";
    this.highlight = false;
    this.hasJob = true;
  }
}
