import styled from "@emotion/styled";
import React, { Component } from "react";
import { KLAYO_COLORS } from "../../themes";

class IntegrationAbbreviation extends Component<any, any> {
  static dataType = {
    integration: {
      value: 0,
      nameField: "name",
      abbreviationField: "abbreviation"
    },
    integrationUser: {
      value: 1,
      nameField: "organizationIntegrationName",
      abbreviationField: "organizationIntegrationAbbreviation"
    }
  };

  getNameField() {
    const { type } = this.props;

    if (type === IntegrationAbbreviation.dataType.integration.value) {
      return IntegrationAbbreviation.dataType.integration.nameField;
    }

    if (type === IntegrationAbbreviation.dataType.integrationUser.value) {
      return IntegrationAbbreviation.dataType.integrationUser.nameField;
    }
  }

  getAbbreviationField() {
    const { type } = this.props;

    if (type === IntegrationAbbreviation.dataType.integration.value) {
      return IntegrationAbbreviation.dataType.integration.abbreviationField;
    }

    if (type === IntegrationAbbreviation.dataType.integrationUser.value) {
      return IntegrationAbbreviation.dataType.integrationUser.abbreviationField;
    }
  }

  render() {
    const { data } = this.props;
    const nameField: any = this.getNameField();
    const abbreviationField: any = this.getAbbreviationField();
    const label = data ? (data[abbreviationField] ? data[abbreviationField] : data[nameField]) : "";

    return data && label ? (
      <IntegrationAbbreviationStyled className='klayo_integration-abbreviation'>
        | {label}
      </IntegrationAbbreviationStyled>
    ) : (
      <></>
    );
  }
}

const IntegrationAbbreviationStyled = styled.span`
  &.klayo_integration-abbreviation {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: ${KLAYO_COLORS.GreyGR400};
    margin-left: 4px;
  }
`;

export default IntegrationAbbreviation;
