import axios from "axios";
import { showGlobalSnackbar } from "../providers/SnackbarProvider";
import { ErrorModel } from "./models/ErrorModel";

const API_URL = `${(window as any).app.env.API_URL}/api`;

const axiosClient = axios.create({
  baseURL: API_URL,
  withCredentials: true
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorMessage = new ErrorModel(
      ErrorModel.Severity.FAIL,
      error,
      error?.response?.config?.url
    );
    showGlobalSnackbar(errorMessage.message, "error");

    return Promise.reject(error);
  }
);

export default axiosClient;
