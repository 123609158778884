import { AbstractAttributeBase } from "./AbstractAttributeBase";
import { AttributeRequestActivity } from "./AttributeRequestActivity";

// export class JobAttribute extends AbstractAttribute {
export class JobAttribute extends AbstractAttributeBase {
  category: string;
  required: boolean;
  currentProficiency: number;
  requiredProficiency: number;
  gapReason: any;
  type: any;
  attributeName: string;
  isParent: any;
  mandatory: any;

  constructor(index: any, data: any) {
    super(index, data);

    this.attributeName = data.attributeDefinitionName;
    this.category = data.attributeDefinitionCategory;
    this.required = data.required;
    this.mandatory = data.mandatory;
    this.currentProficiency = data.proficiencyValue;
    this.requiredProficiency = data.proficiencyMinimum;
    this.gapReason = Object.values(AbstractAttributeBase.gapReason)[
      data.employeeAttributeToJobAttributeMetType
    ];

    if (this.isParent) this.type = null;
    else {
      if (this.mandatory) this.type = AbstractAttributeBase.type.mandatory.label;
      else if (this.required) this.type = "Required";
      else this.type = "Desirable";
    }
  }

  loadRequestActivity(context: any, updateCache: any) {
    return new Promise((resolve, reject) => {
      if (!this.requestActivity || updateCache === true) {
        this.requestActivity = new AttributeRequestActivity({
          attributeId: this.employeeAttributeId,
          resolve: (requestActivity: any) => {
            resolve(requestActivity);
          },
          reject,
          context
        });
      } else resolve(this.requestActivity);
    });
  }
}
