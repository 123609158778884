import { cloneDeep } from "lodash";
import { Abstract_Attribute } from "../../constants";
import { Data } from "../Data";
import { EmployeeAttributeList } from "../attributes/EmployeeAttributeList";
import { GapAttributeList } from "../attributes/GapAttributeList";
import { JobAttributeList } from "../attributes/JobAttributeList";
import { UserAttributeSummary } from "./UserAttributeSummary";

export class UserProfile extends Data {
  employeeId!: string;
  employeeNumber!: string;
  gender!: string;
  email!: string;
  fullName!: string;
  jobTitle!: string;
  location!: string;
  preferredName!: string;
  ethnicity!: string;
  isAdmin!: boolean;
  reportsTo!: string | null;
  hasManager!: boolean;
  hasGroupManager!: boolean;
  hasApprover!: boolean;
  startDate!: string;
  locationName!: string;
  dateOfBirth!: string;
  organizationName!: string;
  employeeAttributes!: EmployeeAttributeList;
  gapAttributes!: GapAttributeList;
  attributeSummary!: UserAttributeSummary;
  jobDescription!: string;
  jobAttributes!: any;
  hasJob!: boolean;
  department!: string;

  constructor(options: any) {
    super({
      api: "/Profile?employeeId=" + options.employeeId,
      callback: (data: any) => {
        const { employee, jobDefinition, gapAttributes, job } = data;

        this.employeeId = employee.employeeId;
        this.employeeNumber = employee.employeeNumber;
        this.gender = employee.gender;
        this.email = employee.email;
        this.fullName = employee.fullName;
        this.jobTitle = employee.jobTitle;
        this.location = employee.location;
        this.preferredName = employee.preferredName;
        this.gender = employee.gender;
        this.ethnicity = employee.ethnicity;
        this.isAdmin = employee.isOrganizationAdmin || employee.isTeamLead;
        this.reportsTo = employee.reportsTo;
        this.hasManager = this.reportsTo !== null;
        this.hasGroupManager = employee.hasAGroupManagerApprover;
        this.hasApprover = this.hasManager === true || this.hasGroupManager === true;
        this.startDate = employee.startDate;
        this.location = employee.location;
        this.locationName = employee.locationName;
        this.dateOfBirth = employee.dateOfBirth;
        this.organizationName = employee.organizationName;

        this.employeeAttributes = new EmployeeAttributeList({ data: employee.employeeAttributes });

        this.gapAttributes = new GapAttributeList({ data: gapAttributes });
        this.attributeSummary = new UserAttributeSummary(employee.attributeSummaryDto);
        //Job
        if (jobDefinition) {
          this.jobDescription = jobDefinition.description;
          this.jobAttributes = new JobAttributeList({ data: jobDefinition.jobAttributes });
          this.updateJobAttributes();
          this.gapAttributes.initAttributesLists(this.jobAttributes, this.employeeAttributes);
          this.generateTaskAttributesTree(jobDefinition);
        }

        //Job
        this.hasJob = job !== null && job !== undefined;
        if (this.hasJob) {
          this.department = job.department;
        }

        options.resolve(this);
      },
      ...options
    });
  }

  onDataReceived(data: any) {}

  static get(context: any, employeeId: any) {
    return new Promise((resolve, reject) => {
      new UserProfile({ employeeId: employeeId, resolve, reject, context });
    });
  }

  generateTaskAttributesTree(jobDefinition: any) {
    const jobAttributes = this.jobAttributes.attributes;
    const employeeAttrs = this.employeeAttributes.flatAttributes.filter(
      ({ attributeDefinitionId }: any) =>
        !!jobAttributes.find(
          (jobAttr: any) => jobAttr.attributeDefinitionId === attributeDefinitionId
        )
    );
    const gapAttrs = this.gapAttributes.flatAttributes;
    const taskAttributesTree = [];

    const attributesMap: any = {};
    [...gapAttrs, ...employeeAttrs].forEach((_attr) => {
      const attr = cloneDeep(_attr);
      attr.inTask = false;
      if (!attributesMap[attr.attributeDefinitionId]) {
        attributesMap[attr.attributeDefinitionId] = attr;
      }
    });

    jobAttributes.forEach((attr: any) => {
      const foundAttr = attributesMap[attr.attributeDefinitionId];
      //Employee can have attributes that are not in job definition
      if (foundAttr) {
        foundAttr.missing = attr.missing;
        foundAttr.mandatory = attr.mandatory;
        foundAttr.required = attr.required;
        foundAttr.type = attr.type;
      } else {
        //Desirable attributes that are not in employee attributes
        const _attr = cloneDeep(attr);
        _attr.missing = attr.missing;
        _attr.inTask = false;
        attributesMap[_attr.attributeDefinitionId] = _attr;
      }
    });
    const attributesInTask: any = [];
    const regulationTasks = jobDefinition.regulationTasks ?? [];
    regulationTasks.forEach((task: any) => {
      const attributes = task.competencies
        ?.map(({ attributeDefinitionId }: any) => attributesMap[attributeDefinitionId])
        ?.map((_attr: any) => {
          _attr.inTask = true;
          const attr = cloneDeep(_attr);
          attr.attributeHierarchy = [task.id, attr.id];
          attr.rowId = `${task.id}_${attr.id}`;
          return attr;
        });
      attributesInTask.push(...attributes);

      const taskWithAttributes = {
        id: task.id,
        name: task.name,
        attributeHierarchy: [task.id],
        missing: attributes.some((attribute: any) => attribute.missing),
        rowId: task.id,
        isTask: true
      };
      taskAttributesTree.push(taskWithAttributes);
    });
    taskAttributesTree.push(...attributesInTask);
    const attributesNotInTask = Object.values(attributesMap).filter(({ inTask }: any) => !inTask);
    taskAttributesTree.push(...attributesNotInTask);
    this.jobAttributes.taskAttributesTree = taskAttributesTree;

    const flat = jobDefinition.jobAttributes
      .filter((attribute: any) => attributesMap.hasOwnProperty(attribute.attributeDefinitionId))
      ?.map(({ attributeDefinitionId }: any) => attributesMap[attributeDefinitionId])
      ?.map((_attr: any) => {
        const attr = cloneDeep(_attr);
        attr.attributeHierarchy = [attr.id];
        return attr;
      });

    this.jobAttributes.flatAttributes = flat;
  }

  updateJobAttributes() {
    this.jobAttributes.attributes.forEach((a: any) => {
      a.currentProficiency = this.getProficiency(a.attributeDefinitionId);
      a.missing = a.gapReason !== Abstract_Attribute.gapReason.met; //emplAttr === undefined;

      if (a.childAttributes)
        a.childAttributes.forEach((c: any) => {
          const emplChildAttr = this.jobAttributes.getByDefinition(c.attributeDefinitionId);
          if (emplChildAttr) c.gapReason = emplChildAttr.gapReason;

          c.missing = c.gapReason !== Abstract_Attribute.gapReason.met;
          //if(c.missing) a.missing = true;
        });
    });
  }

  removeAttribute(attr: any) {
    this.employeeAttributes.removeAttribute(attr);
  }

  getProficiency(jobDefId: any) {
    const attr = this.employeeAttributes.getByDefinition(jobDefId);
    return attr ? attr.currentProficiency : null;
  }

  isComplient(attr: any) {
    if (!attr) return false;

    const reqProf = attr.requiredProficiency;
    const curProd = this.getProficiency(attr.attributeDefinitionId);
    const profMet = curProd >= reqProf;

    return attr.mandatory ? profMet : profMet ? true : null;
  }

  list: any[] = [];

  findMatch(attr: any) {
    return this.list.find((a: any) => a.name === attr);
  }

  hasAttribute(attr: any) {
    return this.employeeAttributes?.getAttributeByEmployeeAttributeId(attr.attributeDefinitionId);
  }
}
