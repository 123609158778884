import { Data } from "../Data";
import { User } from "../users/User";

export class EmployeeSelectorList extends Data {
  static instance: any = null;
  employees: any;
  attributes: any;

  constructor(options: any) {
    super({
      api: "/Employee/Search?searchText=" + options.search,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...options
    });
  }

  onDataReceived(data: any) {
    this.employees = [];
    data.employees.forEach((employee: any, index: any) =>
      this.employees.push(new User({ data: { id: index, ...employee } }))
    );
  }

  static get(context: any, search: any) {
    return new Promise((resolve: any, reject) => {
      if (EmployeeSelectorList.instance && search === "")
        resolve(EmployeeSelectorList.instance, true);
      else
        EmployeeSelectorList.instance = new EmployeeSelectorList({
          resolve,
          reject,
          search,
          context
        });
    });
  }

  findMatch(attr: any) {
    return this.attributes.find((a: any) => a.name === attr);
  }
}
