export class SettingsRegulationRegion {
  id: any;
  name: any;
  createdDate: any;
  lastUpdatedDate: any;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.createdDate = data.createdDate;
      this.lastUpdatedDate = data.lastUpdatedDate;
    }
  }
}
