export class BillingCreditCardDetails {
  cardHolderName: any;
  lastFourDigits: any;
  isDefault: any;
  createdDate: any;
  expiryMonth: any;
  expiryYear: any;

  constructor(data: any) {
    this.cardHolderName = data.cardHolderName;
    this.lastFourDigits = data.lastFourDigits;
    this.isDefault = data.isDefault;
    this.createdDate = data.createdDate;
    this.expiryMonth = data.expiryMonth;
    this.expiryYear = data.expiryYear + 2000;
  }
}
