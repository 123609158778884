export class SettingsTask {
  id: any;
  name: any;
  createdDate: any;
  lastUpdatedDate: any;
  numberOfCompetencies: any;
  numberOfJobDefinitions: any;
  numberOfRegulations: any;
  competencies: any;
  row: any;

  constructor(data: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.createdDate = data.createdDate;
      this.lastUpdatedDate = data.lastUpdatedDate;
      this.numberOfCompetencies = data.numberOfCompetencies;
      this.numberOfJobDefinitions = data.numberOfJobDefinitions;
      this.numberOfRegulations = data.numberOfRegulations;
      this.competencies = data.competencies;
      this.row = {
        id: data.id
      };
    }
  }
}
