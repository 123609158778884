import { Data } from "../Data";
import { SettingsAttributeCategory } from "./SettingsAttributeCategory";

export class SettingsAttributeCategoryList extends Data {
  static instance: any = null;
  static defaultPageSize = 50;

  categories: any;
  currentPage: any;
  totalPages: any;
  totalCount: any;
  hasNext: any;
  hasPrevious: any;

  constructor(options: any) {
    const { searchText, pageNumber, pageSize, orderBy, ...otherOptions } = options;

    const apiOptions = {
      api: `/AttributeDefinition/AttributeCategory`,
      callback: (data: any) => {
        options.resolve(this, false);
      },
      ...otherOptions
    };

    // add params for api if provided
    const queryParams = [];
    if (searchText) queryParams.push(`searchText=${searchText}`);
    if (pageNumber) queryParams.push(`pageNumber=${pageNumber}`);
    if (pageSize)
      queryParams.push(`pageSize=${pageSize || SettingsAttributeCategoryList.defaultPageSize}`);
    if (orderBy) queryParams.push(`orderBy=${orderBy}`);

    if (queryParams.length > 0) {
      apiOptions.api += `?${queryParams.join("&")}`;
    }

    super(apiOptions);

    this.categories = [];
    this.currentPage = 1;
    this.totalPages = null;
    this.totalCount = null;
    this.hasNext = false;
    this.hasPrevious = false;
  }

  onDataReceived(data: any) {
    //  reformat data from api then assign to state
    const { currentPage, totalPages, totalCount, hasNext, hasPrevious } = data.attributeCategories;

    this.currentPage = currentPage;
    this.totalPages = totalPages;
    this.totalCount = totalCount;
    this.hasNext = hasNext;
    this.hasPrevious = hasPrevious;

    data.attributeCategories.data.forEach((cat: any, index: any) =>
      this.categories.push(new SettingsAttributeCategory(cat))
    );
  }

  getCategory(id: any) {
    return this.categories.find((l: any) => l.categoryId === id || l.attributeCategoryId === id);
  }

  updateCategory(category: any) {
    const categories = [...this.categories];
    const index = categories.indexOf(this.getCategory(category.categoryId));
    const updatedCat = new SettingsAttributeCategory(category);
    if (index !== -1) categories.splice(index, 1, updatedCat);
    this.categories = [...categories];
    return updatedCat;
  }

  addCategory(category: any) {
    this.categories = [...this.categories];
    const newCat = new SettingsAttributeCategory(category);
    this.categories.push(newCat);
    return newCat;
  }

  deleteCategory(category: any) {
    const categories = [...this.categories];
    const index = categories.indexOf(category);
    if (index !== -1) categories.splice(index, 1);
    this.categories = [...categories];
  }

  static get(context: any, updateCache: any) {
    return new Promise((resolve: any, reject) => {
      if (SettingsAttributeCategoryList.instance && updateCache !== true)
        resolve(SettingsAttributeCategoryList.instance, true);
      else
        SettingsAttributeCategoryList.instance = new SettingsAttributeCategoryList({
          resolve,
          reject,
          context
        });
    });
  }

  static getWithPaging(context: any, options: any) {
    const { searchText, pageNumber, pageSize, orderBy } = options;

    return new Promise((resolve, reject) => {
      SettingsAttributeCategoryList.instance = new SettingsAttributeCategoryList({
        searchText,
        pageNumber,
        pageSize,
        orderBy,
        resolve,
        reject,
        context
      });
    });
  }
}
