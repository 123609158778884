import { AxiosRequestConfig } from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import axiosClient from "../common/AxiosClient";

interface UseAppQueryOptions<TData = unknown, TError = unknown>
  extends UseQueryOptions<TData, TError> {
  config?: AxiosRequestConfig;
}

export function useAppQuery<TData = unknown, TError = unknown>(
  url: string,
  options?: UseAppQueryOptions<TData, TError>
): UseQueryResult<TData, TError> {
  return useQuery<TData, TError>({
    queryKey: [url, options?.config?.params],
    queryFn: async () => {
      const res = await axiosClient.get(url, options?.config);

      return res.data;
    },
    ...options
  });
}
