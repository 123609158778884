import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { DateLabel } from "klayowebshared";
import { ReactComponent as MoreVertIcon } from "../../resources/images/icons-more-vertical.svg";
import { Table } from "./Table";

export class DocumentTable extends Table {
  static columns = {
    name: {
      field: "name",
      label: "Name"
    },
    actions: {
      field: "actions",
      label: "Actions"
    }
  };

  static defaultProps = {
    ...Table.defaultProps,
    columns: [{ type: DocumentTable.columns.name }, { type: DocumentTable.columns.actions }],
    noRowsMessage: null,
    rowHasAction: null
  };

  constructor(props: any) {
    super(props);
  }

  getRowId(e: any) {
    return this.tableId + "_" + e.documentId;
  }

  initColumns() {
    const { columns, sortable, onDocumentAction } = this.props;

    this.columns = [];

    columns.forEach((c: any) => {
      if (this.hasColumn(c)) {
        if (c.type === DocumentTable.columns.name)
          this.columns.push({
            field: "name",
            sortable: sortable,
            headerName: c.label,
            flex: true,
            filterable: false,
            disableReorder: true,
            valueGetter: (params: any) => params.row.documentName,
            renderCell: (params: any) => (
              <div>
                <DateLabel date={params.row.createDate} />
                <div>{params.row.documentName}</div>
              </div>
            )
          });
      }

      if (c.type === DocumentTable.columns.actions)
        this.columns.push({
          field: "actions",
          type: "actions",
          filterable: false,
          disableReorder: true,
          align: "right",
          getActions: (params: any) => [
            <GridActionsCellItem
              icon={<MoreVertIcon />}
              label='Actions'
              onClick={(e) => onDocumentAction(e, params.row)}
              key={Math.random().toString(36).substring(7)}
            />
          ]
        });
    });
  }
}
