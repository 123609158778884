import styled from "@emotion/styled";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { Component } from "react";
import { ReactComponent as CalendarIcon } from "../resources/images/icons-calendar-custom.svg";
import { KLAYO_COLORS } from "../themes";
import { getDateFormatPattern } from "../utilities/formatDate";
import { Alert } from "./Alert";

export class DatePicker extends Component<any, any> {
  static defaultProps = {
    allowEmpty: true,
    allowFuture: true,
    allowPast: true,
    showPastWarning: false,
    showPastWarningMessage: "Please select a current or future date",
    // inputFormat: 'dd/MM/yyyy',
    // format: 'dd/MM/yyyy',
    readOnly: false
  };

  ref: any;

  constructor(props: any) {
    super(props);

    this.ref = React.createRef();

    const valid = this.isValid(props.value);

    this.state = {
      error: this.props.disabled !== true ? !valid : (props.error ?? false),
      errorMessage: props.errorMessage ?? "Invalid date",
      value: props.value,
      isPast: valid && this.isDatePast(props.value),
      format: "MM/dd/yyyy"
    };
  }

  componentDidMount() {
    const userLanguage = navigator.language || "en";
    const findFormat = getDateFormatPattern(userLanguage);
    this.setState({ format: findFormat });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.value !== this.props.value)
      this.setState({
        value: this.props.value,
        error: this.props.disabled !== true ? !this.isValid(this.props.value) : false
      });
    if (prevProps.error !== this.props.error) this.setState({ error: this.props.error });
    if (prevProps.errorMessage !== this.props.errorMessage)
      this.setState({ errorMessage: this.props.errorMessage });
    // allowEmpty change reset error
    if (prevProps.allowEmpty !== this.props.allowEmpty) this.onBlur();
  }

  /*onError(reason, value) {
        this.setState({ error: reason !== null });
        if (reason === null) this.fireChange(value);
    }*/

  onChange(value: any) {
    const { onChange } = this.props;

    const valid = this.isValid(value);
    this.setState({ value, error: !valid, isPast: valid && this.isDatePast(value) });

    if (onChange) onChange(value, valid);
  }

  isDatePast(value: any) {
    if (value === null) return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return value < today;
  }

  isValid(value: any) {
    const { allowFuture, allowPast, allowEmpty } = this.props;
    if (value === null) {
      return allowEmpty;
    }

    if (!value || typeof value === "string" || (!value as any) instanceof Date || isNaN(value)) {
      return false;
    }

    let year = value.getYear();
    if (isNaN(year)) {
      return false;
    }

    year += 1900;
    if (year < 1900 || year > 2050) {
      return false;
    }

    const month = value.getMonth();
    if (isNaN(month)) return false;
    if (month < 0 || month > 11) {
      return false;
    }

    const date = value.getDate();
    if (isNaN(date)) return false;
    if (date < 0 || date > 31) {
      return false;
    }

    const today = new Date();
    if (!allowFuture && value > today) return false;
    if (!allowPast && value < today) return false;

    value.setHours(0);
    value.setMinutes(0);

    return true;
  }

  fireChange(value: any) {
    const { onChange } = this.props;

    const year = value.getYear() + 1900;
    if (year < 1900 || year > 2050) return;

    const month = value.getMonth();
    if (month < 0 || month > 11) return;

    this.setState({ error: false });

    if (onChange) onChange(value);
  }

  onAccept() {}

  onBlur() {
    const { value } = this.state;
    const isValid = this.isValid(value);

    this.setState({ error: !isValid });

    if (this.props.onBlur) {
      this.props.onBlur(value, isValid);
    }
  }

  renderDatePicker(props: any) {
    const { theme } = this.props;
    // return theme && theme.isBreakpointDown('md') ? <MobileDatePicker {...props} format={this.state.format} /> : <DesktopDatePicker {...props} format={this.state.format} />
    return <MuiDatePicker {...props} format={this.state.format} />;
  }

  render() {
    const {
      label,
      disabled,
      sx,
      inputFormat,
      readOnly,
      autoFocus,
      helperText,
      showPastWarning,
      showPastWarningMessage,
      format,
      maxDate,
      minDate
    } = this.props;
    const { value, error, errorMessage, isPast } = this.state;

    return (
      <DatePickerStyled className={`klayo_date-picker ${disabled ? "picker-disabled" : ""}`}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {label && (
            <label className={`date-picker-label ${error ? "date-picker-label-error" : ""}`}>
              {label}
            </label>
          )}

          {this.renderDatePicker({
            ref: this.ref,
            inputFormat: inputFormat,
            onChange: this.onChange.bind(this),
            onAccept: this.onAccept.bind(this),
            value: value,
            disabled: disabled,
            readOnly: readOnly,
            format: format,
            components: {
              OpenPickerIcon: CalendarIcon
            },
            maxDate: maxDate,
            minDate: minDate,
            onBlur: this.onBlur.bind(this),
            autoFocus: autoFocus,
            slotProps: {
              textField: {
                error: error,
                helperText: error ? errorMessage : helperText
              }
            }
          })}

          {showPastWarning && isPast && (
            <Alert severity='warning' sx={{ marginTop: "16px" }}>
              {showPastWarningMessage}
            </Alert>
          )}
        </LocalizationProvider>
      </DatePickerStyled>
    );
  }
}

const DatePickerStyled = styled.div`
  &.klayo_date-picker {
    // max-width: 200px;

    .date-picker-label {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      line-height: 20px;
      color: ${KLAYO_COLORS.GreyGR700};
      padding-left: 14px;
      padding-bottom: 5px;
      display: block;
    }

    .MuiInputBase-input {
      padding: 9.5px 14px;
    }

    .MuiOutlinedInput-root {
      border-radius: 20px;
    }

    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${KLAYO_COLORS.NeonBlueNB400};
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${KLAYO_COLORS.NeonBlueNB200};
    }

    .MuiIconButton-root {
      color: ${KLAYO_COLORS.NeonBlueNB500};
    }
  }
  &.picker-disabled {
    .MuiTextField-root {
      .MuiInputBase-root {
        background-color: ${KLAYO_COLORS.GreyGR100};
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: ${KLAYO_COLORS.GreyGR300};
    }
    .MuiIconButton-root {
      color: ${KLAYO_COLORS.GreyGR400};
    }
  }
`;
