import { Box, CircularProgress } from "@mui/material";
import React, { Component } from "react";

export class SectionLoader extends Component<any, any> {
  render() {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "30px" }}>
        <CircularProgress />
      </Box>
    );
  }
}
