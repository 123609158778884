import styled from "@emotion/styled";
import { AppContext } from "../../common/AppContext";
import { Paper } from "../../components/Paper";
import SettingTrainingFilter from "../../components/SettingTrainingFilter";
import { TrainingTable } from "../../components/table/TrainingTable";
import { ViewComponent } from "../../components/ViewComponent";
import {
  COURSE_RECURRENCE,
  TRAINING_ATTRIBUTE_DIALOG_TYPE,
  TRAINING_FILTER_DEFAULT_VALUE,
  TRAINING_FILTER_FACULTY_DEFAULT_VALUE
} from "../../constants";
import { SettingsCourseList } from "../../data/settings/SettingCourseList";
import { KLAYO_COLORS } from "../../themes";
import { getSortByApiValue } from "../../utilities";
import { TrainingAttributesDialog } from "./TrainingAttributesDialog";

export class SettingsTrainingView extends ViewComponent {
  static contextType = AppContext;

  static defaultProps = {
    allowAssignAdminBilling: true
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ...(ViewComponent as any).state,
      search: null,
      integrationId: null,
      actionTarget: null,
      associatedAttributeDetail: null,
      trainingDialogType: null,
      trainingError: null,
      isTrainingAttributesDialogShow: false,
      pageSize: SettingsCourseList.defaultPageSize,
      orderByConvertList: [
        { tableFieldName: "attributes", apiFieldName: "numberOfAssociatedAttributes" },
        {
          tableFieldName: "provider",
          apiFieldName: "organizationIntegrationName",
          subApiFieldName: "organizationIntegrationAbbreviation"
        }
      ],
      sortModel: [
        {
          field: "courseName",
          sort: "asc"
        }
      ],
      paginationModel: {
        page: 0,
        pageSize: SettingsCourseList.defaultPageSize
      },
      integrations: { ...TRAINING_FILTER_DEFAULT_VALUE },
      faculties: [],
      coursesWithHierarchy: []
    };
  }

  async componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    const { onLoadTrainingCourse, onLoadIntegration, onLoadTrainingFaculties } = this.props;
    onLoadTrainingCourse();
    onLoadIntegration();
    const faculties = await onLoadTrainingFaculties();
    if (faculties) {
      const facultyList = [TRAINING_FILTER_FACULTY_DEFAULT_VALUE.ALL];
      faculties.map((item: any) => {
        facultyList.push({
          value: item.id,
          label: item.name
        });
      });
      this.setState({ faculties: facultyList });
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.integration !== this.props.integration) {
      this.onProcessIntegration();
    }

    if (prevProps.courses !== this.props.courses) {
      this.setState({
        coursesWithHierarchy: this.getCoursesWithHierarchy(this.props.courses)
      });
    }
  }

  onProcessIntegration() {
    const { integration } = this.props;

    if (integration) {
      const { integrations } = integration;
      let integrationList = {
        ...TRAINING_FILTER_DEFAULT_VALUE
      };

      integrations.map((item: any) => {
        const label = item?.abbreviation ? item?.abbreviation : item.name;

        return (integrationList = {
          ...integrationList,
          [item.id]: {
            value: item.id,
            label: label
          }
        });
      });

      this.setState({ integrations: integrationList });
    }
  }

  onSearch(search: string) {
    const { onLoadTrainingCourse } = this.props;
    const {
      paginationModel: { pageSize },
      integrationId,
      facultyId,
      sortModel,
      orderByConvertList
    } = this.state;

    const [{ field, sort }] = sortModel;
    const orderBy = getSortByApiValue(orderByConvertList, field, sort);
    onLoadTrainingCourse(search, pageSize, orderBy, integrationId, facultyId);

    const newModel = { page: 0, pageSize };
    this.setState({ paginationModel: newModel });
  }

  onFilterChange(e: any) {
    const { onLoadTrainingCourse } = this.props;
    const {
      paginationModel: { pageSize },
      search,
      sortModel,
      orderByConvertList,
      facultyId
    } = this.state;
    const [{ field, sort }] = sortModel;
    const orderBy = getSortByApiValue(orderByConvertList, field, sort);
    const integrationId =
      e.target.value.value !== TRAINING_FILTER_DEFAULT_VALUE.ALL.value
        ? e.target.value.value
        : null;
    const newModel = { page: 0, pageSize };
    this.setState({ integrationId, paginationModel: newModel });
    onLoadTrainingCourse(search, pageSize, orderBy, integrationId, facultyId);
  }

  onFilterFacultyChange(e: any) {
    const { onLoadTrainingCourse } = this.props;
    const {
      paginationModel: { pageSize },
      search,
      sortModel,
      orderByConvertList,
      integrationId
    } = this.state;
    const [{ field, sort }] = sortModel;
    const orderBy = getSortByApiValue(orderByConvertList, field, sort);
    const facultyId =
      e.target.value.value !== TRAINING_FILTER_FACULTY_DEFAULT_VALUE.ALL.value
        ? e.target.value.value
        : null;
    const newModel = { page: 0, pageSize };
    this.setState({ facultyId, paginationModel: newModel });
    onLoadTrainingCourse(search, pageSize, orderBy, integrationId, facultyId);
  }

  onCheckHasExpiredData(course: any) {
    const { associatedAttribute } = course;

    return associatedAttribute.every((attribute: any) => attribute?.hasExpiryDate);
  }

  onShowTrainingAttributesDialog(associatedRow: any) {
    // set detail data for dialog
    this.setState({ associatedAttributeDetail: associatedRow });

    this.setState({ isTrainingAttributesDialogShow: true });
    this.setState({ trainingDialogType: TRAINING_ATTRIBUTE_DIALOG_TYPE.DETAIL });
  }

  onCloseTrainingAttributesDialog() {
    this.setState({ isTrainingAttributesDialogShow: false });
    this.setState({ trainingDialogType: null });
  }

  onPaginationModelChange(model: any) {
    const { page, pageSize } = model;
    this.setState({ paginationModel: model });
    const { onLoadCoursesPageSize } = this.props;
    const { search, integrationId, sortModel, orderByConvertList } = this.state;
    const [{ field, sort }] = sortModel;
    const orderBy = getSortByApiValue(orderByConvertList, field, sort);
    onLoadCoursesPageSize(page + 1, pageSize, search, orderBy, integrationId);
  }

  onSortModelChange(sortModel: any) {
    const { onLoadTrainingCourse } = this.props;
    const {
      paginationModel: { pageSize },
      search,
      integrationId,
      orderByConvertList
    } = this.state;
    const [{ field, sort }] = sortModel;

    const orderBy = getSortByApiValue(orderByConvertList, field, sort);

    onLoadTrainingCourse(search, pageSize, orderBy, integrationId);
    const newModel = { page: 0, pageSize };
    this.setState({ sortModel, paginationModel: newModel });
  }

  onRecurrentChange(row: any, recurrence: any) {
    const { onSaveCourseRecurrence } = this.props;
    const { id } = row;
    const { index } = recurrence;

    if (!this.onCheckHasExpiredData(row) && row.recurrence === COURSE_RECURRENCE.NONE.value) {
      // open warning dialog

      this.onShowTrainingAttributesDialog(row);
      this.setState({ trainingDialogType: TRAINING_ATTRIBUTE_DIALOG_TYPE.WARNING });
      return;
    }

    onSaveCourseRecurrence(id, index);
  }

  getCoursesWithHierarchy(courses: any) {
    if (!courses?.courses?.length) return [];

    const coursesWithHierarchy = courses.courses.map((course: any) => ({
      ...course,
      hierarchy: [course.rowId]
    }));

    courses.courses.forEach((course: any) => {
      const competencies = course.associatedAttribute;
      competencies.forEach((competency: any) => {
        coursesWithHierarchy.push({
          name: competency.name,
          hierarchy: [course.rowId, competency.attributeDefinitionId],
          rowId: `${course.rowId}_${competency.attributeDefinitionId}`
        });
      });
    });

    return coursesWithHierarchy;
  }

  render() {
    const { theme, user, organization } = this.props;
    const {
      integrations,
      sortModel,
      associatedAttributeDetail,
      isTrainingAttributesDialogShow,
      trainingDialogType,
      paginationModel,
      faculties,
      coursesWithHierarchy
    } = this.state;

    return (
      <StyledSettingTrainingView>
        <div className='klayo__setting-training-desktop'>
          <Paper
            theme={theme}
            padding={{ xs: "46px 24px", md: "56px 64px" }}
            borderFromBreakpoint='md'
          >
            <h1>Training</h1>
            <SettingTrainingFilter
              onSearchChange={this.onSearch.bind(this)}
              onFilterChange={this.onFilterChange.bind(this)}
              onFilterFacultyChange={this.onFilterFacultyChange.bind(this)}
              faculties={faculties}
              organization={organization}
              user={user}
              integrations={integrations}
            />
            <TrainingTable
              sortable={true}
              rowHasAction={true}
              minHeight='300px'
              theme={theme}
              filterMode='server'
              hideFirstLastBorder={true}
              dense={true}
              paginationMode='server'
              showDialog={this.onShowTrainingAttributesDialog.bind(this)}
              totalCount={this.props.courses?.totalCount}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              onRecurrentChange={this.onRecurrentChange.bind(this)}
              onSortModelChange={this.onSortModelChange.bind(this)}
              paginationModel={paginationModel}
              onPaginationModelChange={this.onPaginationModelChange.bind(this)}
              selectable={false}
              columns={
                organization?.hasMultipleIntegrations
                  ? TrainingTable.defaultProps.columns
                  : TrainingTable.defaultProps.columns.filter(
                      (col: any) => col.type.field !== TrainingTable.columns.provider.field
                    )
              }
              sortingMode='server'
              sortModel={sortModel}
              paper={false}
              rows={coursesWithHierarchy}
            />
          </Paper>

          {isTrainingAttributesDialogShow && (
            <TrainingAttributesDialog
              type={trainingDialogType}
              onClose={this.onCloseTrainingAttributesDialog.bind(this)}
              associatedAttributeDetail={associatedAttributeDetail}
              theme={theme}
              {...this.props}
            />
          )}
          {this.renderSnackbar()}
        </div>
        <div className='klayo__setting-training-mobile'>
          <p className='content'> This page is not available at this breakpoint </p>
        </div>
      </StyledSettingTrainingView>
    );
  }
}

const StyledSettingTrainingView = styled.div`
  .klayo__setting-training-mobile {
    display: none;
  }

  @media (max-width: 600px) {
    .klayo__setting-training-desktop {
      display: none;
    }

    .klayo__setting-training-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80vh;

      .content {
        color: ${KLAYO_COLORS.RedWarning};
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.3px;
        text-align: center;
      }
    }
  }
`;
